module.exports = {
  production: true,
  //api: "https://localhost:44304/",
  //site: "https://localhost:44318/",
  //api: "https://api-wir-liefern.dibaha.de",
  //site: "https://wir-liefern.dibaha.de/",
  api: 'https://api.wir-liefern.org/v1.9',
  site: 'https://cdn.wir-liefern.org/',
  googleMapsKey: 'AIzaSyBbb1wzGVCeSAXS3GTRjVRSAB3J33sMhkE', // dbh
  googleMapsKeyGeocoding: 'AIzaSyD8ClLawuBPt_Qc5Y_clNHgcc_x7Lywl5E', // dbh Geocoding
  //googleMapsKey: "AIzaSyDZorK8rlZlbiOSh-wZXJE2YQgpRu1tnJM", // Waterdog
  googleMapsGeoCodingUrl:
    'https://maps.googleapis.com/maps/api/geocode/json?address=',
  cookieKey: 'dbhAuthCookie',
};
