import {
  StoreFormViewModel,
  defaultEmptyState,
  defaultEmptyStatePreProd,
} from "./StoreInfoSchema";
import { BaseStoreForm, FormSubmissionStatus } from "./BaseStoreForm";
import React from "react";
import { CreateFormImageUploader } from "app/shared/forms/imageUploader/CreateFormImageUploader";
import { createStore } from "../services";

/*
Exports a customized version of the base store form for the business creation use-case.
*/
const CreateStoreForm: React.FC = () => {
  return (
    <BaseStoreForm
      initialState={defaultEmptyState}
      imageUploaderComponent={CreateFormImageUploader}     
    />
  );
};

export default CreateStoreForm;
