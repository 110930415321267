import { PaginatedStores, Store, StoreForList, StoreForMaps, StoreId, StoreSubCategory, StoreForLayoutGrid, OpeningHoursWeek } from "../models";
import { Photo } from "app/shared/models"
import { SearchParams, SearchForUserParam } from "../../search/models/index";
import environment from "configurations";

import axios from "../../../network";
import { StoreFormViewModel } from "../forms/StoreInfoSchema";

export const searchStores = async (searchParams: SearchParams): Promise<PaginatedStores> => {
  const itemCountPerPage = 10;
  let searchUrl: string = "/search?";

  const {
    category,
    subCategories,
    lat,
    lng,
    perPage,
    currentPage,
    sort,
    searchTerm
  } = searchParams

  const pageNumber = searchParams.currentPage !== undefined ? searchParams.currentPage : 1;
  const itemNumber = perPage !== undefined ? perPage : itemCountPerPage;

  searchUrl += "currentPage={}";
  searchUrl = addParameterToSearchString(searchUrl, "perPage", perPage, 10);
  searchUrl = addParameterToSearchString(searchUrl, "category", category, undefined);
  searchUrl = addParameterToSearchString(searchUrl, "subCategories", subCategories, undefined);
  searchUrl = addParameterToSearchString(searchUrl, "txt", searchTerm, undefined);
  searchUrl = addParameterToSearchString(searchUrl, "lat", lat, undefined);
  searchUrl = addParameterToSearchString(searchUrl, "lng", lng, undefined);

  let apiParamString = addParameterToSearchString("", "perPage", itemNumber, 10);
  apiParamString = addParameterToSearchString(apiParamString, "page", currentPage, 1);
  apiParamString = addParameterToSearchString(apiParamString, "cat", category, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "lat", lat, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "txt", searchTerm, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "lng", lng, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "subcats", subCategories, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "order", sort === "rating" ? 2 : sort === "distance" ? 3 : 1, 1);

  return new Promise<PaginatedStores>((resolve, reject) => {

    axios.
      get("/Stores/SearchStores" + apiParamString)

      .then(function (response) {
        try {
          let data = response.data;
          let storeData: StoreForList[] = [];

          for (let i = 0; i < data.length; i++) {
            storeData.push(getStoreForListFromData(data[i]));
          }
          const paginatedStores: PaginatedStores = {
            totalResults: storeData != null && storeData.length > 0 ? storeData[0].storesCount ?? 0 : 0,
            currentPage: pageNumber,
            perPage: itemNumber,
            nextPageUrl: "",
            prevPageUrl: "",
            urlTemplate:
              searchUrl,
            data: storeData,
          };

          resolve(paginatedStores);
        } catch (r) {
          console.log("Error loading/parsing StoreList data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing StoreList data");
        //reject(categoriesDTO);
      });
  });

};

export const searchStoresForMap = async (searchParams: SearchParams): Promise<StoreForMaps[]> => {
  const {
    category,
    subCategories,
    lat,
    lng,
    searchTerm
  } = searchParams

  let apiParamString = addParameterToSearchString("", "cat", category, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "subcats", subCategories, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "txt", searchTerm, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "lat", lat, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "lat", lat, undefined);
  apiParamString = addParameterToSearchString(apiParamString, "lng", lng, undefined);

  return new Promise<StoreForMaps[]>((resolve, reject) => {
    fetch(environment.api + "/Stores/SearchStoresForResultMap" + apiParamString)
      .then(function (response) {
        try {
          response.json().then(function (data) {
            let storeMapData: StoreForMaps[] = [];
            for (let i = 0; i < data.length; i++) {
              let storeForMap: StoreForMaps = {
                id: data[i].id ?? "",
                name: data[i].name ?? "",
                mainPhotoUrl: data[i].mainPhotoUrl,
                coordinates: {
                  lat: data[i].lat ?? 0,
                  lng: data[i].long ?? 0
                },
                rating: data.rating ?? "0"
              }

              storeMapData.push(storeForMap);
            }

            resolve(storeMapData);
          });
        } catch (r) {
          console.log("Error loading/parsing StoresForMaps data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing StoresForMaps data");
        //reject(categoriesDTO);
      });
  });

}
function addParameterToSearchString(searchString: string, parametername: string, parameterValue: any, defaultValue: any) {
  if ((parameterValue === undefined || parameterValue.toString().length == 0) &&
    (defaultValue === undefined || defaultValue.toString().length == 0)) {
    return searchString;
  }

  const searchContainsQuestionmark = searchString.indexOf('?') >= 0;
  const startSign = searchContainsQuestionmark ? "&" : "?";
  let newPart = startSign + parametername + "=" + (parameterValue !== undefined ? parameterValue.toString() : defaultValue);
  return searchString + newPart;
}

function getSubCategoriesFromData(data: any): StoreSubCategory[] {
  if (data === undefined || data === null) {
    return []
  }

  if (data.categories === undefined || data.categories === null) {
    return []
  }
  let subcategories: StoreSubCategory[] = [];
  for (let i = 0; i < data.categories.length; i++) {
    let subCat: StoreSubCategory = {
      name: data.categories[i].name ?? "",
      parentCategory: data.categories[i].parentCategory ?? "",
      id: data.categories[i].id ?? ""
    }
    subcategories.push(subCat);
  }

  return subcategories;
}

function getPhotosFromData(data: any): Photo[] {
  if (data === undefined || data === null) {
    return []
  }

  if (data.photos === undefined || data.photos === null) {
    return []
  }

  let photos: Photo[] = [];
  for (let i = 0; i < data.photos.length; i++) {
    let photo: Photo = {
      id: data.photos[i].id ?? "",
      url: data.photos[i].url ?? ""
    }
    photos.push(photo);
  }

  return photos
}

function getStoreForListFromData(data: any): StoreForList {
  let subcategories: StoreSubCategory[] = getSubCategoriesFromData(data);
  let photos: Photo[] = getPhotosFromData(data);

  return {
    id: data.id ?? "",
    name: data.name ?? "",
    typeOfStore: "",
    address: {
      streetNumber: data.streetNumber ?? "",
      city: data.city ?? "",
      country: data.country ?? "",
      street: data.street ?? "",
      zipCode: data.zipCode ?? ""
    },
    description: data.description ?? "",
    favorite: data.favorite ?? false,
    coordinates: {
      lat: data.lat ?? 0,
      lng: data.long ?? 0
    },
    numViews: data.numViews ?? 0,
    numComments: data.numComments ?? 0,
    numContacts: data.numContacts ?? 0,
    numFavorites: data.numFavorites ?? 0,
    //socialNetworks: undefined,
    deliveryOptions: {
      canDeliver: data.canDeliver ?? false,
      canPickup: data.canPickup ?? false,
      deliveryDescription: data.deliveryDescription ?? "",
      deliveryType: data.deliveryNationwide ? "National" : "Local",
      localDeliveryRadiusKm: data.deliveryLocalRadius,
      pickupDescription: data.pickupDescription
    },
    rating: data.rating ?? "1",
    mainPhotoUrl: data.mainPhotoUrl ?? "",
    storesCount: data.storesCount ?? 0,
    distance: data.distance ?? 0
    //contacts: {
    //  email: data.email ?? "",
    //  telephone: data.telephone ?? "",
    //  websiteUrl: data.websiteUrl ?? ""
    //},
    //categories: subcategories,
    //photos: photos
  }
}

function getStoreFromData(data: any): Store {
  let subcategories: StoreSubCategory[] = getSubCategoriesFromData(data);
  let photos: Photo[] = getPhotosFromData(data);

  return {
    id: data.id ?? "",
    name: data.name ?? "",
    openingHours: data.openingHours,
    address: {
      street: data.street,
      streetNumber: data.streetNumber,
      zipCode: data.zipCode,
      city: data.city,
      country: data.country,
    },
    typeOfStore: data.typeOfStore ?? "",
    streetNumber: data.streetNumber ?? "",
    city: data.city ?? "",
    country: data.country ?? "",
    street: data.street ?? "",
    zipCode: data.zipCode ?? "",
    description: data.description ?? "",
    favorite: data.favorite ?? false,
    coordinates: {
      lat: data.lat ?? 0,
      lng: data.long ?? 0
    },
    mainPhotoUrl: data.mainPhotoUrl ?? "",
    numViews: data.numViews ?? 0,
    numComments: data.numComments ?? 0,
    numContacts: data.numContacts ?? 0,
    numFavorites: data.numFavorites ?? 0,
    facebookUrl: data.facebookUrl ?? "",
    twitterUrl: data.twitterUrl ?? "",
    instagramUrl: data.instagramUrl ?? "",
    //socialNetworks: undefined,
    deliveryOptions: {
      canDeliver: data.canDeliver ?? false,
      canPickup: data.canPickup ?? false,
      deliveryDescription: data.deliveryDescription ?? "",
      deliveryType: data.deliveryNationwide ? "National" : "Local",
      localDeliveryRadiusKm: data.deliveryLocalRadius,
      pickupDescription: data.pickupDescription
    },
    rating: data.rating ?? "1",
    //mainPhotoUrl: data.mainPhotoUrl,    
    contacts: {
      email: data.contacts.email ?? "",
      telephone: data.contacts.telephone ?? "",
      websiteUrl: data.contacts.websiteUrl ?? ""
    },
    categories: subcategories,
    photos: photos,
    distance: data.distance ?? 0,
    videoUrl: data.videoUrl ?? null
  }

}

export const getStore = async (storeId: StoreId): Promise<Store> => {
  return new Promise<Store>((resolve, reject) => {

    //fetch(environment.api + "/Stores/Store/" + storeId)
    axios.
      get("/Stores/Store/" + storeId)
      .then(function (response) {
        try {

          const store: Store = getStoreFromData(response.data);
          resolve(store);

        } catch (r) {
          console.log("Error loading/parsing Store data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
        //reject(categoriesDTO);
      });
  });
};

export const getOpeningHours = async (storeId: StoreId): Promise<OpeningHoursWeek> => {
  return new Promise<any>((resolve, reject) => {
    axios.
      get("/Stores/OpeningHours/" + storeId)
      .then(function (response) {
        try {

          const openingHours: OpeningHoursWeek = getOpeningHoursFromData(response.data)
          resolve(openingHours);

        } catch (r) {
          console.log("Error loading/parsing Store data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
        //reject(categoriesDTO);
      });
  });
};

function getOpeningHoursFromData(data: any): OpeningHoursWeek {
  return {
    monday: {
      firstStart: data.mon_1_open,
      firstEnd: data.mon_1_close,
      secondStart: data.mon_2_open,
      secondEnd: data.mon_2_close,
    },
    tuesday: {
      firstStart: data.tue_1_open,
      firstEnd: data.tue_1_close,
      secondStart: data.tue_2_open,
      secondEnd: data.tue_2_close,
    },
    wednesday: {
      firstStart: data.wed_1_open,
      firstEnd: data.wed_1_close,
      secondStart: data.wed_2_open,
      secondEnd: data.wed_2_close,
    },
    thursday: {
      firstStart: data.thu_1_open,
      firstEnd: data.thu_1_close,
      secondStart: data.thu_2_open,
      secondEnd: data.thu_2_close,
    },
    friday: {
      firstStart: data.fri_1_open,
      firstEnd: data.fri_1_close,
      secondStart: data.fri_2_open,
      secondEnd: data.fri_2_close,
    },
    saturday: {
      firstStart: data.sat_1_open,
      firstEnd: data.sat_1_close,
      secondStart: data.sat_2_open,
      secondEnd: data.sat_2_close,
    },
    sunday: {
      firstStart: data.sun_1_open,
      firstEnd: data.sun_1_close,
      secondStart: data.sun_2_open,
      secondEnd: data.sun_2_close,
    },
  }
}

export const createStore = async (values: StoreFormViewModel): Promise<boolean> => {
  const { photos, openingHours, ...jsonPayload } = values;

  return new Promise((resolve, reject) => {
    const data = new FormData();

    for (var i = 0; i < photos.length; i++) {
      data.append('pictures', photos[i] as File);
    }

    data.append("store", JSON.stringify(jsonPayload));

    axios
      .post(`/Stores/AddMyStore`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        }
        else if (response?.status == 401 || (response as any).response?.status == 401)
        {
          reject(response);
        }
        else {
          updateOpeningHours(response.data, openingHours);
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

export const updateStore = async (values: StoreFormViewModel): Promise<boolean> => {
  const { photos, openingHours, ...jsonPayload } = values;

  return new Promise((resolve, reject) => {
    const data = new FormData();
    let pictures = [];
    for (var i = 0; i < photos.length; i++) {
      if (photos[i] instanceof File) {
        data.append('newPictures', photos[i] as File);
      }
      else {
        pictures.push(photos[i] as Photo);
      }
    }

    data.append('pictures', JSON.stringify(pictures));
    data.append("store", JSON.stringify(jsonPayload));

    axios
      .post(`/Stores/UpdateMyStore`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        }
        else if (response?.status == 401 || (response as any).response?.status == 401) {
          reject(response);
        }
        else {
          updateOpeningHours(jsonPayload.id, openingHours)
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log("Server error, please try again later");
        }
        reject(error);
      });
  });
};

const updateOpeningHours = async (
  storeId: StoreId,
  openingHours: OpeningHoursWeek
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const data = new FormData();

    data.append(
      'openingHours',
      JSON.stringify(getOpeningHoursAsFormData(storeId, openingHours))
    );

    axios
      .post(`/Stores/SetOpeningHours`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        } else if (
          response?.status == 401 ||
          (response as any).response?.status == 401
        ) {
          reject(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log('Server error, please try again later');
        }
        reject(error);
      });
  });
};

function getOpeningHoursAsFormData(storeId: StoreId, openingHours: OpeningHoursWeek) {
  return {
    storeId: storeId,

    mon_1_open: openingHours.monday.firstStart,
    mon_1_close: openingHours.monday.firstEnd,
    mon_2_open: openingHours.monday.secondStart,
    mon_2_close: openingHours.monday.secondEnd,

    tue_1_open: openingHours.tuesday.firstStart,
    tue_1_close: openingHours.tuesday.firstEnd,
    tue_2_open: openingHours.tuesday.secondStart,
    tue_2_close: openingHours.tuesday.secondEnd,

    wed_1_open: openingHours.wednesday.firstStart,
    wed_1_close: openingHours.wednesday.firstEnd,
    wed_2_open: openingHours.wednesday.secondStart,
    wed_2_close: openingHours.wednesday.secondEnd,

    thu_1_open: openingHours.thursday.firstStart,
    thu_1_close: openingHours.thursday.firstEnd,
    thu_2_open: openingHours.thursday.secondStart,
    thu_2_close: openingHours.thursday.secondEnd,

    fri_1_open: openingHours.friday.firstStart,
    fri_1_close: openingHours.friday.firstEnd,
    fri_2_open: openingHours.friday.secondStart,
    fri_2_close: openingHours.friday.secondEnd,

    sat_1_open: openingHours.saturday.firstStart,
    sat_1_close: openingHours.saturday.firstEnd,
    sat_2_open: openingHours.saturday.secondStart,
    sat_2_close: openingHours.saturday.secondEnd,

    sun_1_open: openingHours.sunday.firstStart,
    sun_1_close: openingHours.sunday.firstEnd,
    sun_2_open: openingHours.sunday.secondStart,
    sun_2_close: openingHours.sunday.secondEnd,
  }
}

export const getOwnedStores = async (
  searchParams: SearchForUserParam
): Promise<StoreForLayoutGrid> => {
  return new Promise<StoreForLayoutGrid>((resolve, reject) => {
    axios.
      get("/Stores/SearchMyStores?id=" + searchParams.userId)
      .then(function (response) {
        try {
          let storeData: StoreForList[] = [];

          for (let i = 0; i < response.data.length; i++) {
            storeData.push(getStoreForListFromData(response.data[i]));
          }

          resolve({ data: storeData, perPage: 100, totalResults: 100 });
        } catch (r) {
          console.log("Error loading/parsing Store data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
        //reject(categoriesDTO);
      });
  });
};


export const getFavoriteStores = async (
  searchParams: SearchForUserParam
): Promise<StoreForLayoutGrid> => {
  return new Promise<StoreForLayoutGrid>((resolve, reject) => {
    axios.
      get("/Stores/SearchMyFavoriteStores?id=" + searchParams.userId)
      .then(function (response) {
        try {
          let storeData: StoreForList[] = [];

          for (let i = 0; i < response.data.length; i++) {
            storeData.push(getStoreForListFromData(response.data[i]));
          }

          resolve({ data: storeData, perPage: 100, totalResults: 100 });
        } catch (r) {
          console.log("Error loading/parsing Store data");
          //reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
        //reject(categoriesDTO);
      });
  });
};

export const deleteStore = async (storeId: StoreId): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios.
      post("/Stores/DeleteStore?id=" + storeId)
      .then(function (response) {
        try {

          resolve(true);
        } catch (r) {
          console.log("Error loading/parsing Store data");
          reject(r);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
      });
  });
};

export const getStoreIsFavorite = async (storeId: StoreId) => {
  return new Promise<boolean>((resolve, reject) => {
    axios.
      get("/Stores/GetStoreIsFavorite?id=" + storeId)
      .then(function (response) {
        try {
          resolve(response.data);
        } catch (r) {
          console.log("Error loading/parsing Store data");
          reject(r);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
      });
  });
};

export const toggleFavorite = async (storeId: StoreId) => {
  return new Promise<boolean>((resolve, reject) => {
    axios.
      post("/Favourites/ToggleMyFavourite?id=" + storeId)
      .then(function (response) {
        try {
          resolve(true);
        } catch (r) {
          console.log("Error loading/parsing Store data");
          reject(r);
        }
      }).catch(() => {
        console.log("Error loading/parsing Store data");
      });
  });
};

//export const getStoreVideoInformation = async (storeId: StoreId) => {
//  return new Promise<boolean>((resolve, reject) => {
//    axios.
//      get("/Stores/GetStoreVideoInformation?id=" + storeId)
//      .then(function (response) {
//        try {
//          resolve(response.data);
//        } catch (r) {
//          console.log("Error loading/parsing Store data");
//          reject(r);
//        }
//      }).catch(() => {
//        console.log("Error loading/parsing Store data");
//      });
//  });
//};
