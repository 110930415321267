import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { compose } from "recompose";
import { Flex, Box, Text, HStack, Stack, VStack } from "@chakra-ui/react";
import { Heading } from "app/shared";
import { useRootRepositoryContext } from "rootRepository";
import { ProductCard } from "./ProductCard";
import { CampaignHeader } from "./CampaignHeader";
import { useMediaQueryContext } from "styles/context";

const CampaignSlideBase: React.FC = () => {
  const { campaignRepository } = useRootRepositoryContext();
  const [campaign, setCampaign] = useState(campaignRepository.campaign);

  const { isSmallMedium } = useMediaQueryContext();

  useEffect(() => {
    campaignRepository.fetchCampaign().then(() => {
      setCampaign(campaignRepository.campaign);
    });
  }, [campaignRepository]);

  return campaign.products ? (
    <Stack position="relative" alignItems={["center"]} spacing={8}>
      <Box
        position="absolute"
        width="100vw"
        height="100%"
        marginLeft="-50vw"
        left="50%"
        zIndex={10}
        bg="#F4F4F4"
        pt="40px"
        pb="40px"></Box>
      <Flex
        position="relative"
        zIndex={100}
        direction="column"
        pr={[0, 0, 0, 0, "60px"]}
        pb={8}
        width="100%"
        alignItems={["center", "center", "center", "center", "unset"]}>
        <CampaignHeader title={campaign.name} subtitle={campaign.text} />
        <HStack
          pb={4}
          mb={4}
          spacing={8}
          w={["230px", "400px", "500px", "800px", "1175px"]}
          overflowX={["scroll"]}>
          {campaign.products ? (
            campaign.products.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))
          ) : (
            <></>
          )}
        </HStack>
      </Flex>
    </Stack>
  ) : (
    <></>
  );
};

export const CampaignSlide = compose<{}, {}>(observer)(CampaignSlideBase);
