import { Flex, Image, Box } from "@chakra-ui/react";
import { CloseButton, Rating } from "app/shared";
import { TextLink } from "app/shared/textLink";
import { StoreForMaps } from "app/stores/models";
import React, { useState } from "react";
import environment from "configurations";
import { BottomTriangle } from "app/shared";

interface Props {
  store: StoreForMaps;
  close: () => void;
  open: boolean;
  top?: number;
  left?: number;
  elementWidth?: number;
  elementHeight?: number;
}

const POPUP_WIDTH = 205;
const POPUP_HEIGHT = 196;

export const StoreMarkerPopup: React.FC<Props> = ({
  store,
  close,
  open,
  top,
  left,
  elementHeight,
  elementWidth,
}) => {
  const [topPx, setTopPx] = useState("");
  const [leftPx, setLeftPx] = useState("");

  const invertedY = top && elementHeight && top > elementHeight / 2;
  const invertedX = left && elementWidth && left > elementWidth / 2;

  const imgHeight = "120px";

  if (open && top && left && !topPx && !leftPx) {
    const fixedTop = invertedY ? top - POPUP_HEIGHT : top;
    const fixedLeft = invertedX ? left - POPUP_WIDTH : left;
    setTopPx(`${fixedTop}px`);
    setLeftPx(`${fixedLeft}px`);
  }

  return open ? (
    <Flex
      direction="column"
      position="absolute"
      top={topPx}
      left={leftPx}
      bg="brandRed.300"
      minW={`${POPUP_WIDTH}px`}
      width={`${POPUP_WIDTH}px`}
      minH={`${POPUP_HEIGHT}px`}
      pb={"20px"}
      pl={"17px"}
      pr={"17px"}
      pt={"12px"}
      boxShadow="lg"
      justifyContent="space-between"
      zIndex={1000002}
      >
     
      <TextLink
        isTruncated
        to={`/store/${store.id}`}
        fontSize="sm"
        color="white"
        fontWeight="bold"
        target="_self">
        {store.name}
      </TextLink>
      <Rating
        activeColor="darkGrey"
        inactiveColor="mdGrey"
        rating={store.rating}
      />
      <Box _hover={{ cursor: "pointer" }}>
        <Image
          width="162px"
          maxWidth="162px"
          height="108px"
          maxHeight="108px"
          objectFit="cover"
          margin="auto"
          src={environment.site + store.mainPhotoUrl ?? ""}
          onClick={() => window.open(`/store/${store.id}`, "_self")}
        />
      </Box>
      
    </Flex>
  ) : null;
};


