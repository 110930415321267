import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  MenuButtonProps,
  useDisclosure,
} from "@chakra-ui/react";
import React, { LegacyRef } from "react";
import { BurgerMenuIcon } from "styles/icons";
//import { useTranslation } from "react-i18next";
import { DrawerMenu } from "./DrawerMenu";



const BurgerDrawerViewController: React.FC<MenuButtonProps> = (props) => {
  //const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef() as LegacyRef<HTMLButtonElement>;

  return (
    <>
      <Button
        ref={btnRef}
        backgroundColor={"brandRed.300"}
        borderRadius={0}
        width={[70, 70, 100]}
        height={[70, 70, 90]}
        onClick={onOpen}
        _focus={{ outline: "none" }}
        _hover={{
          backgroundColor: "brandRed.200",
        }}>
        <BurgerMenuIcon color="white" boxSize="34px" />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef as any}>
        <DrawerContent backgroundColor="brandRed.300" color="white">
          <DrawerBody
            width={"100%"}
            maxWidth={["maxWidth"]}
            mx="auto"
            py={8}
            px={10}>
            <DrawerMenu onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export const BurgerDrawer = BurgerDrawerViewController;
