import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import useScript from '../utils/useScript';
import { ContentArea } from 'app/shared';
import { ProductInfo } from 'app/products/productInfo';
import { ProductRepositoryProvider } from 'app/products/repositories';
import { StoreWindowRepositoryProvider } from 'app/storeWindows/repositories';

interface Params {
  storeWindowId: string;
  productId: string;
}

type Props = RouteComponentProps<Params>;

const ProductDetailsBase: React.FC<Props> = ({ match }) => {
  const storeWindowId = match.params.storeWindowId;
  const productId = match.params.productId;

  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: '" +
      productId +
      "', section: 'product', pubdate: '', author: '', url: '/product', object_type: 'product' }}); } "
  );

  return (
    <ContentArea color="white" bg="darkGrey">
      <ProductRepositoryProvider>
        <StoreWindowRepositoryProvider>
          <ProductInfo storeWindowId={storeWindowId} productId={productId} />
        </StoreWindowRepositoryProvider>
      </ProductRepositoryProvider>
    </ContentArea>
  );
};

export const ProductDetails = compose<Props, {}>(withRouter)(
  ProductDetailsBase
);
