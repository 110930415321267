import * as Yup from 'yup';
import i18next from 'i18next';

import { StoreWindow } from '../models';
import { fileSizeChecker, fileTypeChecker } from 'app/shared/utils/FileUtils';
import { MixedPhoto, Photo } from 'app/shared/models';
import { ProductForTable } from 'app/products/models';

const TEN_MB = 10 * 1024 * 1024;
const VALID_MIME_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const StoreWindowFormSchema = Yup.object({
  id: Yup.string(),
  storeId: Yup.string(),
  photo: Yup.mixed<MixedPhoto>()
    .nullable()
    .test(
      'checkFileSize',
      i18next.t(`storeWindows:form.validation.fileTooLarge`),
      fileSizeChecker(TEN_MB)
    )
    .test(
      'checkExtension',
      i18next.t(`storeWindows:form.validation.invalidFileType`),
      fileTypeChecker(VALID_MIME_TYPES)
    )
    .required(i18next.t(`storeWindows:form.validation.requiredImage`)),
  name: Yup.string()
    .min(3, i18next.t(`storeWindows:form.validation.min`))
    .max(255, i18next.t(`storeWindows:form.validation.max`))
    .required(i18next.t(`storeWindows:form.validation.requiredName`)),
  visible: Yup.boolean(),
  products: Yup.array().of(Yup.object<ProductForTable>()),
}).defined();

export type StoreWindowFormViewModel = Yup.InferType<
  typeof StoreWindowFormSchema
>;

export const model2ViewModel = (
  storeWindow: StoreWindow
): StoreWindowFormViewModel => ({
  id: storeWindow.id,
  storeId: storeWindow.storeId,
  photo: storeWindow.photo,
  name: storeWindow.name,
  visible: storeWindow.visible,
  products: storeWindow.products,
});

export const defaultEmptyState: StoreWindowFormViewModel = {
  id: '',
  storeId: '',
  photo: null,
  name: '',
  visible: true,
  products: [],
};

export const defaultEmptyStatePreProd: StoreWindowFormViewModel = {
  id: '',
  storeId: '',
  photo: null,
  name: 'TEST',
  visible: true,
  products: [],
};
