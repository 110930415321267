import { Box } from "@chakra-ui/react";
import React from "react";

interface Props {
  selectable?: boolean;
  top?: number | string;
  left?: number | string;
  opacity?: number;
}

export const BottomTriangle: React.FC<Props> = ({
  selectable,
  top,
  left,
  opacity,
}) => (
  <Box
    position="absolute"
    top={top ?? 196}
    left={left ?? 50}
    width={0}
    height={0}
    borderStyle="solid"
    borderWidth="26.04px 14.86px 0 14.86px"
    borderColor={`#EB5757 transparent transparent transparent`}
    opacity={opacity ?? selectable ? 1 : 0.15}
    _hover={{ cursor: selectable ? "pointer" : undefined }}
  />
);
