import React from 'react';
import i18next from 'i18next';
import { BoxProps, Stack, Text, SimpleGrid } from '@chakra-ui/react';
import { OpeningHoursDay, OpeningHoursWeek } from '../models';

interface Props extends BoxProps {
  openingHours: OpeningHoursWeek;
}

export const StoreOpeningHours: React.FC<Props> = ({
  openingHours,
  ...rest
}) => {
  const openingHoursArray: { day: string; values: OpeningHoursDay }[] = [
    { day: 'monday', values: openingHours?.monday },
    { day: 'tuesday', values: openingHours?.tuesday },
    { day: 'wednesday', values: openingHours?.wednesday },
    { day: 'thursday', values: openingHours?.thursday },
    { day: 'friday', values: openingHours?.friday },
    { day: 'saturday', values: openingHours?.saturday },
    { day: 'sunday', values: openingHours?.sunday },
  ];

  const groupedOpeningHours: { day: string; values: OpeningHoursDay }[][] = [];
  openingHoursArray.forEach((element) => {
    if (groupedOpeningHours.length === 0) {
      groupedOpeningHours.push([element]);
    } else {
      const lastOpeningHoursGroup =
        groupedOpeningHours[groupedOpeningHours.length - 1];
      if (
        lastOpeningHoursGroup[0].values.firstStart ===
          element.values.firstStart &&
        lastOpeningHoursGroup[0].values.firstEnd === element.values.firstEnd &&
        lastOpeningHoursGroup[0].values.secondStart ===
          element.values.secondStart &&
        lastOpeningHoursGroup[0].values.secondEnd === element.values.secondEnd
      ) {
        lastOpeningHoursGroup.push(element);
      } else {
        groupedOpeningHours.push([element]);
      }
    }
  });

  return (
    <Stack direction="column" spacing={0} {...rest}>
      <Text fontSize="lg" fontWeight="bold">
        {i18next.t(`stores:storeInfo.storeOpeningHours.openingHoursHeader`)}:
      </Text>
      <SimpleGrid columns={2} spacing={0}>
        {groupedOpeningHours.map((item, index) => (
          <>
            {/* Day / DaySpan */}
            {item.length > 1 ? (
              <Text fontSize="lg">{`${i18next.t(
                `stores:storeInfo.storeOpeningHours.openingDays.${item[0].day}`
              )} - ${i18next.t(
                `stores:storeInfo.storeOpeningHours.openingDays.${
                  item[item.length - 1].day
                }`
              )}:`}</Text>
            ) : (
              <Text fontSize="lg">{`${i18next.t(
                `stores:storeInfo.storeOpeningHours.openingDays.${item[0].day}`
              )}:`}</Text>
            )}

            {/* Times */}
            {item[0].values.firstStart && item[0].values.firstEnd ? (
              <Stack direction="column" spacing={0}>
                <Text fontSize="lg">
                  {`${item[0].values.firstStart} – ${
                    item[0].values.firstEnd
                  } ${i18next.t(`stores:storeInfo.storeOpeningHours.clock`)}`}
                </Text>
                {item[0].values.secondStart && item[0].values.secondEnd && (
                  <Text fontSize="lg">
                    {`${item[0].values.secondStart} – ${
                      item[0].values.secondEnd
                    } ${i18next.t(`stores:storeInfo.storeOpeningHours.clock`)}`}
                  </Text>
                )}
              </Stack>
            ) : (
              <Text fontSize="lg">
                {i18next.t(`stores:storeInfo.storeOpeningHours.closed`)}
              </Text>
            )}
          </>
        ))}
      </SimpleGrid>
    </Stack>
  );
};
