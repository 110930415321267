import { Box, BoxProps, Flex, Link, Text } from "@chakra-ui/react";
import i18n from "i18n/i18n";
import React from "react";
import { useSearchRepositoryContext } from "../../search/repositories";
import { createSearchUrl, getSubCategoriesForParam } from "../utils";
import queryString from "query-string";
import { SearchParams } from "../models";

export const Paginator: React.FC<Props> = ({
  onPageChanged,
  nextPageUrl,
  prevPageUrl,
  totalResults,
  perPage,
  currentPage,
  urlTemplate,
  ...rest
}) => {

  // generate the proper amount of pages: two pages before the current page
  const twoBefore: Page[] = [];
  let prevPage = currentPage - 1;
  while (prevPage > 0 && twoBefore.length < 2) {
    twoBefore.push({
      url: createPageUrl(prevPage, urlTemplate),
      number: prevPage,
      isCurrent: false,
    });
    prevPage--;
  }

  // and two pages after the current page
  const twoAfter: Page[] = [];
  let nextPage = currentPage;
  while (Math.ceil(totalResults / perPage) - nextPage > 0 && twoAfter.length < 2) {
    nextPage++;
    twoAfter.push({
      url: createPageUrl(nextPage, urlTemplate),
      number: nextPage,
      isCurrent: false,
    });
  }

  // plus the current page itself
  const thisPage = {
    url: createPageUrl(currentPage, urlTemplate),
    number: currentPage,
    isCurrent: true,
  };
  const pages: Page[] = twoBefore.reverse().concat([thisPage]).concat(twoAfter);

  // if the first page in the results is not in the first two pages then we create a different link for it
  const displayLinkToFirstPage = pages[0].number > 1;
  const firstPage = {
    url: createPageUrl(1, urlTemplate),
    number: 1,
    isCurrent: false,
  };

  // if the last page in the results is not in the two last pages, then we  create a different link for it
  const lastPageNumber =
    totalResults % perPage === 0
      ? totalResults / perPage
      : Math.ceil(totalResults / perPage);
  const displayLinkToLastPage =
    pages[pages.length - 1].number < lastPageNumber;
  const lastPage = {
    url: createPageUrl(lastPageNumber, urlTemplate),
    number: lastPageNumber,
    isCurrent: false,
  };

  let { searchRepository } = useSearchRepositoryContext();
  const changeSite = (pagenumber: number) => {
    if (onPageChanged) {
      onPageChanged();
    }
    searchRepository.setCurrentPage(pagenumber);
    searchRepository.fetchStoresForPage();
  }

  return (
    <Flex direction="row" justifyContent="center" {...rest} alignItems="center">
      {currentPage != 1 && (
        <Link {...paginationLinkStyle} mx={1} onClick={() => changeSite(+currentPage - 1)} mr={3}>
          {i18n.t("search:searchResults.pagination.previousPage")}
        </Link>
      )}

      {displayLinkToFirstPage && (
        <>
          <PageLink
            page={firstPage}
            idx={firstPage.number}
            onPageChanged={onPageChanged}
            numPages={firstPage.number}
            lastPageIsInSet={true}
          />
          <PageLinkSeparator />
          <PagesInBetween />
          <PageLinkSeparator />
        </>
      )}
      {pages.map((page, idx) => (
        <PageLink
          key={`paginator_page_${idx}`}
          page={page}
          idx={idx}
          onPageChanged={onPageChanged}
          numPages={pages.length}
          lastPageIsInSet={!displayLinkToLastPage}
        />
      ))}

      {displayLinkToLastPage && (
        <>
          <PagesInBetween />
          <PageLinkSeparator />
          <PageLink
            page={lastPage}
            onPageChanged={onPageChanged}
            idx={lastPage.number}
            numPages={lastPage.number}
            lastPageIsInSet={true}
          />
        </>
      )}
      {currentPage != lastPageNumber && (
        
        <Link {...paginationLinkStyle} mx={1} onClick={() => changeSite(+currentPage + 1)} ml={[1, 1, 3]}>
          {i18n.t("search:searchResults.pagination.nextPage")}
        </Link>
      )}
    </Flex>
  );
};

const createPageUrl = (pageNumber: number, template: string) =>
  `${template}`.replace("{}", `${pageNumber}`);

interface Props extends BoxProps {
  onPageChanged?: () => void;
  totalResults: number;
  currentPage: number;
  perPage: number;
  nextPageUrl?: string;
  prevPageUrl?: string;
  urlTemplate: string;
}

interface Page {
  url: string;
  number: number;
  isCurrent: boolean;
}

const paginationLinkStyle = {
  color: "brandRed.300",
  fontWeight: "bold",
  fontSize: ["sm", "sm", "lg"],
};

const paginationElement = {
  color: "lightGrey",
  fontWeight: "bold",
  fontSize: ["sm", "sm", "lg"],
};

const PageLinkSeparator: React.FC = () => (
  <Box as="span" {...paginationElement}>
    |
  </Box>
);

const PagesInBetween: React.FC = () => (
  <Box as="span" px={1} {...paginationElement}>
    ...
  </Box>
);

interface PageLinkProps {
  onPageChanged?: () => void;
  page: Page;
  idx: number;
  numPages: number;
  lastPageIsInSet: boolean;
}

const PageLink: React.FC<PageLinkProps> = (props) => {
  const { onPageChanged, page, idx, numPages, lastPageIsInSet } = props;
  const separatorIdx = !lastPageIsInSet ? numPages : numPages - 1;

  let { searchRepository } = useSearchRepositoryContext();

  const changeSite = () => {
    if (onPageChanged) {
      onPageChanged();
    }
    searchRepository.setCurrentPage(page.number);
    searchRepository.fetchStoresForPage();

    //const params: SearchParams = {};

    //if (categoryId && categoryId !== "") {
    //  params.category = categoryId;
    //}

    //if (subCategories && subCategories.length) {
    //  params.subCategories = subCategories.map((subCategory) => subCategory.id);
    //}

    //if (location && location.lat !== undefined && location.lng !== undefined) {
    //  params.lat = location.lat;
    //  params.lng = location.lng;
    //}

    //const searchUrl = `/search?${queryString.stringify(, { arrayFormat: "comma" })}`
  }

  return (
    <Box>
      {page.isCurrent ? (
        <Box as="span" px={1} {...paginationElement}>
          {page.number}
        </Box>
      ) : (
          <Link {...paginationLinkStyle} px={1} onClick={changeSite}  >
            {page.number}
          </Link>
        )}
      {idx < separatorIdx && <PageLinkSeparator />}
    </Box>
  );
};
