import { Box } from "@chakra-ui/react";
import useMouse from "@react-hook/mouse-position";
import { Map } from "app/shared/maps/Map";
import { Coords } from "app/shared/maps/models";
import { StoreForMaps } from "app/stores/models";
import React, { useState, useRef } from "react";
import { StoreMarker } from "./StoreMarker";
import { StoreMarkerPopup } from "./StoreMarkerPopup";
import ReactDOM from "react-dom";
import { useMediaQueryContext } from "styles/context";

interface Position {
  y?: number;
  x?: number;
  width?: number;
  height?: number;
}
interface Props {
  centerLat: number;
  centerLng: number;
  stores: StoreForMaps[];
  setGoogleMapsApi: (maps: any) => void;
  hoveredStore?: StoreForMaps;
  zoom?: number;
  hideMap?: boolean;
}

let DEFAULT_ZOOM_LEVEL = 6;

export const MapResults: React.FC<Props> = ({
  centerLat,
  centerLng,
  zoom,
  stores,
  hoveredStore,
  setGoogleMapsApi,
  hideMap,
}) => {
  const [clickedMarkerElement, setClickedMarkerElement] = useState<Element>();
  const [clickedMarker, setClickedMarker] = useState<StoreForMaps>();
  const [clickedMarkerPosition, setClickedMarkerPosition] = useState<Position>();
  const [popupOpen, setPopupOpen] = useState(false);
  const target = React.useRef(null);

  let keyValue: string = "mapRenderKey_" + (new Date().toUTCString);

  if (zoom) {
    DEFAULT_ZOOM_LEVEL = zoom;
  }
  //guarantee that lat and lng are floats and not strings
  const center: Coords = {
    //lat: 51.158352,
    //lng: 10.441210,

    //this._lat = lat ?? 51.158352;
    //this._lng = lng ?? 10.441210;

    lat: parseFloat(centerLat === 0 ? 51.158352.toString() : centerLat.toString(10)),
    lng: parseFloat(centerLng === 0 ? 10.441210.toString() : centerLng.toString(10)),
  };

  const handleMarkerPopupClose = () => {
    setPopupOpen(false);
    setClickedMarker(undefined);
  };


  let arr: any[] = [];
  {   
    stores !== undefined && stores.map((store, index) => {

      arr.push(
        
        <StoreMarker
          key={`marker_${store.id}`}
          onClickCustom={(obj: Element, clickedStore: any) => {

            setClickedMarkerElement(obj);
            setPopupOpen(false);
            setClickedMarker(undefined);
            setTimeout(() => {
              var rect = ReactDOM.findDOMNode(obj) as any;
              var pos: Position = {};
              if (rect instanceof Element) {
               
                let x = (rect as any).parentElement.style.left.replace("px", "") * 1
                let y = (rect as any).parentElement.style.top.replace("px", "") * 1;

                if (x < 0) {
                  x = x + 20;
                }
                if (y < 0) {
                  y = y + 20;
                }
                pos.x = x;
                pos.y = y;
              }
              setClickedMarkerPosition(pos);

              setTimeout(() => {
                setClickedMarker(clickedStore);
                console.log(clickedStore);

                setPopupOpen(true);
              }, 5);
            }, 5);
          }}
          isHoveredOnList={store.id === hoveredStore?.id}
          store={store}
          lat={store.coordinates.lat}
          lng={store.coordinates.lng}
        />
      )

    })
  }
  const { isMedium } = useMediaQueryContext();

  return (
    <Box
      width={["100%", "100%", "100%", "100%", "51%"]}
      height={["40vh", "40vh", "40vh", "40vh", "64vh"]}
      position={!isMedium ? "sticky" : undefined}
      top={!isMedium ? 90 : undefined}
      ref={target}
      display={hideMap ? "none" : "block"}
    >
      <Map
        center={center}
        zoom={DEFAULT_ZOOM_LEVEL}
        onGoogleApiLoaded={(maps) => { setGoogleMapsApi(maps); }}
        onClick={handleMarkerPopupClose}
        onChildClick={(_, props) => { }}
        storeMarker={arr}
      >

        {clickedMarker && (
          <StoreMarkerPopup
            store={clickedMarker}
            open={popupOpen}
            close={handleMarkerPopupClose}
            top={clickedMarkerPosition?.y ?? 0}
            left={clickedMarkerPosition?.x ?? 0}
            elementHeight={100}
            elementWidth={100}
          />
        )}
      </Map>
    </Box>
  );
};

