interface CategoryIcon {
  categoryName: string;
  iconName: string;
}

export const CategoryIcons: CategoryIcon[] = [
  {
    categoryName: "Lebensmittel & Getränke",
    iconName: "lebensmittel",
  },
  {
    categoryName: "Bürobedarf & Technik",
    iconName: "bueroTechnik",
  },
  {
    categoryName: "Drogerie & Beauty",
    iconName: "beauty",
  },
  {
    categoryName: "Restaurants",
    iconName: "restaurant",
  },
  {
    categoryName: "Bekleidung & Schuhe",
    iconName: "kleidung",
  },
  {
    categoryName: "Handwerk",
    iconName: "handwerk",    
  },
  {
    categoryName: "Gesundheit",
    iconName: "gesundheit",
  },
  {
    categoryName: "Schuhe",
    iconName: "schuhe",
  },
  {
    categoryName: "Kunst, Kunsthandwerk und Geschenke",
    iconName: "kunst",
  },
  {
    categoryName: "Taschen & Accessoires",
    iconName: "taschen",
  },
  {
    categoryName: "Sonstiges",
    iconName: "sonstiges",
  },
  {
    categoryName: "Schmuck & Uhren",
    iconName: "schmuck",
  },
  {
    categoryName: "Dienstleistungen",
    iconName: "dienstleistung",
  },
  {
    categoryName: "Haus und Garten",
    iconName: "hausGarten",
  },
  {
    categoryName: "Kinder",
    iconName: "kinder",
  },
  {
    categoryName: "Bücher & Zeitschriften",
    iconName: "buecher",
  },
  {
    categoryName: "DIY Gesichtsmasken",
    iconName: "gesichtsmasken",
  },
  {
    categoryName: "Sport, Hobby & Unterhaltung",
    iconName: "hobby",
  },
  {
    categoryName: "Corona Hilfsaktionen",
    iconName: "corona",
  },
  {
    categoryName: "Haustiere",
    iconName: "haustiere",
  },
  {
    categoryName: "default",
    iconName: "wirLiefernLogo",
  },
];
