import axios from "../../../network";
import environment from "configurations";
import { Testimonial } from "../models";

export const getLatestTestimonial = async (): Promise<Testimonial> => {
  return new Promise<Testimonial>((resolve, reject) => {
    axios.
      get("/Testimonial/GetHomepageTestimonial/")
      .then(function (response) {
        try {

          const testimonial: Testimonial = response.data;
          resolve(testimonial);

        } catch (r) {
          console.log("Error loading/parsing Testimonial data");
        }
      }).catch((r) => {
        console.log("Error loading/parsing Testimonial data");
      });
  });
};


export const getActiveTestimonial = async (): Promise<Testimonial[]> => {
  return new Promise<Testimonial[]>((resolve, reject) => {
    axios.
      get("/Testimonial/GetTestimonials/")
      .then(function (response) {
        try {

          const testimonials: Testimonial[] = response.data;
          resolve(testimonials);

        } catch (r) {
          console.log("Error loading/parsing active Testimonials data");
        }
      }).catch((r) => {
        console.log("Error loading/parsing active Testimonials data");
      });
  });
};
