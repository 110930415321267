import { BoxProps, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Address } from "../models";

interface Props extends BoxProps {
  address: Address;
}

export const StoreAddress: React.FC<Props> = ({ address, ...rest }) => (
  <Stack direction="column" spacing={0} {...rest}>
    <Text fontSize="lg" fontWeight="bold">
      {`${address?.street ?? ""} ${address?.streetNumber ?? ""}`}
    </Text>
    <Text
      fontSize="lg"
      fontWeight="bold">{`${address?.zipCode ?? ""} ${address?.city ?? ""}`}</Text>
  </Stack>
);
