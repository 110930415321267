import { PaginatedStores, StoreForMaps } from "app/stores/models";
import { searchStores, searchStoresForMap } from "app/stores/services";
import { action, computed, flow, observable } from "mobx";
import { SearchParams } from "../models/index";
import { SLIDER_DEFAULT_VALUE, SortValue } from "./../constants";
import { isArray } from "util";

export class SearchRepository {
  @observable private _category: string;
  @observable private _subCategories: string[];
  @observable private _lat: number;
  @observable private _lng: number;
  @observable private _radiusKm: number;
  @observable private _perPage: number;
  @observable private _currentPage: number;
  @observable private _paginatedStores: PaginatedStores = {} as any;
  @observable private _sort: SortValue = "alphabetic";

  @observable private _storesForMap: StoreForMaps[] = {} as any;
  @observable private _shopsChanged: boolean;

  @observable private _zoomLevel: number;
  @observable private _searchTerm: string;

  @observable private _googleSearchTerm: string;

  constructor(searchParams: SearchParams) {
    const {
      category,
      subCategories,
      lat,
      lng,
      radiusKm,
      perPage,
      currentPage,
      sort,
      zoomLevel,
      searchTerm,
      googleSearch
    } = searchParams;

    this._category = category ?? "";
    // aus unbekanten Gründen wird hier kein Array übergeben, wenn nur ein Eintrag in der SubCategory hinterlegt ist
    if (subCategories && !isArray(subCategories)) {
      this._subCategories = [subCategories];
    } else {
      this._subCategories = subCategories ?? [];
    }
    //this._lat = lat ?? 52.52;
    //this._lng = lng ?? 13.405;
    //this._lat = lat ?? 51.158352;
    //this._lng = lng ?? 10.441210;
    this._lat = lat ?? 0;
    this._lng = lng ?? 0;
    this._radiusKm = radiusKm ?? SLIDER_DEFAULT_VALUE; // 10 km default radius
    this._perPage = perPage ?? 10; //
    this._currentPage = currentPage ?? 1;
    if (sort != undefined) {
      this._sort = sort ?? "alphabetic";
    }
    else {
      this._sort = this._lng > 0 && this._lat > 0 ? "distance" : "alphabetic";
    }
    this._storesForMap = [];
    this._shopsChanged = false;
    this._zoomLevel = zoomLevel ? +zoomLevel : 6;
    this._searchTerm = searchTerm ?? "";

    this._googleSearchTerm = googleSearch ?? "";
  }

  @action.bound setCategory(category: string) {
    this._category = category;
  }

  @action.bound setSubCategories(subCategories: string[]) {
    this._subCategories = subCategories;
  }

  @action.bound setLat(lat: number) {
    this._lat = lat;
  }

  @action.bound setLng(lng: number) {
    this._lng = lng;
  }

  @action.bound setRadiusKm(radiusKm: number) {
    this._radiusKm = radiusKm;
  }

  @action.bound setPerPage(perPage: number) {
    this._perPage = perPage;
  }

  @action.bound setCurrentPage(currentPage: number) {
    this._currentPage = currentPage;
  }

  @action.bound setSort(sort: SortValue) {
    this._sort = sort;
  }

  @action.bound setZoom(zoom: number) {
    this._zoomLevel = zoom;
  }

  @action.bound setSearchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
  }

  @action.bound setGoogleSearchTerm(searchTerm: string) {
    this._googleSearchTerm = searchTerm;
  }

  fetchStoresForPage = flow(function* (this: SearchRepository) {
    try {
      let params = {
        category: this._category,
        subCategories: this._subCategories,
        lat: this._lat,
        lng: this._lng,
        radiusKm: this._radiusKm,
        perPage: this._perPage,
        currentPage: this._currentPage,
        sort: this._sort,
        searchTerm: this._searchTerm
      };
      const paginatedStores: PaginatedStores = yield searchStores(params);
      this._paginatedStores = paginatedStores;

      this._shopsChanged = true;

      this.fetchStoresForMap();
    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  fetchStoresForMap = flow(function* (this: SearchRepository) {
    try {
      let params = {
        category: this._category,
        subCategories: this._subCategories,
        lat: this._lat,
        lng: this._lng,
        searchTerm: this._searchTerm
      };
      const storesForMap: StoreForMaps[] = yield searchStoresForMap(params);
      this._storesForMap = storesForMap;

    } catch (error) {
      console.error(error);
    }
  }).bind(this);

  @computed get category(): string {
    return this._category;
  }

  @computed get subCategories(): string[] {
    return this._subCategories;
  }

  @computed get lat(): number {
    return this._lat;
  }

  @computed get lng(): number {
    return this._lng;
  }

  @computed get radiusKm(): number {
    return this._radiusKm;
  }

  @computed get perPage(): number {
    return this._perPage;
  }

  @computed get currentPage(): number {
    return this._currentPage;
  }

  @computed get paginatedStores(): PaginatedStores {
    return this._paginatedStores;
  }

  @computed get sort(): SortValue {
    return this._sort;
  }

  @computed get storesForMap(): StoreForMaps[] {
    return this._storesForMap;
  }

  @computed get shopsChanged(): boolean {
    return this._shopsChanged;
  }

  @computed get zoom(): number {
    return this._zoomLevel;
  }

  @computed get searchTerm(): string {
    return this._searchTerm;
  }

  @computed get googleSearchTerm(): string {
    return this._googleSearchTerm;
  }

  @computed get currentSearchUrl(): string {
    let searchUrl = "/search";
    searchUrl = addParameterToSearchString(searchUrl, "currentPage", this._currentPage, 1);
    searchUrl = addParameterToSearchString(searchUrl, "perPage", this._perPage, 10);
    searchUrl = addParameterToSearchString(searchUrl, "category", this._category, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "subCategories", this._subCategories, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "lat", this._lat != 0 ? this._lat : undefined, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "lng", this._lng != 0 ? this._lng : undefined, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "searchTerm", this._searchTerm, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "googleSearch", this._googleSearchTerm, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "zoomLevel", this._zoomLevel, undefined);
    searchUrl = addParameterToSearchString(searchUrl, "sort", this._sort, undefined);

    return searchUrl;
  }
}

function addParameterToSearchString(searchString: string, parametername: string, parameterValue: any, defaultValue: any) {
  if ((parameterValue === undefined || parameterValue.toString().length == 0) &&
    (defaultValue === undefined || defaultValue.toString().length == 0)) {
    return searchString;
  }

  const searchContainsQuestionmark = searchString.indexOf('?') >= 0;
  const startSign = searchContainsQuestionmark ? "&" : "?";
  let newPart = startSign + parametername + "=" + (parameterValue !== undefined ? parameterValue.toString() : defaultValue);
  return searchString + newPart;
}
