import { BoxProps, Flex, Text, Textarea } from "@chakra-ui/react";
import { Button, Rating, Popup } from "app/shared";
import { Ratings } from "app/shared/rating/constants";
import { StoreId } from "app/stores/models";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { useMediaQueryContext } from "styles/context";
import { useCommentRepositoryContext } from "../repositories";
import { rateAndCommentStore, commentForStoreExists } from "../services";
import { useRootRepositoryContext } from "rootRepository";

import { LoginForm } from "../../shared/navbar/loginMenu/LoginForm";

interface Props extends BoxProps {
  storeId: StoreId;
}

const StoreAddCommentBase: React.FC<Props> = ({ storeId }) => {
  const { commentRepository } = useCommentRepositoryContext();
  const { userRepository } = useRootRepositoryContext();
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState<Ratings>(0);
  const [commentAreaVisible, setCommentAreaVisible] = useState(true);
  const [error, setError] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setComment(inputValue);
  };
  const user = userRepository.user;

  const handleAddComment = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (user?.id === undefined) {
      return;
    }

    if (comment === "") {
      return;
    }

    if (comment.length > 500) {
      return;
    }

    if (rating < 0 || rating > 5) {
      return;
    }

    rateAndCommentStore(storeId, user?.id != undefined ? user?.id : "", { comment, rating }).then((response) => {
      commentRepository.fetchCommentsForPage(storeId);
      setError("");
      setCommentAreaVisible(false);
    }).catch((errorMessage) => {
      setError(errorMessage);
    });
    // Reset status
    setComment("");
    setRating(0);
  };


  if (user?.id != undefined) {
    commentForStoreExists(storeId).then((response) => {
      setCommentAreaVisible(!response);
    });
  }

  const { isSmall, isSmallMedium, isMedium } = useMediaQueryContext();

  const width = isSmall
    ? "250px"
    : isSmallMedium
      ? "300px"
      : isMedium
        ? "500px"
        : "600px";


  return commentRepository.status === "successful" && commentAreaVisible ? (
    <Flex direction="column">
      <Text fontSize={["3xl", "3xl", "4xl", "5xl"]} fontWeight="bold">
        {i18next.t("comments:storeAddComment.header")}
      </Text>
      <Rating
        rating={rating}
        inactiveColor="darkestGrey"
        activeColor="white"
        setRating={setRating}
      />
      <Textarea
        value={comment}
        onChange={handleInputChange}
        boxSize="sm"
        mt={[4, 6, 8]}
        mb={[4, 6, 10]}
        bg="white"
        color="black"
        borderRadius="0"
        resize="none"
        width={width}
        height="184px"
        maxLength={500}
      />
      {
        error && error.length > 0 && (
          <Text
            color="brandRed.400"
            fontWeight="bold"
          >
            {error}
          </Text>
        )
      }

      {userRepository?.user ?
        (
          <Button
            isDisabled={comment === ""}
            w={["90%", "90%", "40%", "15%"]}
            onClick={handleAddComment}>
            {i18next.t("comments:storeAddComment.comment")}
          </Button>
        )
        : (
          <Popup
            trigger={(onClose) => (
              <Button
                isDisabled={comment === ""}
                w={["90%", "90%", "40%", "15%"]}
                onClick={onClose}>
                {i18next.t("comments:storeAddComment.comment")}
              </Button>
            )}
            content={(onClose) => <LoginForm isInPopup={true} onLogin={() =>
              <Button
                isDisabled={comment === ""}
                w={["90%", "90%", "40%", "15%"]}
                onClick={onClose}>
                {i18next.t("comments:storeAddComment.comment")}
              </Button>

            } />}
            contentMaxWidth={["100%", "500px", "500px", "600px", "600px"]}
          />
        )}


    </Flex>
  ) : (
      <></>
    );
};

export const StoreAddComment = compose<Props, Props>(observer)(
  StoreAddCommentBase
);
