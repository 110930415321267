import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { StoreCard } from "app/stores";
import { PaginatedStores, StoreForList, Store } from "app/stores/models";
import i18next from "i18next";
import debounce from "lodash.debounce";
import React, { useRef } from "react";
import { OnlyPickup } from "./OnlyPickup";
import { Paginator } from "./Paginator";
import { useSearchRepositoryContext } from "../../search/repositories";
import { DEFAULT_OPENING_HOURS } from "app/stores/forms/StoreInfoSchema";

interface Props extends BoxProps {
  searchResultsPage?: PaginatedStores;
  onCardMouseEnter: (store: StoreForList) => void;
  onCardMouseLeave: () => void;
}

export const StoresSearchResults: React.FC<Props> = ({
  searchResultsPage,
  onCardMouseEnter,
  onCardMouseLeave,
  ...rest
}) => {
  const { searchRepository } = useSearchRepositoryContext();
  const searchResultPageContext = searchRepository?.paginatedStores;
  const stores = searchResultPageContext?.data ?? [];

  const initialLoad = searchResultPageContext?.data === undefined;

  let currentSearchUrl = searchRepository?.currentSearchUrl;
  localStorage.setItem("wirLiefer_searchUrl", currentSearchUrl);

  const refForSearchResults: any = useRef(null);
  const executeScroll = () => {
    window.scroll({ left: 0, top: refForSearchResults?.current?.offsetTop - 120 ?? 0, behavior: 'smooth' });
  };

  const renderNoResults = (
    <Box>
      <Text fontSize="lg" color="white" fontWeight="bold">
        {i18next.t("search:searchResults.storesSearchResults.noResults")}
      </Text>
    </Box>
  );

  let storesForCard: Store[] = [];
  for (let i = 0; i < stores.length; i++) {
    storesForCard.push({
      openingHours: DEFAULT_OPENING_HOURS,
      address: stores[i].address,
      coordinates: stores[i].coordinates,
      favorite: stores[i].favorite,
      id: stores[i].id,
      description: stores[i].description,
      numComments: stores[i].numComments,
      numContacts: stores[i].numContacts,
      numFavorites: stores[i].numFavorites,
      numViews: stores[i].numViews,
      rating: stores[i].rating,
      mainPhotoUrl: stores[i].mainPhotoUrl,
      photos: [{ url: stores[i].mainPhotoUrl, id: "" }],
      categories: [],
      contacts: {
        email: "",
        telephone: "",
        websiteUrl: ""
      },
      deliveryOptions: stores[i].deliveryOptions,
      name: stores[i].name,
      typeOfStore: stores[i].typeOfStore,
      city: stores[i].address?.city,
      country: stores[i].address?.country,
      street: stores[i].address?.street,
      streetNumber: stores[i].address?.streetNumber,
      zipCode: stores[i].address?.zipCode,
      distance: stores[i].distance,
      videoUrl: ""
    });
  }

  const renderResults = (
    <Box ref={refForSearchResults}>
      {stores.length === 0 && !initialLoad && (
        <Stack
          w={["95%", "95%", "580px"]}
          maxW={"95%"}
          ml={[4, 4, 4, 4, 0]}
          mt="20px"
        >
          <Box>
            <Text fontSize="lg" color="white">
              {i18next.t("search:searchResults.storesSearchResults.noResults")}
              <strong>  
                  "{searchRepository?.searchTerm ?? ""}"
              </strong>
             
              {i18next.t("search:searchResults.storesSearchResults.noResults2")}
              <strong>
                "{searchRepository?.searchTerm ?? ""}"
              </strong>
              {i18next.t("search:searchResults.storesSearchResults.noResults3")}
            </Text>
          </Box>
        </Stack>
      )}
      {stores.map((store, index) => (
        <Stack
          key={`store_search_${store.id}_${index}`}
          position="relative"
          direction="row"
          ml={[0, 4, 4, 4, 0]}
          justifyContent={["center", "center", "center", "center", "flex-start"]}
          maxW="580px"
          w={["100%", "95%", "580px"]}>
          {store.deliveryOptions.canPickup &&
            !store.deliveryOptions.canDeliver && <OnlyPickup />}
          <StoreCard
            store={storesForCard[index]}
            showTriangle={true}
            my={[4, 4, 4, 4, 4]}
            maxW="556px"
            w={["93%", "93%", "556px"]}
            onMouseEnter={() =>
              debounce(() => {
                onCardMouseEnter(store);
              }, 20)()
            }
            onMouseLeave={() =>
              debounce(() => {
                onCardMouseLeave();
              }, 20)()
            }
          />
        </Stack>
      ))}
      {searchResultPageContext && stores.length > 0 && (
        <Paginator
          onPageChanged={executeScroll}
          mt={1}
          nextPageUrl={searchResultPageContext.nextPageUrl}
          prevPageUrl={searchResultPageContext.prevPageUrl}
          currentPage={searchResultPageContext.currentPage}
          perPage={searchResultPageContext.perPage}
          totalResults={searchResultPageContext.totalResults}
          urlTemplate={searchResultPageContext.urlTemplate}
        />
      )}


    </Box>
  );

  return (
    <Stack


      direction="column"
      spacing={10}
      py={4}
      overflowX="hidden"
      {...rest}>
      {renderResults}
    </Stack>
  );
};

