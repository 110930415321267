import { StoreFormViewModel, model2ViewModel } from "./StoreInfoSchema";
import { BaseStoreForm, FormSubmissionStatus } from "./BaseStoreForm";
import { StoreWindowGrid } from "../storeWindowGrid";
import React, { useEffect } from "react";
import i18next from "i18next";
import { EditFormImageUploader } from "app/shared/forms/imageUploader/EditFormImageUploader";
import { StoreId } from "../models";
import { useStoreRepositoryContext } from "../repositories";
import { autorun } from "mobx";
import {
  Stack,
  Skeleton,
  Button,
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import { observer } from "mobx-react";

const tabStyle = {
  bg: "brandRed.300",
  color: "white",
  fontWeight: "bold",
  borderRadius: "0",
  border: "1px solid",
  borderColor: "grey",
  borderBottom: "0px",
  mr: "2",
};

/*
Exports a customized version of the base store form for the business edit
*/
interface Props {
  storeId: StoreId;
}

const EditStoreFormBase: React.FC<Props> = ({ storeId }) => {
  const { storeRepository } = useStoreRepositoryContext();

  useEffect(() => {
    autorun(() => storeRepository.fetchStore(storeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={20} direction="column">
      {/* REQUIRES ACCESS TO THE LOGGED IN USE: <ProfileInfo editDisabled={false} />*/}
      {storeRepository.store.id ? (
        <Box>
          <Button variant="unstyled" width="215px" marginBottom="25px">
            <Link to="/user">zurück zum Profil</Link>
          </Button>

          <Tabs variant="enclosed" colorScheme="red">
            <TabList>
              <Tab
                {...tabStyle}
                _selected={{ bg: "white", color: "lightBlack" }}
                _focus={undefined}>
                {i18next.t("stores:tabs.general")}
              </Tab>
              <Tab
                {...tabStyle}
                _selected={{ bg: "white", color: "lightBlack" }}
                _focus={undefined}>
                {i18next.t("stores:tabs.storeWindows")}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel borderColor="grey">
                <BaseStoreForm
                  initialState={model2ViewModel(storeRepository.store)}
                  imageUploaderComponent={EditFormImageUploader}
                  storeId={storeRepository.store.id}
                />
              </TabPanel>
              <TabPanel borderColor="grey">
                <StoreWindowGrid storeId={storeRepository.store.id} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      ) : (
        <Skeleton height="20px" my="10px" />
      )}
    </Stack>
  );
};

export const EditStoreForm = compose<Props, Props>(observer)(EditStoreFormBase);
