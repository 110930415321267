import { BoxProps, Stack, Flex, Text, Link, chakra } from '@chakra-ui/react';
import { Button, Popup } from 'app/shared';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React from 'react';
import { compose } from 'recompose';
import { useProductRepositoryContext } from '../repositories';
import { LoginForm } from '../../shared/navbar/loginMenu/LoginForm';
import { useRootRepositoryContext } from 'rootRepository';
import { LOCAL_DELIVERY } from 'app/shared/models';
import { ContactForm } from './ContactForm';

const ProductTextInfoBase: React.FC<BoxProps> = (props) => {
  const { userRepository } = useRootRepositoryContext();
  const { productRepository } = useProductRepositoryContext();
  const {
    name,
    price,
    description,
    link,
    deliveryOptions,
  } = productRepository.product;

  let tmp = document.createElement('DIV');
  tmp.innerHTML = description;
  const clearedDescription = tmp.textContent || tmp.innerText || '';

  return (
    <Stack direction="column" spacing={4} {...props}>
      <Stack>
        <Text fontSize={['3xl', '3xl', '4xl', '5xl']} fontWeight="bold">
          {name}
        </Text>

        <Text fontSize={['3xl', '3xl', '4xl', '5xl']} fontWeight="bold">
          {price} €
        </Text>
      </Stack>

      <Text fontSize="lg">{clearedDescription}</Text>

      {/* PBI 10267: Verfügbarkeit wird vorerst entfernt */}
      {/* <Stack direction="row" spacing={1} alignItems="center">
        <CircleIcon
          color={stock == AVAILABLE ? "lightGreen" : "red"}
          boxSize={7}
        />

        <Text fontSize="lg">
          {i18next.t(`products:productTextInfo.stockType.${stock}`)}
        </Text>
      </Stack> */}

      {link && (
        <Link
          fontSize="lg"
          color="brandRed.200"
          href={link.indexOf('http') > -1 ? link : 'https://' + link}
          target="_blank">
          {i18next.t('products:productTextInfo.showOnWebsite')}
        </Link>
      )}

      {/* DELIVERY OPTIONS */}
      <Stack>
        {deliveryOptions.canPickup && (
          <Stack spacing={0}>
            <Text fontSize="lg">
              <chakra.span fontWeight="bold">
                {i18next.t('products:productTextInfo.pickup')}:
              </chakra.span>{' '}
              {deliveryOptions.pickupDescription}
            </Text>
          </Stack>
        )}
        {deliveryOptions.canDeliver && (
          <Stack spacing={0}>
            <Flex>
              <Text fontSize="lg">
                <chakra.span fontWeight="bold">
                  {i18next.t('products:productTextInfo.delivery')}:
                </chakra.span>{' '}
                {i18next.t(
                  `products:productTextInfo.deliveryType.${deliveryOptions.deliveryType}`
                )}
                {deliveryOptions.deliveryType === LOCAL_DELIVERY &&
                  ` ${deliveryOptions.localDeliveryRadiusKm} 
                  ${i18next.t(
                    'products:productTextInfo.deliveryType.localDeliveryRadiusKm'
                  )}`}
              </Text>
            </Flex>
            <Text fontSize="lg">{deliveryOptions.deliveryDescription}</Text>
          </Stack>
        )}
      </Stack>

      {/* KONTAKTAUFNAHME */}
      {userRepository?.user ? (
        <Popup
          trigger={(onClose) => (
            <Button
              mr={4}
              w={['60%', '60%', '30%', '30%', '60%']}
              onClick={onClose}>
              <Text fontSize="md" fontWeight="bold" lineHeight="tall">
                {i18next.t('products:productTextInfo.contact')}
              </Text>
            </Button>
          )}
          content={(onClose) => <ContactForm onClose={onClose} />}
          contentMaxWidth={['100%', '100%', '80%', '80%', '1000px']}
        />
      ) : (
        <Popup
          trigger={(onClose) => (
            <Button
              mr={4}
              w={['60%', '60%', '30%', '30%', '60%']}
              onClick={onClose}>
              <Text fontSize="md" fontWeight="bold" lineHeight="tall">
                {i18next.t('products:productTextInfo.contact')}
              </Text>
            </Button>
          )}
          content={(onClose) => (
            <LoginForm
              isInPopup={true}
              onLogin={() => <ContactForm onClose={onClose} />}
            />
          )}
          contentMaxWidth={['100%', '500px', '500px', '600px', '600px']}
        />
      )}
    </Stack>
  );
};

export const ProductTextInfo = compose<{}, BoxProps>(observer)(
  ProductTextInfoBase
);
