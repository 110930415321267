import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  ModalBody,
  Text,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import i18next from "i18next";

export const CreatedPopup: React.FC = () => {
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen && firstRun) {
    onOpen();
    setFirstRun(false);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("shared:popups.titleCreated")}</Heading>
        </ModalHeader>
        <ModalBody mb={["80px"]}>
          <Text fontSize="lg">
            {i18next.t("shared:popups.textCreated")}
          </Text>
          <br />
          <br />
          <Link href="/user" color="brandRed.300" fontWeight="bold">
            {i18next.t("shared:popups.backToProfile")}
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
