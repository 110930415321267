import { CategoryMultiSelect } from "app/categories/categoryMultiSelect";
import { FullWidthContainer } from "app/shared/layout/FullWidthContainer";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { OpeningHoursDay, Store } from "../models";
import { LOCAL_DELIVERY } from "app/shared/models"
import environment from "configurations";

import {
  SimpleGrid,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import {
  StyledInput,
  Button,
  StyledTextarea,
  ValidationPopup,
  CreatingPopup,
  CreatedPopup,
  NeedLoginPopup,
} from "app/shared";
import {
  DeliveryTypeAndRadiusField,
  StoreCheckboxField,
  StoreFormField,
  StoreTimeField,
} from "./StoreFormFields";
import { StoreFormSchema, StoreFormViewModel } from "./StoreInfoSchema";
import { ImageUploaderProps } from "app/shared/forms/imageUploader";
import { createStore, updateStore } from "../services";

import { useMediaQueryContext } from "styles/context";
import { useRootRepositoryContext } from "../../../rootRepository";
import { CloseIcon } from "styles/icons";

var validationMessages = "";

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}

export type FormSubmissionStatus = Store | Error;

interface Props {
  initialState: StoreFormViewModel;
  storeId?: string;
  imageUploaderComponent: React.ComponentType<ImageUploaderProps>;
}

interface CategorieState {
  key: number;
  category: string;
}

export const BaseStoreForm: React.FC<Props> = (storeFormProps) => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const [showModalValidation, setShowModalValidation] = useState(false);
  const [showModalCreated, setShowModalCreated] = useState(false);
  const [showNeedLoginMessage, setShowNeedLoginMessage] = useState(false);
  const [googleMapsApi, setGoogleMapsApi] = useState<any>(undefined);
  const [category, setCategory] = useState<CategorieState[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [hasCoordinates, setHasCoordinates] = useState(false);
  const [hasCategory, setHasCategory] = useState(false);
  const [hasSubCategories, setHasSubCategories] = useState(false);
  const [categoryCount, setCategoryCount] = useState(0);

  const { userRepository, categoryRepository } = useRootRepositoryContext();

  const user = userRepository.user;

  useEffect(() => {
    const initialState = storeFormProps.initialState;
    if (storeFormProps.storeId != undefined) {
      setHasCoordinates(true);
      setHasCategory(true);
      setHasSubCategories(true);
    }

    if (initialState && initialState.category) {
      let initalCatState: CategorieState[] = [];
      if (initialState && initialState.category.length > 0) {
        for (let i = 0; i < initialState.category.length; i++) {
          initalCatState.push({ key: i, category: initialState.category[i] });
        }
      }
      setCategory(initalCatState);
    }

    if (initialState && initialState.subCategories.length > 0) {
      setSubCategories(initialState.subCategories);
    }

    setCategoryCount(
      initialState.category.length > 0 ? initialState.category.length - 1 : 0
    );
  }, [storeFormProps.initialState]);

  const submitHandler = (
    values: StoreFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    setShowModalCreation(true);

    getCoordinates(values)
      .then(() => {
        // remove all HTML Tags
        values.deliveryDescription = values.deliveryDescription?.replace(
          /<[^>]*>/g,
          ""
        );
        values.pickupDescription = values.pickupDescription?.replace(
          /<[^>]*>/g,
          ""
        );
        values.name = values.name.replace(/<[^>]*>/g, "");
        values.typeOfStore = values.typeOfStore.replace(/<[^>]*>/g, "");
        values.description = values.description.replace(/<[^>]*>/g, "");

        if (storeFormProps.storeId == undefined) {
          createStore(values)
            .then(() => {
              if (user?.ownedStores) {
                user.ownedStores = user?.ownedStores * 1 + 1;
              }
              setShowModalCreation(false);
              setShowModalCreated(true);
            })
            .catch((err: any) => {
              console.error(err.message);
              setShowModalCreation(false);
              if (err?.status === 401 || err?.response?.status === 401) {
                setShowNeedLoginMessage(true);
                userRepository.logout();
              }
            })
            .finally(() => handlerProps.setSubmitting(false));
        } else {
          updateStore(values)
            .then(() => {
              setShowModalCreation(false);
              setShowModalCreated(true);
            })
            .catch((err: any) => {
              console.error(err.message);
              setShowModalCreation(false);
              if (err?.status === 401 || err?.response?.status === 401) {
                setShowNeedLoginMessage(true);
                userRepository.logout();
              }
            })
            .finally(() => handlerProps.setSubmitting(false));
        }
      })
      .catch(() => {
        validationMessages +=
          "<b>Adresse:</b> " +
          i18next.t(`stores:form.validation.AddressNotValid`) +
          "<br />";
        setShowModalCreation(false);
        setShowModalValidation(true);
        handlerProps.setSubmitting(false);
      });
  };
  const {
    isSmall,
    isSmallMedium,
    isMedium,
    isMediumLarge,
    isLarge,
    isVeryLarge,
  } = useMediaQueryContext();

  const getCoordinates = async (values: StoreFormViewModel) => {
    return new Promise<void>((resolve, reject) => {
      setShowModalCreation(true);

      // Try to get Coordinates
      fetch(
        environment.googleMapsGeoCodingUrl +
          encodeURI(values.address) +
          "&key=" +
          environment.googleMapsKeyGeocoding
      )
        .then((response) => {
          return response.json();
        })
        .then((resultsFromGoogleMaps: any) => {
          try {
            let resultForOutput: boolean = false;
            let results = resultsFromGoogleMaps.results;

            if (
              results != null &&
              results != undefined &&
              results != "" &&
              results.length > 0
            ) {
              // get first hit
              if (
                results[0].geometry != null &&
                results[0].geometry != undefined &&
                results[0].geometry != ""
              ) {
                if (
                  results[0].geometry.location != null &&
                  results[0].geometry.location != undefined &&
                  results[0].geometry.location != ""
                ) {
                  if (
                    results[0].geometry.location.lat != null &&
                    results[0].geometry.location.lat != undefined &&
                    results[0].geometry.location.lat != "" &&
                    results[0].geometry.location.lat != 0 &&
                    results[0].geometry.location.lng != null &&
                    results[0].geometry.location.lng != undefined &&
                    results[0].geometry.location.lng != "" &&
                    results[0].geometry.location.lng != 0
                  ) {
                    values.coordinates = results[0].geometry.location;
                    resultForOutput = true;
                  }
                }
              }
            }

            if (resultForOutput) {
              resolve();
            } else {
              reject();
            }
          } catch (x) {
            console.log("Error loading/parsing Google Maps Data");
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  const renderActualForm = (
    isSmall: boolean,
    isSmallMedium: boolean,
    isMedium: boolean,
    props: FormikProps<StoreFormViewModel>
  ) => {
    const { values, handleSubmit } = props;

    const CustomImageUploader = storeFormProps.imageUploaderComponent;
    const isDeliveryEnabled = values.canDeliver;
    const isPickupEnabled = values.canPickup;
    const openingHoursErrors = props?.errors?.openingHours
    const openingHoursDaysErrors: FormikErrors<OpeningHoursDay | undefined>[] = [
      openingHoursErrors?.monday,
      openingHoursErrors?.tuesday,
      openingHoursErrors?.wednesday,
      openingHoursErrors?.thursday,
      openingHoursErrors?.friday,
      openingHoursErrors?.saturday,
      openingHoursErrors?.sunday
    ];
    const center = { lat: values.coordinates.lat, lng: values.coordinates.lng };

    //const onChangeAddress = (coords: Coords, place: any) => {
    //  try {
    //    const address = parseAddressComponents(place);
    //    //props.setFieldValue("addressDetails", address);
    //    props.setFieldValue("address", place.formatted_address);
    //    props.setFieldTouched("address", true);

    //    props.setFieldValue("coordinates", coords);
    //    props.setFieldTouched("coordinates", true);

    //    setHasCoordinates(coords != null && coords.lat != null && coords.lat != undefined && coords.lat != 0);
    //    //props.validateForm();
    //  } catch (err) {
    //    console.log("Invalid place detected:");
    //    console.log(place);
    //    console.log("Exception:", err);
    //  }
    //};

    const onStoreSubCategoryUpdate = (subCategories: string[]) => {
      props.setFieldValue("subCategories", subCategories);
      props.setFieldTouched("subCategories", true);
      setSubCategories(subCategories);
      setHasSubCategories(
        subCategories != undefined && subCategories.length > 0
      );
      props.validateForm();
    };

    const onStoreCategoryUpdate = (
      categorie: string,
      oldCatValue?: string,
      index?: number
    ) => {
      if (oldCatValue && oldCatValue.length > 0 && categorie === "") {
        // Kategorie wurde gelöscht -> aus dem State entfernen
        setCategory(category.filter((c) => c.key !== index));
      } else if (categorie.length > 0) {
        category.push({ key: index ?? 0, category: categorie });
        setCategory(category);
      }

      let catsForProps: string[] = category.map((c) => c.category);
      props.setFieldValue("category", catsForProps);
      props.setFieldTouched("category", true);

      setHasCategory(categorie != undefined && category.length > 0);
      props.validateForm();
    };

    const onCategorySelectRemove = (index: number) => {
      let selectedCategory =
        category.filter((c) => c.key === index)[0]?.category ?? "";
      if (selectedCategory && selectedCategory.length > 0) {
      }
      // subCategorien entfernen
      const subCategoriesIds: string[] =
        categoryRepository.categories
          .find((c) => c.id === selectedCategory)
          ?.subCategories.map((subCategory) => subCategory.id) ?? [];
      let subCatsToRemove = "";
      for (let i = 0; i < subCategoriesIds.length; i++) {
        subCatsToRemove += subCategoriesIds[i] + ";";
      }
      let newSubCats = subCategories.filter(
        (sc) => subCatsToRemove.indexOf(sc) === -1
      );
      setSubCategories(newSubCats);
      props.setFieldValue("subCategories", newSubCats);
      props.setFieldTouched("subCategories", true);

      // Category entfernen
      let newCats = category.filter((c) => c.key !== index);
      setCategory(newCats);
      let catsForProps: string[] = newCats.map((c) => c.category);
      props.setFieldValue("category", catsForProps);
      props.setFieldTouched("category", true);

      // dafür sorgen, dass multiselect nicht mehr gerendert wird
      setCategoryCount(categoryCount - 1);
    };

    const drawAdditionalCatSelects = (indexes: number[]) => {
      // da beim Entfernen eines CategoryPickers die Nummerierung löchrig wird, werden vor dem "Zeichnen" der MultiSelects die Keys korrigiert
      for (let i = 0; i < category.length; i++) {
        category[i].key = i;
      }
      return (
        <Box>
          {indexes.map((item: number) => (
            <Box py={3} key={"additionalMultiselect_" + item}>
              <Box>
                <Button
                  width="30px"
                  mb={"5px"}
                  type="button"
                  onClick={(e) => {
                    onCategorySelectRemove(item);
                  }}>
                  <CloseIcon boxSize="15px" />
                </Button>
              </Box>
              <SimpleGrid columns={1}>
                <CategoryMultiSelect
                  index={item}
                  selectedCategory={
                    category && category.length > 0
                      ? category.filter((cs) => cs.key === item)[0]?.category
                      : ""
                  }
                  selectedSubCategories={subCategories}
                  setSelectedCategory={onStoreCategoryUpdate}
                  setSelectedSubCategories={onStoreSubCategoryUpdate}
                  autoSelectSubCategories={false}
                  width={["300px", "450px", "600px", "800px", "1000px"]}
                  hideOnSmall={false}
                />
              </SimpleGrid>
            </Box>
          ))}
        </Box>
      );
    };

    const getAdditionalCatSelects = () => {
      let arrayCatIndexes = [];
      for (let i = 0; i < categoryCount; i++) {
        arrayCatIndexes.push(i + 1);
      }
      return drawAdditionalCatSelects(arrayCatIndexes);
    };

    return (
      <Form onSubmit={handleSubmit}>
        <Box py={3}>
          <Text fontSize="xl" fontWeight="bold">
            {i18next.t(`stores:form.storeInfoTitle`)}
          </Text>
          <Flex
            justifyContent="flexStart"
            flexDirection={"column"}
            width={"100%"}>
            {/* Store information block*/}
            {/* Left panel */}
            <Box width={"100%"}>
              {/* Core store information */}
              <Box>
                <StoreFormField
                  fieldName="name"
                  fieldType="string"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />

                <StoreFormField
                  fieldName="street"
                  fieldType="string"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="streetNumber"
                  fieldType="string"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="zipCode"
                  fieldType="string"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="city"
                  fieldType="string"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="country"
                  fieldType="string"
                  disabled={true}
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />

                <StoreFormField
                  fieldName="typeOfStore"
                  fieldType="string"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                  labelWidthPx={isSmallMedium ? "300px" : undefined}
                />
                <StoreFormField
                  fieldName="description"
                  fieldType="string"
                  Component={StyledTextarea}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
              </Box>
              {/* Photos - The custom image uploader is the component passed in the props */}
              <Box>
                <CustomImageUploader fieldName="photos" parentForm={props} />
              </Box>
            </Box>
            {/* Store information block*/}
            {/* Right panel */}
            <Box width={"100%"}>
              {/* Delivery options */}
              <Box>
                <SimpleGrid columns={2} spacing={10}>
                  <Box>
                    <StoreCheckboxField
                      fieldName="canDeliver"
                      fieldType="boolean"
                      labelWidthPx="80px"
                    />
                    <StoreFormField
                      fieldName="deliveryDescription"
                      fieldType="text"
                      Component={StyledTextarea}
                      disabled={!isDeliveryEnabled}
                      labelWidthPx="0px"
                    />
                  </Box>
                  <Box>
                    <StoreCheckboxField
                      fieldName="canPickup"
                      fieldType="boolean"
                      labelWidthPx="80px"
                    />
                    <StoreFormField
                      fieldName="pickupDescription"
                      fieldType="text"
                      Component={StyledTextarea}
                      disabled={!isPickupEnabled}
                      labelWidthPx="0px"
                    />
                  </Box>
                </SimpleGrid>
                {isDeliveryEnabled && (
                  <Box bg={"brandRed.50"} mt={3}>
                    <DeliveryTypeAndRadiusField
                      deliveryTypeFieldName="deliveryType"
                      localDeliveryRadiusKmFieldName="localDeliveryRadiusKm"
                      disabled={!isDeliveryEnabled}
                      isLocalDelivery={values.deliveryType === LOCAL_DELIVERY}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>
        </Box>
        {/* Contact options / Opening Hours */}
        <Box>
          <FullWidthContainer bg="lightestGrey" py={10}>
            <Text fontSize="xl" fontWeight="bold">
              {i18next.t(`stores:form.contactInfoTitle`)}
            </Text>
            <Box width={"100%"}>
              <Box>
                <StoreFormField
                  fieldName="email"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="phoneNumber"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="websiteUrl"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
              </Box>
              <Box>
                <StoreFormField
                  fieldName="facebookUrl"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="twitterUrl"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
                <StoreFormField
                  fieldName="instagramUrl"
                  fieldType="text"
                  Component={StyledInput}
                  flexDirection={isSmallMedium ? "column" : "row"}
                />
              </Box>
            </Box>

            <Text fontSize="xl" fontWeight="bold" mt={10}>
              {i18next.t(`stores:form.openingHoursInfoTitle`)}
            </Text>
            <Text fontSize="lg">
              {i18next.t(`stores:form.openingHoursSubTitle`)}
            </Text>
            <Box width={"100%"}>
              <StoreTimeField 
                fieldName="monday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
              <StoreTimeField 
                fieldName="tuesday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
              <StoreTimeField 
                fieldName="wednesday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
              <StoreTimeField 
                fieldName="thursday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
              <StoreTimeField 
                fieldName="friday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
              <StoreTimeField 
                fieldName="saturday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
              <StoreTimeField 
                fieldName="sunday"
                flexDirection={isSmallMedium ? "column" : "row"}
              />
            </Box>
          </FullWidthContainer>
        </Box>
        {/* Category selection */}
        <Box py={3}>
          <Text fontSize="xl" fontWeight="bold">
            {i18next.t(`stores:form.categoryInfoTitle`)}
          </Text>
          <SimpleGrid columns={1}>
            <CategoryMultiSelect
              index={0}
              selectedCategory={
                category && category.length > 0
                  ? category.filter((cs) => cs.key === 0)[0]?.category
                  : ""
              }
              selectedSubCategories={subCategories}
              setSelectedCategory={onStoreCategoryUpdate}
              setSelectedSubCategories={onStoreSubCategoryUpdate}
              autoSelectSubCategories={false}
              width={["300px", "450px", "600px", "800px", "1000px"]}
              hideOnSmall={false}
            />
          </SimpleGrid>
        </Box>

        {getAdditionalCatSelects()}

        <Box>
          <Text mt="20px" fontSize="12px">
            {i18next.t(`stores:form.mandatoryText`)}
          </Text>
        </Box>

        <Box>
          <Flex
            direction="column"
            alignItems="center"
            borderTop="4px solid"
            borderColor="brandRed.300"
            mt={10}>
            <Button
              width="245px"
              type="button"
              onClick={(e) => {
                setCategoryCount(categoryCount + 1);
              }}>
              {i18next.t(`stores:form.addCat.add`)}
            </Button>
          </Flex>
        </Box>

        <Flex py={3} justifyContent="center">
          <Button
            width="200px"
            type="submit"
            onClick={(e) => {
              props.validateForm();

              validationMessages = "";

              if (
                !props.isValid ||
                props.isSubmitting ||
                !hasCategory ||
                !hasSubCategories
              ) {
                e.preventDefault();

                if (!props.values.name)
                  validationMessages +=
                    "<b>Name:</b> " +
                    i18next.t(`stores:form.validation.requiredName`) +
                    "<br />";
                if (!props.values.street)
                  validationMessages +=
                    "<b>Straße:</b> " +
                    i18next.t(`stores:form.validation.requiredStreet`) +
                    "<br />";
                if (!props.values.streetNumber)
                  validationMessages +=
                    "<b>Haunsnummer:</b> " +
                    i18next.t(`stores:form.validation.requiredStreetNumber`) +
                    "<br />";
                if (!props.values.zipCode)
                  validationMessages +=
                    "<b>PLZ:</b> " +
                    i18next.t(`stores:form.validation.requiredZipCode`) +
                    "<br />";
                if (!props.values.city)
                  validationMessages +=
                    "<b>Ort:</b> " +
                    i18next.t(`stores:form.validation.requiredCity`) +
                    "<br />";
                if (!props.values.country)
                  validationMessages +=
                    "<b>Land:</b> " +
                    i18next.t(`stores:form.validation.requiredCountry`) +
                    "<br />";
                if (!props.values.description)
                  validationMessages +=
                    "<b>Beschreibung:</b> " +
                    i18next.t(`stores:form.validation.requiredDescription`) +
                    "<br />";
                if (!props.values.typeOfStore)
                  validationMessages +=
                    "<b>Geschäftsart:</b> " +
                    i18next.t(`stores:form.validation.requiredTypeOfStore`) +
                    "<br />";
                if (
                  props.values.canDeliver &&
                  props.values.deliveryType &&
                  props.values.deliveryType === LOCAL_DELIVERY &&
                  !props.values.localDeliveryRadiusKm
                )
                  validationMessages +=
                    "<b>Lieferradius:</b> " +
                    i18next.t(
                      `stores:form.validation.requiredLocalDeliveryRadiusKm`
                    ) +
                    "<br />";
                if (!props.values.email)
                  validationMessages +=
                    "<b>E-Mail:</b> " +
                    i18next.t(`stores:form.validation.requiredEmail`) +
                    "<br />";
                if (!props.values.phoneNumber)
                  validationMessages +=
                    "<b>Telefonnummer:</b> " +
                    i18next.t(`stores:form.validation.requiredPhoneNumber`) +
                    "<br />";
                if (openingHoursDaysErrors.some(day => day?.firstStart || day?.firstEnd || day?.secondStart || day?.secondEnd))
                  validationMessages +=
                    "<b>Öffnungszeiten:</b> " +
                    i18next.t(`stores:form.validation.invalidTimeFormat`) +
                    "<br />";
                if (!props.values.photos || props.values.photos.length == 0)
                  validationMessages +=
                    "<b>Fotos:</b> " +
                    i18next.t(`stores:form.validation.requiredPhoto`) +
                    "<br />";
                else if (
                  props?.errors?.photos != null &&
                  props?.errors?.photos != undefined &&
                  props.errors.photos.length > 0
                )
                  validationMessages +=
                    "<b>Fotos:</b> " + props.errors.photos + "<br />";
                if (!hasCategory) {
                  validationMessages +=
                    "<b>Kategorie:</b> " +
                    "Bitte Geben Sie eine Kategorie an.<br />";
                } else if (!hasSubCategories) {
                  validationMessages +=
                    "<b>Unterkategorie:</b> " +
                    "Bitte Geben Sie eine Unterkategorie an.<br />";
                }
              }

              if (validationMessages != "") {
                setShowModalValidation(true);
              } else {
                let address = `${values.street} ${values.streetNumber} ${values.zipCode} ${values.city} ${values.country}`;
                props.setFieldValue("address", address);
                props.setFieldTouched("address", true);
              }
            }}>
            {i18next.t(`stores:form.submit.label`)}
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <>
      {showModalValidation && (
        <ValidationPopup
          setShowModalValidation={setShowModalValidation}
          validationMessages={validationMessages}
        />
      )}
      {showModalCreation && <CreatingPopup />}
      {showModalCreated && <CreatedPopup />}
      {showNeedLoginMessage && <NeedLoginPopup />}

      <Formik
        initialValues={storeFormProps.initialState}
        validationSchema={StoreFormSchema}
        onSubmit={submitHandler}>
        {(props) => renderActualForm(isSmall, isSmallMedium, isMedium, props)}
      </Formik>
    </>
  );
};