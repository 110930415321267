import {
  Box,
  BoxProps,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BottomTriangle, Rating, RightTriangle } from "app/shared";
import { TextLink } from "app/shared/textLink";
import React, { useState } from "react";
import { useMediaQueryContext } from "styles/context";
import { Store } from "../models";
import { StoreAddress } from "../storeAddress";
import { StoreFavorite } from "../storeFavorite";
import { toggleFavorite } from "../services";
import environment from "configurations";
import { useSearchRepositoryContext } from "../../search/repositories";
import { useRootRepositoryContext } from "../../../rootRepository";
import { CommentIcon } from "styles/icons";

interface Props extends BoxProps {
  store: Store;
  showTriangle: boolean;
}

export const StoreCard: React.FC<Props> = ({ store, showTriangle, ...rest }) => {
  const { isSmall, isMediumLarge } = useMediaQueryContext();
  let tmp = document.createElement("DIV");
  tmp.innerHTML = store.description;
  let description = tmp.textContent || tmp.innerText || "";

  const cuttedDesciption = description.substring(0, description.length < 40 ? description.length : 40).replace(/\s\s+/g, ' ').replace(/\n/g, " ");

  const entfernung = store.distance;
  let distanceToStore = Math.floor(entfernung)
    + " km entfernt";

  if (store.distance < 0) {
    distanceToStore = "";
  }


  const [localFavorite, setLocalFavorite] = useState(store.favorite);
  const { searchRepository } = useSearchRepositoryContext();
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <Stack
      direction="column"
      bg="lightestGrey"
      p={7}
      pb={4}
      mr={[0, 0, 6]}
      boxShadow="lg"
      borderRight={isMediumLarge ? undefined : "20px solid"}
      borderBottom={isMediumLarge ? undefined : undefined}
      borderColor="brandRed.300"
      position="relative"
      {...rest}>
      <Flex direction={["column", "column", "row"]}>
        <Box _hover={{ cursor: "pointer" }} mr={[0, 0, 6]} pb={[5, 5, 0]}>
          <Image
            width={["268px", "268px", "247px"]}
            height="193px"
            objectFit="contain"
            src={environment.site + store?.mainPhotoUrl ?? ""}
            onClick={() => window.open(`/store/${store.id}`, "_self")}
          />
        </Box>
        <Flex direction="column" width="217px" mr={[0, 0, 6]}>
          <TextLink
            isTruncated
            to={`/store/${store.id}`}
            fontSize="lg"
            color="brandRed.300"
            fontWeight="bold">
            {store.name}
          </TextLink>
          <Rating
            activeColor="darkGrey"
            inactiveColor="mdGrey"
            rating={store.rating}
          />

          <Text fontSize="lg" color="darkGrey" fontWeight="bold" height="60px" overflow="hidden">
            {cuttedDesciption}...
          </Text>

          <StoreAddress address={store.address} color="mdGrey" />
        </Flex>
      </Flex>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="row">
          <Box position="relative" mr={2}>
            <CommentIcon boxSize="27px" />
            <Text
              width="27px"
              fontSize="xxs"
              position="absolute"
              top={"2px"}
              textAlign="center"
              color="darkGrey">
              {store.numComments}
            </Text>
          </Box>
          <StoreFavorite
            isFavorite={localFavorite}
            width="20px"
            onClick={() => {
              if (user?.ownedFavorites) {
                if (localFavorite) {
                  user.ownedFavorites = (user?.ownedFavorites * 1) - 1;
                } else {
                  user.ownedFavorites = (user?.ownedFavorites * 1) + 1;
                }
              }
              toggleFavorite(store.id)
              setLocalFavorite(!localFavorite);
            }}
          />
          <Text
            mt="2px"
            ml="5px"
            width="100px"
            fontSize="xxs"
            position="relative"
            color="darkGrey">
            {distanceToStore}
          </Text>
        </Flex>
      </Flex>
      {isMediumLarge || !showTriangle ? (
        <></>
      ) : (
          <RightTriangle opacity={1} top={124} left={550} />
        )}
    </Stack>
  );
};
