import React from 'react';
import { defaultEmptyState } from './StoreWindowInfoSchema';
import { BaseStoreWindowForm } from './BaseStoreWindowForm';

interface Props {
  userId: string;
  storeId: string;
}

/*
  Exports a customized version of the base product form for the business creation use-case.
  */
const CreateStoreWindowForm: React.FC<Props> = ({ userId, storeId }) => {
  return (
    <BaseStoreWindowForm
      userId={userId}
      storeId={storeId}
      initialState={defaultEmptyState}
    />
  );
};

export default CreateStoreWindowForm;
