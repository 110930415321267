import { BoxProps, Heading } from "@chakra-ui/react";
import React from "react";

export const Headline: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Heading
    fontWeight="bold"
    fontSize={["3xl", "4xl", "7xl"]}
    color="darkGrey"
    lineHeight={["base", "base", "base"]}
    mb={[0, 0, 0, 6]}
    {...rest}
    size="lg">
    {children}
  </Heading>
);
