import {
  BoxProps,
  Box,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Button, CloseButton, OutsideClickWrapper } from "app/shared";
import { StyledRadio } from "app/shared/forms/StyledRadio";
import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { SortOptions, SortValue } from "../constants";
import { useSearchRepositoryContext } from "../../search/repositories";
import { SortIcon } from "styles/icons";


interface Props extends BoxProps {
  sort: string;
  setSort: (sort: SortValue) => void;
}

export const Sort: React.FC<Props> = ({ sort, setSort, left, ...rest }) => {
  const [open, setOpen] = useState(false);

  const { searchRepository } = useSearchRepositoryContext();

  const handleOnSortChanged = (sortBy: SortValue) => {
    searchRepository.setSort(sortBy);
    searchRepository.setCurrentPage(1);
    searchRepository.fetchStoresForPage();
  };

  return (
    <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
      <Box
        _hover={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
        boxShadow="0px 1px 5px 0px rgba(0,0,0,0.15)"
        zIndex={1}
        left={left}
        {...rest}>
        <Button
          width={`46px`}
          height={`37px`}
          bg={open ? "lightestGrey" : "brandRed.300"}
          hoverBgColor={open ? "grey" : undefined}
          activeBgColor={open ? "darkGrey" : undefined}>
          <SortIcon boxSize="30px" color={open ? "darkGrey" : "white"} />
        </Button>
      </Box>
      {open && (
        <Stack
          direction="column"
          spacing={4}
          bg="lightestGrey"
          position="absolute"
          left={left}
          top={["100%", "100%", 271]}
          w={["70%", "70%", "20%"]}
          py={2}
          px={4}
          zIndex={1}
          boxShadow="lg">
          <Text fontSize="lg" fontWeight="bold" color="darkGrey">
            {i18next.t("search:searchHeader.sort.header")}
          </Text>
          <RadioGroup
            value={sort}
            onChange={(e: any) => {
              setSort(e.target.value);
              handleOnSortChanged(e.target.value);
            }}>
            {SortOptions.map((sortOption, index) => (
              <StyledRadio key={`radio_${index}`} value={sortOption.value}>
                {i18next.t(sortOption.i18n)}
              </StyledRadio>
            ))}
          </RadioGroup>
          <CloseButton
            onClick={() => setOpen(false)}
            iconSize={"13px"}
            position="absolute"
            iconColor="brandRed.300"
            top={0}
            right={1}
          />
        </Stack>
      )}
    </OutsideClickWrapper>
  );
};
