import { SearchHeader, SearchParams } from "app/search";
import { SearchRepositoryProvider } from "app/search/repositories";
import { SearchResults } from "app/search/searchResults";
import { ContentArea } from "app/shared";
import { FullWidthContainer } from "app/shared/layout";
import queryString from "query-string";
import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { PaginatedStores } from "app/stores/models";
import useScript from "../utils/useScript";

type Props = RouteComponentProps;

const SearchBase: React.FC<Props> = ({ history }) => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'search', section: 'search', pubdate: '', author: '', url: '/search', object_type: 'search' }}); } ");

  const searchParams: SearchParams = queryString.parse(
    history.location.search,
    {
      arrayFormat: "comma",
    }
  );

  return (
    <>
      <ContentArea py={0} px={[0, 0, 0, 0, "105px"]}>
        <SearchRepositoryProvider searchParams={searchParams} >
          <SearchPageContents />
        </SearchRepositoryProvider>
      </ContentArea>
    </>
  );
};

export const Search = compose<Props, {}>(withRouter)(SearchBase);

const SearchPageContents: React.FC = () => {
  const [googleMapsApi, setGoogleMapsApi] = useState<any>(undefined);

  return (
    <>
      <FullWidthContainer bg="lightestGrey" py={0}>
        <SearchHeader maps={googleMapsApi} />
      </FullWidthContainer>
      <SearchResults maps={googleMapsApi} setGoogleMapsApi={setGoogleMapsApi} />
    </>
  );
};
