import { Grid, Stack } from "@chakra-ui/react";
import { Button, Heading } from "app/shared";
import i18next from "i18next";
import React from "react";
//import { Link } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BenefitCard } from "./BenefitCard";
import { ReactComponent as CustomersBenefitImage } from "./static/customersBenefit.svg";
import { ReactComponent as DealersBenefitImage } from "./static/dealersBenefit.svg";
//import { ReactComponent as FreeBenefitImage } from "./static/freeBenefit.svg";
import { ReactComponent as CityBenefitImage } from "./static/cityBenefit.svg";
import { useRootRepositoryContext } from "rootRepository";

export const BenefitsGrid: React.FC = () => {
  const { userRepository } = useRootRepositoryContext();

  const i18MailToLinkText = i18next.t("shared:benefitsGrid.freeBenefitMailTo");

  //const mailToLink = () => {
  //  // Mailto-Link wird bei Click zusammengebaut. Je nachdem ob der Nutzer angemeldet ist oder nicht, wird der Name des Nutzers im Placeholder eingesetzt
  //  const body = i18MailToLinkText.replace("{0}", userRepository.user ? `${userRepository.user.firstName} ${userRepository.user.lastName}%0D%0A` : "")
  //  window.location.href = "mailto:?body=" + body;
  //};

  return (
    <Stack flexDirection="column" alignItems="center" spacing={6}>
      <Heading>{i18next.t("shared:benefitsGrid.benefitsHeader")}</Heading>
      <Grid
        templateColumns={[
          "repeat(1, 220px)",
          "repeat(1, 270px)",
          "repeat(1, 370px)",
          "repeat(3, 220px)",
          "repeat(3, 370px)",
        ]}
        gap={6}>
        <BenefitCard
          imageView={() => <DealersBenefitImage />}
          title={i18next.t("shared:benefitsGrid.dealersBenefitTitle")}
          highlightedDesc={i18next.t(
            "shared:benefitsGrid.dealersBenefitHighlightDesc"
          )}
          desc={i18next.t("shared:benefitsGrid.dealersBenefitDesc")}
          btnView={() => (
            <Button>
              <Link to="/newUserAndStore">
                {i18next.t("shared:benefitsGrid.dealersBenefitBtn")}
              </Link>
            </Button>
          )}
        />
        <BenefitCard
          imageView={() => <CustomersBenefitImage />}
          title={i18next.t("shared:benefitsGrid.customersBenefitTitle")}
          highlightedDesc={i18next.t(
            "shared:benefitsGrid.customersBenefitHighlightDesc"
          )}
          desc={i18next.t("shared:benefitsGrid.customersBenefitDesc")}
          btnView={() => (
            <Button>
              <Link to="/search">
                {i18next.t("shared:benefitsGrid.customersBenefitBtn")}
              </Link>
            </Button>
          )}
        />
        <BenefitCard
          imageView={() => <CityBenefitImage />}
          title={i18next.t("shared:benefitsGrid.cityBenefitTitle")}
          highlightedDesc={i18next.t(
            "shared:benefitsGrid.cityBenefitHighlightDesc"
          )}
          desc={i18next.t("shared:benefitsGrid.cityBenefitDesc")}
          btnView={() => (
            <Button>
              <a href="https://lokal.wir-liefern.org/" target="_blank">
                {i18next.t("shared:benefitsGrid.cityBenefitBtn")}
              </a>
            </Button>
          )}
        />
      </Grid>
    </Stack>
  );
};
//href={"mailto:?body=" + mailToLink()}
