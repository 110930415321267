import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import i18next from "i18next";

const PreloadAnimation = "/images/preload.gif";

export const CreatingPopup: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen) {
    onOpen();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bg="rgb(245, 245,245)" maxW={["300px"]}>
        <ModalBody mb={["40px"]} width="300px" textAlign="center">
          <Text m={1} fontSize="lg">
            {i18next.t("shared:popups.textCreation")}
          </Text>
          <br />
          <br />
          <Image m="auto" src={process.env.PUBLIC_URL + PreloadAnimation} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
