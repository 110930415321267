import { Campaign } from "../models/index";
import axios from "../../../network";
import { ProductForCampaign } from "app/products/models";

export const getCampaign = async (): Promise<Campaign> => {
  return new Promise<Campaign>((resolve, reject) => {
    axios
      .get("/campaigns/GetActiveCampaign")
      .then(function (response) {
        try {
          let campaignData: Campaign = getCampaignFromData(response.data);
          resolve(campaignData);
        } catch (r) {
          console.log("Error loading/parsing Campaign data");
        }
      })
      .catch(() => {
        console.log("Error loading/parsing Campaign data");
      });
  });
};

function getCampaignFromData(data: any): Campaign {
  const products = getProductsFromData(data);

  return {
    id: data.id ?? "",
    name: data.name ?? "",
    text: data.text ?? "",
    products: products,
  };
}

function getProductsFromData(data: any): ProductForCampaign[] {
  if (!data) {
    return [];
  }

  if (!data.products) {
    return [];
  }

  const products: ProductForCampaign[] = [];
  for (let i = 0; i < data.products.length; i++) {
    const product: ProductForCampaign = {
      id: data.products[i].id ?? "",
      name: data.products[i].name ?? "",
      photo: {
        id: data.products[i].mainPhotoId ?? "",
        url: data.products[i].mainPhotoUrl ?? "",
      },
      mostRecentStoreWindowId: data.products[i].mostRecentStoreWindowId ?? "",
    };
    products.push(product);
  }

  return products;
}
