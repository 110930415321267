/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Stack,
  Grid,
  Box,
  BoxProps,
  AspectRatio,
  Image,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { useStoreWindowRepositoryContext } from '../repositories';
import { Button } from 'app/shared';
import { DEFAULT_RATIO } from 'app/shared/constants';
import { ProductCard } from './ProductCard';
import environment from 'configurations';

interface Props extends BoxProps {
  storeWindowId: string;
}

const StoreWindowInfoBase: React.FC<Props> = ({ storeWindowId }) => {
  const { storeWindowRepository } = useStoreWindowRepositoryContext();

  useEffect(() => {
    storeWindowRepository.fetchStoreWindow(storeWindowId);
  }, [storeWindowId, storeWindowRepository]);

  return storeWindowRepository.status === 'successful' ? (
    <Box>
      <Button width="215px" marginBottom="25px">
        <Link to={`/store/${storeWindowRepository.storeWindow.storeId}`}>
          {i18next.t('storeWindows:storeWindowInfo.back')}
        </Link>
      </Button>

      <Stack
        direction="column"
        spacing={[5, 5, 20]}
        pb={9}
        w={['100%', '100%', 'unset']}>
        {/* StoreWindow Title + Image */}
        <Stack direction="column" spacing={8}>
          <Box>
            <Text fontSize={['3xl', '3xl', '4xl', '5xl']} fontWeight="bold">
              {storeWindowRepository.storeWindow.name}
            </Text>
          </Box>

          <Box w={['250px', '300px', '648px', '810px', '1232px']} color="black">
            <AspectRatio ratio={DEFAULT_RATIO}>
              <Box>
                <Image
                  objectFit="contain"
                  width="100%"
                  height="100%"
                  src={
                    environment.site +
                    storeWindowRepository.storeWindow.photo?.url
                  }
                />
              </Box>
            </AspectRatio>
          </Box>
        </Stack>

        {/* Products */}
        <Stack direction="column" spacing={8}>
          <Box>
            <Text fontSize="lg">
              {i18next.t('storeWindows:storeWindowInfo.productsInfoText')}
            </Text>
          </Box>

          <Box w={['250px', '300px', '648px', '810px', '1232px']} color="black">
            <Grid templateColumns="repeat(3, 1fr)" gap={8}>
              {storeWindowRepository.storeWindow.products?.map(
                (item, index) => (
                  <Box key={`item_${index}`}>
                    <ProductCard product={item} />
                  </Box>
                )
              )}
            </Grid>
          </Box>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <></>
  );
};

export const StoreWindowInfo = compose<Props, Props>(observer)(
  StoreWindowInfoBase
);
