import { Grid, GridProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import themeGet from "@styled-system/theme-get";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { customColors } from "styles";
import { PAGES_WITH_GREY_LAYOUT } from "./constants";

const StyledMainLayout = styled(Grid) <GridProps>`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas:
    "navbar"
    "header"
    "content"
    "footer";

  ${themeGet("mediaQueries.md")} {
    grid-template-columns:
      auto minmax(auto, ${themeGet("sizes.maxWidth")})
      auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "navbar navbar navbar"
      "header header header"
      "...... content ......"
      "footer footer footer";
  }

`;

/*


${themeGet("mediaQueries.vlg")} {
    grid-template-columns:
      auto minmax(auto, ${themeGet("sizes.maxWidth")})
      auto;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "navbar navbar navbar"
      "header header header"
      "...... content ......"
      "footer footer footer";
  }

 * */

interface Props extends GridProps, RouteComponentProps { }

const MainLayoutBase: React.FC<Props> = ({ history, ...rest }) => {
  const greyLayout =
    PAGES_WITH_GREY_LAYOUT.filter((page) =>
      history.location.pathname.includes(page)
    ).length !== 0;

  return (
    <StyledMainLayout
      background={greyLayout ? customColors.darkGrey : "white"}
      color={greyLayout ? "white" : "black"}      
      {...rest}
      />
  );
};

MainLayoutBase.defaultProps = {
  height: "100%",
};

export const MainLayout = compose<Props, {}>(withRouter)(MainLayoutBase);
