import { Flex, Stack, BoxProps } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { LoginMenu } from "../loginMenu";
import { DrawerMenuLink } from "./DrawerMenuLink";
import { useRootRepositoryContext } from "../../../../rootRepository";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react";
import { compose } from "recompose";
import { LoginIcon } from "styles/icons";

interface PropsExternal extends BoxProps {
  onClose?: () => void;
}

interface Props extends PropsExternal, RouteComponentProps {}

const MobileDrawerMenuContentBase: React.FC<Props> = ({ history, onClose }) => {
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <Stack
      direction="column"
      py={8}
      justifyContent="center"
      alignItems="space-between"
      maxWidth="250px">
      {user ? (
        <DrawerMenuLink
          href="/user"
          name={i18next.t("shared:navbar.burgerDrawer.user")}
          onClick={onClose}
          iconVisible={true}
        />
      ) : (
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <LoginMenu color="white" onClose={onClose} />
        </Flex>
      )}
      <DrawerMenuLink
        href="/search"
        name={i18next.t("shared:navbar.burgerDrawer.storeSearch")}
        onClick={onClose}
        iconVisible={true}
      />
      <DrawerMenuLink
        href="/newUserAndStore"
        name={i18next.t("shared:navbar.burgerDrawer.storeCreate")}
        onClick={onClose}
        iconVisible={true}
      />
      <DrawerMenuLink
        href="/fur-nutzer"
        name={i18next.t("shared:navbar.burgerDrawer.forUsers")}
        onClick={onClose}
        iconVisible={true}
      />
      <DrawerMenuLink
        href="/fur-geschafte"
        name={i18next.t("shared:navbar.burgerDrawer.forStore")}
        onClick={onClose}
        iconVisible={true}
      />
      <DrawerMenuLink
        href="/about"
        name={i18next.t("shared:navbar.burgerDrawer.about")}
        onClick={onClose}
        iconVisible={true}
      />
      <DrawerMenuLink
        href="/news"
        name={i18next.t("shared:navbar.burgerDrawer.news")}
        onClick={onClose}
        iconVisible={true}
      />
      <DrawerMenuLink
        href="/kontakt"
        name={i18next.t("shared:navbar.burgerDrawer.contact")}
        onClick={onClose}
        iconVisible={true}
      />
      {user && (
        <DrawerMenuLink
          onClick={() => {
            userRepository.logout();
            if (onClose) onClose();
            history.push("/");
          }}
          name={i18next.t("shared:navbar.burgerDrawer.logout")}
          iconVisible={true}
        />
      )}
    </Stack>
  );
};

export const MobileDrawerMenuContent = compose<Props, PropsExternal>(
  withRouter,
  observer
)(MobileDrawerMenuContentBase);
