import { Box, BoxProps, Flex, Stack, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CategoryMultiSelect } from "app/categories";
import { GoogleSearchBox } from "app/shared/maps/GoogleSearchBox";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { useSearchRepositoryContext } from "../repositories";
import { RadiusSlider } from "./RadiusSlider";
import { Sort } from "./Sort";
import { useRootRepositoryContext } from "rootRepository";
import { Headline } from "app/shared";
import { StyledInput } from "app/shared/forms/StyledComponents";
import { useMediaQueryContext } from "styles/context";

const SearchHeaderText = styled(Text) <BoxProps>``;
SearchHeaderText.defaultProps = {
  fontSize: ["3xl", "4xl", "5xl", "5xl", "7xl"],
  fontWeight: "bold",
  color: "brandRed.300",
  wordBreak: "break-word",
  lineHeight: "none",
  maxW: ["250px", "300px", "500px", "500px", "unset"],
};

const resultsTextStyle = {
  fontSize: "xl",
  fontWeight: "bold",
  color: "darkGrey",
  pb: [5, 5, 5, 0, 0],
};

interface Props extends BoxProps {
  maps: any;
}

const SearchHeaderBase: React.FC<Props> = ({ maps, ...rest }) => {
  useEffect(() => {

  }, []);

  const { searchRepository } = useSearchRepositoryContext();
  const { categoryRepository } = useRootRepositoryContext();
  const {
    category,
    subCategories,
    radiusKm,
    sort,
    searchTerm,
    googleSearchTerm,
    setCategory,
    setSubCategories,
    setLat,
    setLng,
    setRadiusKm,
    setSort,
    setZoom,
    setSearchTerm,
    setGoogleSearchTerm
  } = searchRepository;

  const [searchInputValue, setSearchInputValue] = useState<string>(searchTerm ?? "");
  const [googleInputValue, setGoogleInputValue] = useState<string>(googleSearchTerm ?? "");
  const [currentTimeOut, setCurrentTimeOut] = useState<any>(null);
  const [timeout, setTimeOut] = useState<boolean>(false);

  const handlefreeTextSearch = (e: React.FormEvent<HTMLInputElement>) => {

    setSearchInputValue(e.currentTarget.value);
    setSearchTerm(e.currentTarget.value);

    if (currentTimeOut) {
      window.clearTimeout(currentTimeOut);
    }
    setCurrentTimeOut(window.setTimeout(() => {
      searchRepository?.setCurrentPage(1);
      searchRepository?.fetchStoresForPage();
    }, 750));
  }

  let categoryName: string = category
    ? categoryRepository.categories.find(c => c.id === category)?.categoryName ?? ""
    : "";

  const { isSmallMedium } = useMediaQueryContext();

  const getGoogleSearchBox = () => {
    return <GoogleSearchBox
      maps={maps}
      initialValue={googleInputValue}
      onBlur={(isEmpty) => {
        if (isEmpty) {
          setLat(0);
          setLng(0);
          setZoom(6);
          searchRepository?.setCurrentPage(1);
          searchRepository?.fetchStoresForPage();
        }
      }}
      handleChange={(value: string) => {
        setGoogleSearchTerm(value);
        setGoogleInputValue(value);
      }}
      onChange={(location) => {
        setLat(location.lat);
        setLng(location.lng);
        setZoom(10);

        if (location && location.lat != 0) {
          setSort('distance');
        }
        searchRepository?.setCurrentPage(1);
        searchRepository?.fetchStoresForPage();
      }}
    />
  }

  return (
    <Box>
      <Flex
        direction={["column", "column", "column", "row", "row"]}
        minH={["250px", "250px", "250px", "250px", "250px"]}
        height="100%"
        w="100%"
        py={4}
        pt={[4, 4, 4, "45px"]}
        position="relative"
        {...rest}>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="space-between"
          height="100%"
          pl={[0, 0, 0, 5, 0]}
          w={["100%", "100%", "90%", "40%", "50%"]}>
          <Flex direction="column" justifyContent="center">
            <Headline as="h1"
              fontSize={["3xl", "4xl", "5xl", "5xl", "7xl"]}
              fontWeight="bold"
              color={"brandRed.300"}
              wordBreak="break-word"
              lineHeight="none"
              maxW={["250px", "300px", "500px", "500px", "unset"]}>
              {categoryName
                ? categoryName
                : i18next.t("search:searchHeader.allCategories")}
            </Headline>

            <SearchHeaderText {...resultsTextStyle}>
              {searchRepository?.paginatedStores?.totalResults
                ? i18next.t("search:searchHeader.numberOfResultsFound", {
                  value: searchRepository?.paginatedStores?.totalResults,
                })
                : i18next.t("search:searchHeader.noResults")}
            </SearchHeaderText>

            <Box
              width={["100%", "100%", "100%", "100%", "100%"]}
              color="black"
              mt={5}
              mr={[0, 0, 0, 0, 0]}
              mb={[0,0,0, 0, 0]}>
              <StyledInput
                color="black"
                type="text"
                fontSize="lg"
                placeholder={i18next.t("search:searchInputsGroup.freeTextSearch")}
                variant="unstyled"
                mb={[0, 0, 4]}
                px={3}
                py={2}
                bg="white"
                value={searchInputValue}
                onChange={(e: React.FormEvent<HTMLInputElement>) => handlefreeTextSearch(e)}
              />
              {!isSmallMedium && getGoogleSearchBox()}

            </Box>
          </Flex>
          <Sort
            position="absolute"
            left={[0, "-55px", 0, 0, "525px"]}
            top={["345px", "340px", "400px", "255px", "265px"]}
            sort={sort}
            setSort={setSort}
          />
        </Stack>
        <Stack
          color="black"
          direction="column"
          spacing={2}
          pl={[0, 0, 0, 12, 12]}
          justifyContent="space-between"
          height="100%"
          width={["100%", "100%", "100%", "60%", "55%"]}>
          <CategoryMultiSelect
            index={0}
            selectedCategory={category}
            showSelectAllSubcategories={true}
            selectedSubCategories={subCategories}
            setSelectedCategory={setCategory}
            setSelectedSubCategories={setSubCategories}
            width={["300px", "400px", "400px", "500px", "unset"]}
            autoSelectSubCategories={true}
            hideOnSmall={true}
          />
          {isSmallMedium && getGoogleSearchBox()}
        </Stack>
      </Flex>
    </Box>
  );
};

export const SearchHeader = compose<Props, Props>(observer)(SearchHeaderBase);
