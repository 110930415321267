import { Text } from "@chakra-ui/react";
import { FormikProps, Form, Formik } from "formik";
import {
  ContentArea,
  FormattedEmbeddedVideo,
  InfoGrid,
  SocialInfo,
  StyledTextarea,
} from "app/shared";
import {
  SimpleGrid,
  Button as ChakraButton,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { StaticPageTitle } from "app/staticPages/StaticPageTitle";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import React, { useState } from "react";
import { compose } from "recompose";
import { observer } from "mobx-react";
import { useRootRepositoryContext } from "rootRepository";
import i18next from "i18next";
import { UserFormField } from "../app/users/forms/UserFormItems";
import { StyledInput, Button, ButtonLink,  CloseButton, Heading } from "app/shared";
import { PublicUserContactFormViewModel, PulbicUserContactFormSchema } from "../app/users/forms/UserFormSchema";
import { } from "../app/users/services";
import { TextLink } from "../app/shared/textLink";
import { sendPublicContactMail } from "../app/contact/services";
import { useMediaQueryContext } from "../styles/context";
import useScript from "../utils/useScript";

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}
const ContactForm: React.FC = () => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'kontakt', section: 'kontakt', pubdate: '', author: '', url: '/kontakt', object_type: 'kontakt' }}); } ");

  const [firstRun, setFirstRun] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;
  const { isMedium } = useMediaQueryContext();

  const initialState: PublicUserContactFormViewModel = {
    subject: "",
    text: "",
    email: user ? user.email : ""
  };

  const submitHandlerRequest = (
    values: PublicUserContactFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    if (firstRun) {
      sendPublicContactMail(values)
        .then(() => {
          setShowModal(true);
        })
        .catch((r) => {
          alert(r);
        })
        .finally(() => {
          handlerProps.setSubmitting(false)
          setFirstRun(false);
        });
    }
  };

  const renderActualForm = (props: FormikProps<PublicUserContactFormViewModel>) => {
    const { handleSubmit } = props;
    return (
      <ContentArea>
        <ChakraButton
          variant="unstyled"
          width="215px"
          marginBottom="25px">
          <Link to="/">
            zurück zur Startseite
        </Link>
        </ChakraButton>
        <StaticPageTitle mb={"96px"}>{i18next.t("users:contact.title")}</StaticPageTitle>
        <StaticPageTextBlock>
          {i18next.t("users:contact.line1")}
        </StaticPageTextBlock>
        <Form onSubmit={handleSubmit}>
          <SimpleGrid columns={!isMedium ? 2 : 1}>
            <Box>
              <UserFormField
                disabled={!firstRun && !user}
                fieldName="email"
                fieldType="email"
                Component={StyledInput}
                flexDirection={isMedium ? "column" : "row"}
              />
              <UserFormField
                disabled={!firstRun}
                fieldName="subject"
                fieldType="string"
                Component={StyledInput}
                flexDirection={isMedium ? "column" : "row"}
              />
              <UserFormField
                disabled={!firstRun}
                fieldName="text"
                fieldType="string"
                Component={StyledTextarea}
                flexDirection={isMedium ? "column" : "row"}
              />
            </Box>
          </SimpleGrid>
          <Flex py={3} mt={8} justifyContent="left">
            <Button
              width="200px"
              type="submit"
              isDisabled={!props.isValid || props.isSubmitting || !firstRun}>
              {i18next.t(`users:contact.btnSave`)}
            </Button>
          </Flex>
        </Form>
      </ContentArea>
    );
  };

  return (
    <>
      {showModal && <ContactRequestSavedMessage />}
      <Formik
        initialValues={initialState}
        validationSchema={PulbicUserContactFormSchema}
        onSubmit={submitHandlerRequest}>
        {(props) => renderActualForm(props)}
      </Formik>
    </>
  );
};

const ContactRequestSavedMessage: React.FC = () => {
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen && firstRun) {
    onOpen();
    setFirstRun(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("users:form.popup.publicContactTitle")}</Heading>
        </ModalHeader>
        
        <ModalBody mb={["40px"]}>
          <ButtonLink
            to={`/`}
            w="15%"
            mt="20px"
            minWidth="190px"
            color="white"
            borderRadius="0"
            bg="brandRed.300">
            {i18next.t(`users:contact.btnReturn`)}
          </ButtonLink>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const ContactPage = compose(withRouter, observer)(ContactForm);

/*
<CloseButton
          onClick={onClose}
          iconSize={"13px"}
          position="absolute"
          top={0}
          right={1}
          iconColor="brandRed.300"
        />

*/
