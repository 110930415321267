import { Stack, Text, Button } from "@chakra-ui/react";
import { ContentArea } from "app/shared";
import { ProfileInfo } from "app/users";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { EditUserForm } from "app/users/forms/EditUserForm";
import { User } from "app/users/models";
import i18next from "i18next";
import useScript from "../utils/useScript";

const EditPage: React.FC<{ user: User, onSave: (response: User) => void }> = ({ user, onSave }) => (
  <Stack direction="column"><Button
    variant="unstyled"
    width="215px"
    marginBottom="25px">
    <Link to="/user">
      zurück zur Übersicht
        </Link>
  </Button>
    <ProfileInfo editDisabled={false} user={user} />
    <EditUserForm user={user} onSave={onSave} />
  </Stack>
);

interface Params {
  id: string;
}

type Props = RouteComponentProps<Params>;

const EditUserPageBase: React.FC<Props> = ({ match, location, history }) => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'editUser', section: 'editUser', pubdate: '', author: '', url: '/editUser', object_type: 'editUser' }}); } ");

  const id = match.params.id;
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  const onSave = (response: User) => {
    userRepository.update(response);
    history.push('/user');
  };

  return (
    <ContentArea>
      {user ? (
        <EditPage user={user} onSave={onSave} />
      ) : (
          <Text>{i18next.t("shared:loginRequired")}</Text>
        )}
    </ContentArea>
  );
};

export const EditUserPage = compose<Props, {}>(
  withRouter,
  observer
)(EditUserPageBase);
