import { BoxProps, Flex, Grid, Image, Text, Box } from "@chakra-ui/react";
import { GalleryView } from "app/shared";
import i18next from "i18next";
import React, { useState } from "react";
import { customColors } from "styles";
import { useMediaQueryContext } from "styles/context";
import { Photo } from "app/shared/models";
import { generatePhotoAreas } from "./utils";
import environment from "configurations";
import { MAX_PHOTOS, MAX_SUB_PHOTOS } from "./constants";

interface Props extends BoxProps {
  photos: Photo[];
}

export const PhotoBox: React.FC<Props> = ({ photos, ...rest }) => {
  const [isGalleryView, setIsGalleryView] = useState(false);
  const [clickedPhotoIndex, setClickedPhotoIndex] = useState(0);

  const coverPhotoIndex = 0;
  let photosArea = generatePhotoAreas(photos, coverPhotoIndex);

 

  const handlePhotoClicked = (index: number) => {
    setClickedPhotoIndex(index);
    setIsGalleryView(true);
  };

  const { isMediumLarge } = useMediaQueryContext();

  return isGalleryView ? (
    <GalleryView
      items={photos.map((photo) => ({
        original: environment.site + photo.url,
        thumbnail: environment.site + photo.url,
      }))}
      open={isGalleryView}
      onClose={() => setIsGalleryView(false)}
      index={clickedPhotoIndex}
      thumbnailsTop={isMediumLarge}
    />
  ) : photos.length !== 0 ? (
    <Grid
      templateAreas={[
        `
        'cover'
        `,
        `
        'cover'
        `,
        `
        'cover'
        `,
        `
        'cover'
        `,
        `
        'cover cover cover cover cover'
        'photo_0 photo_1 photo_2 photo_3 photo_4'
        `,
      ]}
      templateColumns={["250px", "300px", "648px", "810px", "repeat(5, 100px)"]}
      templateRows={[
        "250px",
        "300px",
        "400px",
        "500px",
        "500px",
        "400px 100px",
      ]}
      columnGap={[0, 0, 6]}
      rowGap={[0, 0, 9]}>

      {isMediumLarge
        ? photos.map(
          (photo, index) =>
            photosArea[index] === "cover" && (
              <Box
                gridArea={photosArea[index]}
                key={`photo_${index}`}
                onClick={() => handlePhotoClicked(index)}
                _hover={{
                  cursor: "pointer",
                  outline: `4px solid ${customColors.brandRed[300]}`,
                }}>
                <Image
                  height="100%"
                  width="100%"
                  objectFit="contain"
                  src={environment.site + photos[index].url}
                  alt={photos[index].id}
                />
              </Box>
            )
        )
        : photos.map(
            (photo, index) =>
              photosArea[index] !== "break" && (
                <Box
                  gridArea={photosArea[index]}
                  key={`photo_${index}`}
                  onClick={() => handlePhotoClicked(index)}
                  _hover={{
                    cursor: "pointer",
                    outline: `4px solid ${customColors.brandRed[300]}`,
                  }}>
                  <Image
                    height="100%"
                    width="100%"
                    objectFit="contain"
                    src={environment.site + photos[index].url}
                    alt={photos[index].id}
                  />
                </Box>
              )
          
        )}
      {!isMediumLarge && photosArea.includes("break") && (
        <Box
          onClick={() => handlePhotoClicked(coverPhotoIndex)}
          _hover={{
            cursor: "pointer",
            outline: `4px solid ${customColors.brandRed[300]}`,
          }}>
          <Flex
            gridArea="photo_4"
            bg="brandRed.300"
            color="white"
            alignItems="center"
            justifyContent="center"
            height="100%"
            w="100%">
            <Text
              as="span"
              textAlign="center"
              fontSize="xs"
              fontWeight="bold"
              width="50%">
              {i18next.t("shared:photoBox.viewAll")}
              <Text fontSize="lg" lineHeight="none">
                2+
              </Text>
            </Text>
          </Flex>
        </Box>
      )}
    </Grid>
  ) : (
        <></>
      );
};

const getAlternatePhotoListArea = (
  photos: Photo[],
  coverPhotoIndex: number): string[] => {
  const photosArea: string[] = [];
  for (let i = 0, j = 0; i < photos.length; i++) {
    if (photos.length > MAX_PHOTOS - 1) {
      if (j < MAX_SUB_PHOTOS) {
        photosArea.push(`photo_${j}`);
      } else {
        photosArea.push(`break`);
      }
    } else {
      photosArea.push(`photo_${j}`);
    }
    j++;
  }

  return photosArea;
}
