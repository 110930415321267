import { Text } from "@chakra-ui/react";
import { FormikProps, Form, Formik } from "formik";
import {
  ContentArea,
  FormattedEmbeddedVideo,
  InfoGrid,
  SocialInfo,
} from "app/shared";
import {
  SimpleGrid,
  Button as ChakraButton,
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { StaticPageTitle } from "app/staticPages/StaticPageTitle";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import React, { useState } from "react";
import { compose } from "recompose";
import { observer } from "mobx-react";
import { useRootRepositoryContext } from "rootRepository";
import i18next from "i18next";
import { UserFormField } from "../app/users/forms/UserFormItems";
import { StyledInput, Button, CloseButton, Heading } from "app/shared";
import { ResetUserPasswordFormViewModel, RequestResetUserPasswordFormViewModel, ResetUserPasswordFormSchema, ResetUserPasswordPublicFormViewModel, ResetUserPasswordPublicFormSchema, RequestResetUserPasswordFormSchema } from "../app/users/forms/UserFormSchema";
import { resetPasswordPublicRequestUser, resetPasswordPublicUser, resetPasswordUser } from "../app/users/services";
import { TextLink } from "../app/shared/textLink";
import useScript from "../utils/useScript";

interface Params {
  id: string;
}
type Props = RouteComponentProps<Params>;

interface SubmitHandlerProps {
  setSubmitting: (isSubmitting: boolean) => void;
}

const initialClaimState: RequestResetUserPasswordFormViewModel = {
  email: ""
};
const initialResetPublicState: ResetUserPasswordPublicFormViewModel = {
  password: "",
  repeatPassword: ""
};
const initialResetState: ResetUserPasswordFormViewModel = {
  oldpassword: "",
  password: "",
  repeatPassword: ""
};

const PasswordResetForm: React.FC<Props> = ({ match }) => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'passwort-zuruecksetzen', section: 'passwort-zuruecksetzen', pubdate: '', author: '', url: '/passwort-zuruecksetzen', object_type: 'passwort-zuruecksetzen' }}); } ");

  const [firstRun, setFirstRun] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;
  const id = match.params.id;

  const submitHandlerResetUserPassword = (
    values: ResetUserPasswordFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    if (firstRun) {
      resetPasswordUser(values, user?.id != undefined ? user?.id : "")
        .then(() => {
          setShowModal(true);
        })
        .catch((r) => {
          alert(r);
        }).finally(() => {
          handlerProps.setSubmitting(false)
          setFirstRun(false);
          console.log(firstRun);
        });
    }
  };
  const submitHandlerResetUserPasswordPublic = (
    values: ResetUserPasswordPublicFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    if (firstRun) {
      resetPasswordPublicUser(values, id)
        .then(() => {
          setShowModal(true);
        })
        .catch((r) => {
          alert(r);
        })
        .finally(() => {
          handlerProps.setSubmitting(false)
          setFirstRun(false);
        });
    }
  };
  const submitHandlerRequestResetUserPassword = (
    values: RequestResetUserPasswordFormViewModel,
    handlerProps: SubmitHandlerProps
  ) => {
    if (firstRun) {
      resetPasswordPublicRequestUser(values)
        .then((r) => {
          setShowModal(true);
        })
        .catch((r) => {
          alert(r);
        }).finally(() => {
          handlerProps.setSubmitting(false)
          setFirstRun(false);
        });
    }
  };

  // User LoggedIn
  if (user) {
    const renderActualForm = (props: FormikProps<ResetUserPasswordFormViewModel>) => {
      const { handleSubmit } = props;
      return (
        <ContentArea>
          <ChakraButton
            width="215px"
            marginBottom="25px">
            <Link to="/user">
              zurück zum Profil
        </Link>
          </ChakraButton>
          <StaticPageTitle mb={"96px"}>{i18next.t("users:resetPassword.title")}</StaticPageTitle>
          <StaticPageTextBlock>
            {i18next.t("users:resetPassword.line1")}
          </StaticPageTextBlock>
          <Form onSubmit={handleSubmit}>
            <SimpleGrid columns={2}>
              <Box>
                <UserFormField
                  disabled={!firstRun}
                  fieldName="oldpassword"
                  fieldType="password"
                  hideLabel={true}
                  Component={StyledInput}
                />
                <UserFormField
                  disabled={!firstRun}
                  fieldName="password"
                  fieldType="password"
                  hideLabel={true}
                  Component={StyledInput}
                />
                <UserFormField
                  disabled={!firstRun}
                  fieldName="repeatPassword"
                  fieldType="password"
                  hideLabel={true}
                  Component={StyledInput}
                />
              </Box>
            </SimpleGrid>
            <Flex py={3} mt={8} justifyContent="left">
              <Button
                width="200px"
                type="submit"
                isDisabled={!props.isValid || props.isSubmitting || !firstRun}>
                {i18next.t(`users:form.submit.labelSave`)}
              </Button>
            </Flex>
          </Form>
        </ContentArea>
      );
    };

    return (
      <>
        {showModal && <PasswortSavedMessage />}
        <Formik
          initialValues={initialResetState}
          validationSchema={ResetUserPasswordFormSchema}
          onSubmit={submitHandlerResetUserPassword}>
          {(props) => renderActualForm(props)}
        </Formik>
      </>
    );
  }
  // User not loggedIn and confirmed new password claim
  else if (id != undefined && id != "") {
    const renderActualForm = (props: FormikProps<ResetUserPasswordPublicFormViewModel>) => {
      const { handleSubmit } = props;
      return (
        <ContentArea>
          <StaticPageTitle mb={"96px"}>{i18next.t("users:resetPassword.title")}</StaticPageTitle>
          <StaticPageTextBlock>
            {i18next.t("users:resetPassword.line2")}
          </StaticPageTextBlock>
          <Form onSubmit={handleSubmit}>
            <SimpleGrid columns={2}>
              <Box>
                <UserFormField
                  disabled={!firstRun}
                  fieldName="password"
                  fieldType="password"
                  hideLabel={true}
                  Component={StyledInput}
                />
                <UserFormField
                  disabled={!firstRun}
                  fieldName="repeatPassword"
                  fieldType="password"
                  hideLabel={true}
                  Component={StyledInput}
                />
              </Box>
            </SimpleGrid>
            <Flex py={3} mt={8} justifyContent="left">
              <Button
                width="200px"
                type="submit"
                isDisabled={!props.isValid || props.isSubmitting || !firstRun}>
                {i18next.t(`users:form.submit.labelSave`)}
              </Button>
            </Flex>
          </Form>
        </ContentArea>
      );
    };

    return (
      <>
        {showModal && <PasswortSavedMessage />}
        <Formik
          initialValues={initialResetPublicState}
          validationSchema={ResetUserPasswordPublicFormSchema}
          onSubmit={submitHandlerResetUserPasswordPublic}>
          {(props) => renderActualForm(props)}
        </Formik>
      </>
    );
  }
  // User not loggedIn and claims a new password
  else {
    const renderActualForm = (props: FormikProps<RequestResetUserPasswordFormViewModel>) => {
      const { handleSubmit } = props;
      return (
        <ContentArea>
          <StaticPageTitle mb={"96px"}>{i18next.t("users:resetPassword.title")}</StaticPageTitle>
          <StaticPageTextBlock>
            {i18next.t("users:resetPassword.line2")}
          </StaticPageTextBlock>
          <Form onSubmit={handleSubmit}>
            <SimpleGrid columns={2}>
              <Box>               
                <UserFormField
                  disabled={!firstRun}
                  fieldName="email"
                  fieldType="text"
                  hideLabel={true}
                  Component={StyledInput}
                />
              </Box>
            </SimpleGrid>
            <Flex py={3} mt={8} justifyContent="left">
              <Button
                width="200px"
                type="submit"
                isDisabled={!props.isValid || props.isSubmitting || !firstRun}>
                {i18next.t(`users:form.submit.labelRequest`)}
              </Button>
            </Flex>
          </Form>
        </ContentArea>
      );
    };

    return (
      <>
        {showModal && <PasswortRequestCreatedMessage />}
        <Formik
          initialValues={initialClaimState}
          validationSchema={RequestResetUserPasswordFormSchema}
          onSubmit={submitHandlerRequestResetUserPassword}>
          {(props) => renderActualForm(props)}
        </Formik>
      </>
    );
  }
};

const PasswortRequestCreatedMessage: React.FC = () => {
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen && firstRun) {
    onOpen();
    setFirstRun(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("users:form.popup.passwortRequestTitle")}</Heading>
        </ModalHeader>
        <CloseButton
          onClick={onClose}
          iconSize={"13px"}
          position="absolute"
          top={0}
          right={1}
          iconColor="brandRed.300"
        />
        <ModalBody mb={["80px"]}>
          <Text fontSize="lg">{i18next.t("users:form.popup.passwortRequestText")}</Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const PasswortSavedMessage: React.FC = () => {
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isOpen && firstRun) {
    onOpen();
    setFirstRun(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="white" maxW={["800px"]}>
        <ModalHeader>
          <Heading>{i18next.t("users:form.popup.passwordSavedTitle")}</Heading>
        </ModalHeader>
        <CloseButton
          onClick={onClose}
          iconSize={"13px"}
          position="absolute"
          top={0}
          right={1}
          iconColor="brandRed.300"
        />
        <ModalBody mb={["80px"]}>
          <Text fontSize="lg">{i18next.t("users:form.popup.passwordSavedText")}</Text>
          <TextLink
            to="/"
            mt="2"
            fontWeight="bold"
            color="brandRed.300"
            fontSize={["xs", "sm"]}>
            {i18next.t("users:form.popup.homeLink")}
          </TextLink>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const PasswordReset = compose<Props, {}>(withRouter, observer)(PasswordResetForm);
