import { CategoryRepository } from "app/categories/repositories/CategoryRepository";
import { UserRepository } from "app/users/repositories/UserRepository";
import React, { useContext } from "react";
import { MapsRepository } from "./MapsRepository";

import { TestimonialRepository } from "app/testimonials/repositories/TestimonialRepository";
import { CampaignRepository } from "app/campaign/repositories/CampaignRepository";

interface RepositoryContextProps {
  categoryRepository: CategoryRepository;
  mapsRepository: MapsRepository;
  userRepository: UserRepository;
  testimonialRepository: TestimonialRepository;
  campaignRepository: CampaignRepository;
}

export const RootRepositoryContext = React.createContext<
  RepositoryContextProps
>({} as RepositoryContextProps);

export const useRootRepositoryContext = () => useContext(RootRepositoryContext);
