import { Grid, Stack, Text } from "@chakra-ui/react";
import { ButtonLink, Heading, IconWithText } from "app/shared";
import i18next from "i18next";
import React from "react";
import { ProfileAvatar } from "./ProfileAvatar";
import { uploadUserPicture } from "../services";
import { User } from "../models";
import { useRootRepositoryContext } from "../../../rootRepository";
import { useMediaQueryContext } from "styles/context";
import { MailToIcon, PhoneIcon } from "styles/icons";

interface Props {
  user: User;
  editDisabled: boolean;
}

export const ProfileInfo: React.FC<Props> = ({ user, editDisabled }) => {
  const pictureUploadHandler = (file: File) => {
    uploadUserPicture(file).then((r) => {
      user.profilePictureUrl = r;
    });
  };

  const { isSmall, isSmallMedium, isMedium, isMediumLarge, isLarge, isVeryLarge } = useMediaQueryContext();

  return (
    <Grid templateColumns={isSmall ? "100%" : "280px auto"} pb={10}>
      <Stack direction="column" spacing={8} >
        <ProfileAvatar
          pictureUrl={user.profilePictureUrl}
          onPictureUpload={pictureUploadHandler}
          editDisabled={!editDisabled}
          mb={5}
        />
        {!editDisabled && (
          <Stack direction="row" spacing={2} >
            <ButtonLink
              to={`/editUser`}
              color="white"
              bg="brandRed.300"
              borderRadius={0}
              _hover={{ bg: "brandRed.100" }}
              _focus={{ textDecoration: "none" }}>
              {i18next.t("users:profile.profileInfo.edit")}
            </ButtonLink>
            <ButtonLink
              to={`/passwort-zuruecksetzen/`}
              color="white"
              bg="brandRed.300"
              borderRadius={0}
              _hover={{ bg: "brandRed.100" }}
              _focus={{ textDecoration: "none" }}>
              {i18next.t("users:profile.profileInfo.password")}
            </ButtonLink>
          </Stack>
        )}
      </Stack>
      <Stack spacing={2} gridRow={isSmall ? 1 : undefined} mb={isSmall ? "20px" : undefined }>
        <Text fontSize="lg" color="darkGrey">
          {i18next.t("users:profile.profileInfo.profileSettings")}
        </Text>
        {user.firstName && user.lastName && (
          <Heading lineHeight="none">{`${user.firstName} ${user.lastName}`}</Heading>
        )}
        <Stack direction="column" spacing={1}>
          {user.phoneNumber && (
            <IconWithText
              icon={<PhoneIcon color="darkGrey" boxSize="15px" />}
              text={user.phoneNumber}
              textColor="darkGrey"
            />
          )}
          {user.email && (
            <IconWithText
              icon={<MailToIcon color="darkGrey" boxSize="18px" />}
              text={user.email}
              textColor="darkGrey"
            />
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};
