import { ChakraProvider } from '@chakra-ui/react';
import { Footer, FooterArea, MainLayout, Navbar, NavbarArea } from 'app/shared';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { theme } from 'styles';
import { MediaQueryProvider } from 'styles/context';
import { About } from './About';
import CreateStorePage from './CreateStorePage';
import { CreateUserAndStorePage } from './CreateUserAndStorePage';
import CreateUserPage from './CreateUserPage';
import CreateProductPage from './CreateProductPage';
import EditStorePage from './EditStorePage';
import { EditUserPage } from './EditUserPage';
import EditProductPage from './EditProductPage';
import HomePage from './HomePage';
import NotFoundPage from './NotFoundPage';
import ImpressumPage from './ImpressumPage';
import PrivacyPage from './PrivacyPage';
import { Profile } from './Profile';
import { Search } from './Search';
import { StoreDetails } from './StoreDetails';
import { ForStores } from './ForStores';
import { ForUsers } from './ForUsers';
import { ContactPage } from './ContactPage';
import { Doi } from './DOI';
import { PasswordReset } from './PasswordReset';
import { News } from './News';
import { NewsDetails } from './NewsDetails';
import { Presse } from './Presse';
import { PresseDetails } from './PresseDetails';
import { Partner } from './Partner';
import { PartnerDetails } from './PartnerDetails';
import { TestimonialsPage } from './TestimonialsPage';

import ScrollToTop from 'app/_dbh/scrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateStoreWindowPage from './CreateStoreWindowPage';
import EditStoreWindowPage from './EditStoreWindowPage';
import { StoreWindowDetails } from './StoreWindowDetails';
import { ProductDetails } from './ProductDetails';

const reload = () => window.location.reload();

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <MediaQueryProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MainLayout>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              limit={1}
              rtl={false}
            />
            <NavbarArea>
              <Navbar />
            </NavbarArea>
            <Switch>
              {/* Public routes */}
              <Route exact path="/" component={HomePage} />
              <Route exact path="/about" component={About} />
              <Route exact path="/fur-geschafte" component={ForStores} />
              <Route exact path="/fur-nutzer" component={ForUsers} />
              <Route path="/search" component={Search} />
              <Route exact path="/store/:id" component={StoreDetails} />
              <Route
                exact
                path="/storeWindow/:id"
                component={StoreWindowDetails}
              />
              <Route
                exact
                path="/product/:storeWindowId/:productId"
                component={ProductDetails}
              />
              <Route exact path="/doi/:id" component={Doi} />
              <Route
                exact
                path="/passwort-zuruecksetzen/:id"
                component={PasswordReset}
              />
              <Route
                exact
                path="/passwort-zuruecksetzen"
                component={PasswordReset}
              />
              <Route exact path="/newUser" component={CreateUserPage} />
              <Route exact path="/kontakt" component={ContactPage} />
              <Route exact path="/news" component={News} />
              <Route exact path="/newsDetail/:id" component={NewsDetails} />
              <Route exact path="/presse" component={Presse} />
              <Route exact path="/presseDetail/:id" component={PresseDetails} />
              <Route exact path="/partner" component={Partner} />
              <Route
                exact
                path="/partnerDetail/:id"
                component={PartnerDetails}
              />
              <Route
                exact
                path="/lieblingslaeden"
                component={TestimonialsPage}
              />

              <Route
                exact
                path="/newUserAndStore"
                component={CreateUserAndStorePage}
              />

              {/*Footer-Pages*/}
              <Route exact path="/Impressum" component={ImpressumPage} />
              <Route
                exact
                path="/Datenschutzerklaerung"
                component={PrivacyPage}
              />

              {/* Routes requiring login */}
              <Route exact path="/editStore/:id" component={EditStorePage} />
              <Route exact path="/newStore" component={CreateStorePage} />
              <Route
                exact
                path="/editProduct/:id"
                component={EditProductPage}
              />
              <Route exact path="/newProduct" component={CreateProductPage} />
              <Route
                exact
                path="/editStoreWindow/:id"
                component={EditStoreWindowPage}
              />
              <Route
                exact
                path="/newStoreWindow/:storeId"
                component={CreateStoreWindowPage}
              />
              <Route path="/user" component={Profile} />
              <Route path="/editUser" component={EditUserPage} />

              <Route path="/crashtest-c4f17a4b.html" onEnter={reload} />

              {/* Default redirect to the 404 page */}
              <Route path="/404" component={NotFoundPage} />
              <Redirect from="*" to="/404" />
            </Switch>
            <FooterArea>
              <Footer />
            </FooterArea>
          </MainLayout>
        </BrowserRouter>
      </MediaQueryProvider>
    </ChakraProvider>
  );
};

export default App;
