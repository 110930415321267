import { Box, Text } from "@chakra-ui/react";
import { Heading } from "app/shared";
import i18next from "i18next";
import React from "react";

interface Props {
  title: string;
  subtitle: string;
}

export const CampaignHeader: React.FC<Props> = ({ title, subtitle }) => (
  <Box
    pb={10}
    maxWidth={["270px", "maxWidth"]}
    textAlign={["center", "center", "center", "unset"]}>
    <Heading>{title}</Heading>
    <Text fontWeight="bold" fontSize={["md", "md", "lg"]} color="lightBlack">
      <span dangerouslySetInnerHTML={{ __html: subtitle }} />
    </Text>
  </Box>
);
