import { BoxProps, Flex, Grid, Text } from "@chakra-ui/react";
import { SiteImageButton } from "app/shared";
import i18next from "i18next";
import React from "react";
import { LeftColumn, RightColumn } from "./constants";
import { GridText } from "./GridText";

export const InfoGrid: React.FC<BoxProps> = (props) => (
  <Flex direction="column" alignItems="center" {...props}>
    <SiteImageButton logoColor="grey" pb={[4, 6, 9]} />
    <Text
      fontWeight="bold"
      fontSize={["md", "lg", "xl"]}
      color="darkGrey"
      textAlign={["left", "left", "center"]}
      maxW="75%"
      pb={6}>
      {i18next.t("shared:infoGrid.header")}
    </Text>
    <Flex
      direction={["column", "column", "row"]}
      justifyContent="center"
      alignItems="center">
      <Grid
        templateColumns="repeat(1, 1fr)"
        gap={6}
        w={["75%", "75%", "35%"]}
        mr={[0, 0, "100px"]}
        pb={[6, 6, 0]}>
        {LeftColumn.map((infoText, index) => (
          <GridText key={`left-${index}`} {...infoText} />
        ))}
      </Grid>
      <Grid templateColumns="repeat(1, 1fr)" gap={6} w={["75%", "75%", "35%"]}>
        {RightColumn.map((infoText, index) => (
          <GridText key={`right-${index}`} {...infoText} />
        ))}
      </Grid>
    </Flex>
  </Flex>
);
    //
