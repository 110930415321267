import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { useMediaQueryContext } from "styles/context";
import { SiteImageButton } from "..";
import { BurgerDrawer } from "./burgerDrawer";
import { NavbarActions } from "./navbarActions";
import { ProfileMenu } from "./profileMenu";
import { useRootRepositoryContext } from "../../../rootRepository";

export const Navbar: React.FC = () => {
  const location = useLocation();
  const { isMedium } = useMediaQueryContext();
  const isHomepage = location.pathname === "/";
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <Flex
      width={"100%"}
      maxWidth={["maxWidth"]}
      mx="auto"
      justifyContent="space-between">
      <Stack alignItems="center" direction="row">
        <BurgerDrawer />

        <SiteImageButton width={["150px", "267px"]} marginLeft={[5, 5, 20]} />
      </Stack>
      {!isMedium && <NavbarActions pr={[0, 0, 0, 3, "105px"]} />}
      {isMedium && user &&
        <Stack alignItems="center" direction="row">
          <ProfileMenu user={user} />
        </Stack>
      }
    </Flex>
  );
};
