import * as COMMENTS_TRANSLATIONS from "app/comments/locales";
import * as SEARCH_TRANSLATIONS from "app/search/locales";
import * as SHARED_TRANSLATIONS from "app/shared/locales";
import * as STORES_TRANSLATIONS from "app/stores/locales";
import * as STOREWINDOWS_TRANSLATIONS from "app/storeWindows/locales";
import * as PRODUCTS_TRANSLATIONS from "app/products/locales";
import * as USERS_TRANSLATIONS from "app/users/locales";

import * as IMPRESSUM_TRANSLATIONS from "app/staticPages/impressum/locales";
import * as PRIVACY_TRANSLATIONS from "app/staticPages/privacy/locales";
import * as ABOUT_TRANSLATIONS from "app/staticPages/about/locales";
import * as FOR_USERS_TRANSLATIONS from "app/staticPages/forUsers/locales";
import * as FOR_STORES_TRANSLATIONS from "app/staticPages/forStores/locales";

import * as NEWS_TRANSLATIONS from "app/news/locales";
import * as PRESSE_TRANSLATIONS from "app/presse/locales";
import * as PARTNER_TRANSLATIONS from "app/partner/locales";
import * as TESTIMONIALS_TRANSLATIONS from "app/testimonials/locales";


import { default as i18n, default as i18next } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: "de",
    debug: false,
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  });

i18next.addResourceBundle(
  "de",
  SHARED_TRANSLATIONS.SHARED_NAMESPACE,
  SHARED_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  STORES_TRANSLATIONS.STORES_NAMESPACE,
  STORES_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  STOREWINDOWS_TRANSLATIONS.STOREWINDOW_NAMESPACE,
  STOREWINDOWS_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  PRODUCTS_TRANSLATIONS.PRODUCTS_NAMESPACE,
  PRODUCTS_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  COMMENTS_TRANSLATIONS.COMMENTS_NAMESPACE,
  COMMENTS_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  SEARCH_TRANSLATIONS.SEARCH_NAMESPACE,
  SEARCH_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  USERS_TRANSLATIONS.USERS_NAMESPACE,
  USERS_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  IMPRESSUM_TRANSLATIONS.IMPRESSUM_NAMESPACE,
  IMPRESSUM_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  PRIVACY_TRANSLATIONS.PRIVACY_NAMESPACE,
  PRIVACY_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  ABOUT_TRANSLATIONS.ABOUT_NAMESPACE,
  ABOUT_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  FOR_STORES_TRANSLATIONS.FOR_STORES_NAMESPACE,
  FOR_STORES_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  FOR_USERS_TRANSLATIONS.FOR_USERS_NAMESPACE,
  FOR_USERS_TRANSLATIONS.DE
);

i18next.addResourceBundle(
  "de",
  NEWS_TRANSLATIONS.NEWS_NAMESPACE,
  NEWS_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  PRESSE_TRANSLATIONS.PRESSE_NAMESPACE,
  PRESSE_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  PARTNER_TRANSLATIONS.PARTNER_NAMESPACE,
  PARTNER_TRANSLATIONS.DE
);
i18next.addResourceBundle(
  "de",
  TESTIMONIALS_TRANSLATIONS.TESTIMONIALS_NAMESPACE,
  TESTIMONIALS_TRANSLATIONS.DE
);

export default i18n;
