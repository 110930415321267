import React from "react";
import { Image, Box, Flex, FormControl, Input, Text } from "@chakra-ui/react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Field } from "formik";
import { Button } from "app/shared";
import i18next from "i18next";
import { ImageUploaderProps } from "./ImageUploaderProps";
import { useMediaQueryContext } from "styles/context";
import {
  FieldInternalProps,
  DEFAULT_LABEL_WIDTH,
} from "app/shared/forms/GenericInput";
import { MixedPhoto } from "app/shared/models";
import { isPhoto } from "app/shared/utils/PhotoTypeChecker";
import environment from "../../../../configurations";

/*
 * Provides a specific variant of the image upload component better suited for the business creation form
 */
interface ImageThumbnailProps {
  img: MixedPhoto;
  index: number;
  onDelete: (index: number) => void;
}

const UploadedImageThumbnail: React.FC<ImageThumbnailProps> = (props) => {
  const { img, index, onDelete } = props;
  const url = isPhoto(img)
    ? environment.site + img.url
    : URL.createObjectURL(img);
  const key = isPhoto(img) ? img.id : img.name;
  return (
    <Image height="80px" src={url} key={key} onClick={() => onDelete(index)} />
  );
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: 2,
  height: "84px",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  display: "flex",
  padding: 2,
  overflow: "auto",
});

export const EditFormImageUploader: React.FC<ImageUploaderProps> = (props) => {
  const { fieldName, parentForm } = props;
  const photos = parentForm.values.photos as MixedPhoto[];
  let hiddenInput: HTMLInputElement | undefined = undefined;

  const onPictureUpload = (e: any) => {
    const newPhoto = e.currentTarget.files[0] as File;
    const pictureAlreadyUploaded = photos.find(
      (it: MixedPhoto) => !isPhoto(it) && it.name === newPhoto.name
    );
    //if (!pictureAlreadyUploaded) {
    const result = photos.concat([newPhoto]);
    parentForm.setFieldTouched(fieldName, true);
    parentForm.setFieldValue(fieldName, result);

    if (result.length == 1) {
      if (result[0] instanceof File) {
        parentForm.setFieldValue("mainPhoto", result[0].name);
      } else {
        parentForm.setFieldValue("mainPhoto", result[0].url);
      }
    }
    //} else {
    //  console.warn(
    //    `A picture named: ${newPhoto.name} already exists in the collection`
    //  );
    //}
  };

  const onPictureDelete = (index: number) => {
    const updatedPhotos = photos.concat([]); // Work over a new array
    updatedPhotos.splice(index, 1);

    parentForm.setFieldTouched(fieldName, true);
    parentForm.setFieldValue(fieldName, updatedPhotos);

    if (updatedPhotos.length == 1) {
      if (updatedPhotos[0] instanceof File) {
        parentForm.setFieldValue("mainPhoto", updatedPhotos[0].name);
      } else {
        parentForm.setFieldValue("mainPhoto", updatedPhotos[0].url);
      }
    }
  };

  const onPictureReorder = (fromIndex: number, toIndex: number) => {
    const updatedPhotos = photos.concat([]); // Work over a new array
    const movedItem = updatedPhotos.splice(fromIndex, 1);
    updatedPhotos.splice(toIndex, 0, movedItem[0]);
    parentForm.setFieldValue(fieldName, updatedPhotos);

    parentForm.setFieldTouched(fieldName, true);
    if (toIndex == 0) {
      if (updatedPhotos[0] instanceof File) {
        parentForm.setFieldValue("mainPhoto", updatedPhotos[0].name);
      } else {
        parentForm.setFieldValue("mainPhoto", updatedPhotos[0].url);
      }
    }
  };

  const renderUploadedPhotos = () =>
    photos.map((img, idx) => {
      if (idx !== -1) {
        const key = isPhoto(img) ? img.id : img.name;
        return (
          <Draggable key={key} draggableId={key} index={idx}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}>
                <UploadedImageThumbnail
                  img={img}
                  index={idx}
                  onDelete={() => {
                    if (
                      window.confirm(
                        "Soll das Bild wirklich gelöscht werden?"
                      ) == true
                    ) {
                      onPictureDelete(idx);
                    }
                  }}
                  key={key}
                />
              </div>
            )}
          </Draggable>
        );
      } else {
        return <></>;
      }
    });

  const {
    isSmall,
    isSmallMedium,
    isMedium,
    isMediumLarge,
    isLarge,
    isVeryLarge,
  } = useMediaQueryContext();
  return (
    <Box mt={3}>
      <Flex
        direction={isSmallMedium ? "column" : "row"}
        justifyContent="flex-start">
        <Text width={DEFAULT_LABEL_WIDTH}>
          {i18next.t(`stores:form.fields.${fieldName}.label`)}
        </Text>
        <Box width="100px">
          <Field className="block" name={fieldName} type="file">
            {(renderProps: FieldInternalProps) => (
              <FormControl
                isInvalid={
                  renderProps.form.errors[fieldName] &&
                  renderProps.form.touched[fieldName]
                }>
                <Button
                  width="80px"
                  height="80px"
                  onClick={(e) => (
                    e.preventDefault(), hiddenInput && hiddenInput.click()
                  )}>
                  +
                </Button>
                <Input
                  accept="image/png, image/jpeg, image/png"
                  hidden
                  type="file"
                  id={fieldName}
                  onChange={onPictureUpload}
                  ref={(el: HTMLInputElement) => (hiddenInput = el)}
                />
              </FormControl>
            )}
          </Field>
        </Box>
        <Flex flex="1">
          <DragDropContext
            onDragEnd={(opResult: DropResult) => {
              // Since index is a number we need to compare with undefined manually, otherwise the 0 index will fail
              if (
                opResult.destination &&
                opResult.destination.index !== undefined
              ) {
                onPictureReorder(
                  opResult.source.index,
                  opResult.destination.index
                );
              }
            }}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle()}
                  {...provided.droppableProps}>
                  {renderUploadedPhotos()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      </Flex>
      <Box ml={isSmallMedium ? "0" : DEFAULT_LABEL_WIDTH}>
        <Text>{i18next.t(`stores:form.fields.${fieldName}.helpText`)}</Text>
        {parentForm?.errors[fieldName] && parentForm.touched[fieldName] ? (
          <Box color="brandRed.300">{parentForm.errors[fieldName]}</Box>
        ) : null}
      </Box>
    </Box>
  );
};
