import { Flex, Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { useSearchRepositoryContext } from "../../search/repositories";

interface Props {
  name: string;
  id: string;
  selectedSubCategories: string[];
  selected: boolean;
  onSubCategorySelected: (subCategories: string[]) => void;
}

export const SubCategoryBox: React.FC<Props> = ({
  name,
  id,
  selected,
  selectedSubCategories,
  onSubCategorySelected,
}) => {
  let { searchRepository } = useSearchRepositoryContext();

  const handleOnSubCategorySelected = () => {
    let subCategrories: string[];
    if (selected) {
      subCategrories = selectedSubCategories.filter((subCategoryId) => subCategoryId !== id);     
    }
    else {
      subCategrories = [...selectedSubCategories, id];
    }
    onSubCategorySelected(subCategrories);
    searchRepository?.setSubCategories(subCategrories);
    searchRepository?.fetchStoresForPage();
  };


  return (
    <Box
      _hover={{ cursor: "pointer" }}
      bg={selected ? "brandRed.300" : "lightestGrey"}
      backgroundImage={selected ? "url('/images/ic_x.svg')" : "url('/images/ic_off.svg')"}
      backgroundPosition={"right 12px center"}
      backgroundRepeat="no-repeat"
      color={selected ? "white" : "brandRed.200"}
      border={selected ? undefined : "1px solid"}
      borderColor="lightestBlack"
      onClick={handleOnSubCategorySelected}
      height="27px"
      p="6px 35px 5px 20px"
      m="0 5px 5px 0"
      width={["100%", "100%", "100%", "auto", "auto"]}
      maxWidth={["135px", "175px", "175px", "none", "none"]}
      display="block"
      //width="118px"
      //overflow="hidden"
      boxShadow="lg">
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Text fontSize="xs" fontWeight="bold" isTruncated>
          {name} 
        </Text>
      </Flex>
    </Box>
  );
};
