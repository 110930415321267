import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import {
  Flex,
  Box,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td, Circle } from '@chakra-ui/react';

import { ButtonLink, Popup, Button, Heading } from 'app/shared';
import { getOwnedProducts, deleteProduct } from 'app/products/services';
import { ProductForTable } from 'app/products/models';
import { SearchForUserParam } from 'app/search/models';
import { useRootRepositoryContext } from '../../../rootRepository';
import { DotsIcon, ImageIcon } from 'styles/icons';
import environment from 'configurations';

export const OwnedProductsGrid: React.FC<SearchForUserParam> = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState<ProductForTable[]>([]);
  const [visibleProducts, setVisibleProducts] = useState<ProductForTable[]>([]);

  useEffect(() => {
    getOwnedProducts(userId).then((response) => {
      setAllProducts(response);
      setVisibleProducts(response);
      setLoading(false);
    });
  }, [userId]);

  const onFilterChanged = (newValue: string) => {
    if (newValue == '') {
      setVisibleProducts(allProducts);
    } else {
      setVisibleProducts(
        allProducts.filter(function (value, index, arr) {
          return value.stock == newValue;
        })
      );
    }
  };

  const onPopupDeleteClose = (itemId: string) => {
    setAllProducts(
      allProducts.filter(function (value, index, arr) {
        return value.id != itemId;
      })
    );
    setVisibleProducts(
      visibleProducts.filter(function (value, index, arr) {
        return value.id != itemId;
      })
    );
  };

  return (
    <Flex direction="column" my={9}>
      <Flex direction="row" alignItems="center" mb={5}>
        {/* PBI 10267: Verfügbarkeit wird vorerst entfernt */}
        {/* <Text marginRight="10px">
          {i18next.t("users:profile.ownedProductsGrid.filter")}
        </Text>    
        <Box width="300px">
          <Select
            onChange={(options) => onFilterChanged(options.value)}
            options={[
              {
                value: "",
                label: i18next.t(
                  "users:profile.ownedProductsGrid.filterOptions.all"
                ),
              },
              {
                value: AVAILABLE,
                label: i18next.t(
                  "users:profile.ownedProductsGrid.filterOptions.available"
                ),
              },
              {
                value: SOLD,
                label: i18next.t(
                  "users:profile.ownedProductsGrid.filterOptions.sold"
                ),
              },
            ]}
            defaultValue={{
              value: "",
              label: i18next.t(
                "users:profile.ownedProductsGrid.filterOptions.all"
              ),
            }}
          />
        </Box> */}

        <ButtonLink
          to={`/newProduct`}
          color="white"
          bg="brandRed.300"
          borderRadius={0}
          _hover={{ bg: 'brandRed.100' }}
          _focus={{ textDecoration: 'none' }}
          marginLeft="auto">
          {i18next.t('users:profile.ownedProductsGrid.create')}
        </ButtonLink>
      </Flex>

      <Table width="100%">
        <Thead alignContent="start">
          <Tr textAlign="start">
            <Th width="130px" />
            <Th>{i18next.t('users:profile.ownedProductsGrid.table.name')}</Th>
            <Th>{i18next.t('users:profile.ownedProductsGrid.table.price')}</Th>
            {/* <Th>{i18next.t("users:profile.ownedProductsGrid.table.stock")}</Th> */}
            <Th width="50px" />
          </Tr>
        </Thead>

        <Tbody>
          {visibleProducts.map((item) => {
            return (
              <Tr key={item.id}>
                <Td>
                  {item.photo ? (
                    <Image
                      objectFit="cover"
                      borderRadius="50%"
                      boxSize="80px"
                      src={environment.site + item.photo.url}
                      boxShadow="sm"
                    />
                  ) : (
                    <Circle boxSize="80px" bg="lightGrey" boxShadow="sm">
                      <ImageIcon boxSize="30px" />
                    </Circle>
                  )}
                </Td>
                <Td>{item.name}</Td>
                <Td>
                  {item.price} €
                </Td>
                {/* <Td>{i18next.t(`products:stockType.${item.stock}`)}</Td> */}
                <Td>
                  <Popup
                    trigger={(showPopup) => (
                      <Menu>
                        <MenuButton>
                          <DotsIcon boxSize="24px" />
                        </MenuButton>

                        <MenuList bg="brandRed.100" borderRadius="0" py="0">
                          <Link to={`/editProduct/${item.id}`}>
                            <MenuItem
                              bg="brandRed.300"
                              color="white"
                              _hover={{ bg: 'brandRed.100' }}
                              _focus={{ textDecoration: 'none' }}>
                              {i18next.t(
                                'users:profile.ownedProductsGrid.edit'
                              )}
                            </MenuItem>
                          </Link>
                          <MenuItem
                            bg="brandRed.300"
                            color="white"
                            _hover={{ bg: 'brandRed.100' }}
                            _focus={{ textDecoration: 'none' }}
                            onClick={showPopup}>
                            {i18next.t(
                              'users:profile.ownedProductsGrid.delete'
                            )}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                    content={(onClose) => (
                      <DeleteProductConfirmDialog
                        closePopup={() => {
                          onClose();
                          onPopupDeleteClose(item.id);
                        }}
                        productId={item.id}
                      />
                    )}
                    contentMaxWidth={['800px']}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Flex>
  );
};

interface DeleteProductConfirmationDialogProps {
  productId: string;
  closePopup: () => void;
}

const DeleteProductConfirmDialog: React.FC<DeleteProductConfirmationDialogProps> = ({
  productId,
  closePopup,
}) => {
  const { userRepository } = useRootRepositoryContext();
  const user = userRepository.user;

  return (
    <Box mb={1}>
      <Heading>
        {i18next.t('users:profile.ownedProductsGrid.deletePopup.title')}
      </Heading>
      <Text fontSize="lg" color="darkGrey">
        {i18next.t('users:profile.ownedProductsGrid.deletePopup.message')}
      </Text>
      <Button
        w="200px"
        mt={1}
        color="white"
        bg="brandRed.300"
        borderRadius={0}
        _hover={{ bg: 'brandRed.100' }}
        _focus={{ textDecoration: 'none' }}
        onClick={() => {
          deleteProduct(productId).then(() => {
            if (user?.ownedProducts) {
              user.ownedProducts = user?.ownedProducts * 1 - 1;
            }
            closePopup();
          });
        }}>
        {i18next.t('users:profile.ownedProductsGrid.deletePopup.button')}
      </Button>
    </Box>
  );
};
