import { Box, Flex, Grid } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { ReactNode } from "react";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { CategorySearchContext } from "./context";
import { MetaCategory } from "./MetaCategory";

type MetaCategory = string;

interface Props {
  children?: ReactNode;
  onChange: (category: MetaCategory[]) => void;
  value: MetaCategory[];
}

const CategorySearchControllerBase: React.FC<Props> = ({
  children,
  onChange,
  value,
}) => {
  const { categoryRepository } = useRootRepositoryContext();

  return (
    <CategorySearchContext.Provider
      value={{
        selectedMetaCategories: value,
        setSelectedMetaCategories: onChange,
      }}>
      <Flex
        direction="row"
        justifyContent={"space-between"}
        pb={[0, 2, 2, 4, 10]}
        mb={[4, 4, 4, 4, 0]}
        w={["230px", "400px", "500px", "800px", "unset"]}
        overflowX={["scroll", "scroll", "scroll", "scroll", "unset"]}>
        {categoryRepository.metaCategories.map((metaCategory, index) => (
          <MetaCategory
            key={index}
            category={metaCategory.metaCategoryName}
            minW={["126px", "177px"]}
            mx={[1, "6px", 2, 2, 0]}
          />
        ))}
      </Flex>
      <Box
        height={["470px", "470px", "490px", "500px", "unset"]}
        overflowX={["scroll", "scroll", "scroll", "scroll", "unset"]}
        w={["230px", "400px", "500px", "800px", "unset"]}
        ml={["6px", 0]}>
        <Grid templateColumns={["repeat(8, 126px)"]} gap={[2, 3, 6]}>
          {children}
        </Grid>
      </Box>
    </CategorySearchContext.Provider>
  );
};

export const CategorySearchController = compose<Props, Props>(observer)(
  CategorySearchControllerBase
);
