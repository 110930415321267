import { Box, BoxProps, Image, Stack, Text, Link } from "@chakra-ui/react";
import { TextLink } from "app/shared/textLink";
import React, { ReactNode } from "react";
import { Headline } from "app/shared";
import { useMediaQueryContext } from "styles/context";

interface Props extends BoxProps {
  imgSrc: string;
  imgSrc2: string;
  text: string;
  id: string;
  headline: string;
  date: string;
  imgPosition: "left" | "right";
}

export const NewsCard: React.FC<Props> = ({
  imgSrc,
  imgSrc2,
  imgPosition,
  text,
  id,
  headline,
  date,
  ...rest
}) => {
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  const flexDirection = isSmallMedium ? "column" : imgPosition === "left" ? "row" : "row-reverse";
  const newsLink = `/newsDetail/${id}`;
  const withImage = imgSrc != null && imgSrc.length > 0;

  return (
    <Stack direction={flexDirection} spacing={8} {...rest}>
      {withImage && (<Box minWidth={isSmall ? "100%" : "390px"} maxWidth={isSmall ? "100%" : "390px"}>
        <Image src={imgSrc} width={"100"} height={"auto"} />
        <Text>{imgSrc2}</Text>
      </Box>)}
      <Box fontSize="lg" color="darkGrey">
        <Text background="#E5E5E5" color="#455A64" display="inline" padding="0 10px">{date}</Text>
        <Headline as="h2" fontSize={["xl", "xl", "5xl", "5xl"]} lineHeight={"1.1"}>{headline}</Headline>
        <span dangerouslySetInnerHTML={{ __html: text }} />

        <TextLink
          to={newsLink}
          color="brandRed.300"
          fontWeight="bold">
          {"Artikel lesen >>"}
        </TextLink>
      </Box>
    </Stack>
  );
};
