import { Image, ImageProps } from "@chakra-ui/react";
import React from "react";
import { ButtonLink } from "../buttonLink";
import logoGrey from "./static/logo-grey.svg";
import logo from "./static/logo.svg";

interface Props extends ImageProps {
  onClick?: () => void;
  logoColor?: "white" | "grey";
}

export const SiteImageButton: React.FC<Props> = ({
  width,
  onClick,
  logoColor,
  ...rest
}) => (
  <ButtonLink
    to="/"
    onClick={onClick}
    variant="unstyled"
    width={width ?? ["150px", "300px", "384px"]}
    height={[70, 70, 90]}
    display="table"
    _focus={{ textDecoration: "none" }}>
    <Image
        height={[70, 70, 90]}
      width="100%"
      src={logoColor === "grey" ? logoGrey : logo}
      alt="site-logo"
      {...(rest as any)}
    />
  </ButtonLink>
);
