/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, BoxProps, Flex, Stack, Grid, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { DefaultMarker } from 'app/shared/maps/DefaultMarker';
import { Map } from 'app/shared/maps/Map';
import { Coords } from 'app/shared/maps/models';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { StoreId } from '../models';
import { useStoreRepositoryContext } from '../repositories';
import { StoreTextInfo } from './StoreTextInfo';
import { Button } from 'app/shared';
import { EmbeddedVideo, PhotoBox } from 'app/shared';
import { StoreWindowForList } from 'app/storeWindows/models';
import { getStoreWindowsFromStore } from 'app/storeWindows/services';
import { StoreWindowCard } from './StoreWindowCard';

interface Props extends BoxProps {
  storeId: StoreId;
}

const StoreInfoBase: React.FC<Props> = ({ storeId }) => {
  const { storeRepository } = useStoreRepositoryContext();

  const [storeWindows, setStoreWindows] = useState<StoreWindowForList[]>([]);
  const [center, setCenter] = useState<Coords | undefined>(undefined);
  const [zoom] = useState(15);
  const [, setGoogleMapsApi] = useState<any>(undefined);

  useEffect(() => {
    storeRepository
      .fetchStore(storeId)
      .then(() => setCenter(storeRepository.store.coordinates));
    getStoreWindowsFromStore(storeId).then((response) =>
      setStoreWindows(response.filter((x) => x.visible && x.countProducts > 0))
    );
  }, [storeId, storeRepository]);

  let currentSearchUrl = localStorage.getItem('wirLiefer_searchUrl');
  if (currentSearchUrl === null || currentSearchUrl.length == 0) {
    currentSearchUrl = '/search';
  }

  return storeRepository.status === 'successful' ? (
    <Box>
      <Button width="215px" marginBottom="25px">
        <Link to={currentSearchUrl}>zurück zum Suchergebnis</Link>
      </Button>

      <Stack
        direction="column"
        spacing={[5, 5, 20]}
        pb={9}
        w={['100%', '100%', 'unset']}>
        <Flex
          direction={['column', 'column', 'column', 'column', 'row']}
          alignItems={['flex-start']}>
          <StoreTextInfo
            w={['100%', '100%', '100%', '100%', '50%']}
            mr={[0, 0, 0, 0, 16]}
            mb={[5, 5, 5, 5, 0]}
          />
          <Box>
            <PhotoBox
              photos={storeRepository.store.photos}
              w={['100%', '100%', '100%', '100%', '50%']}
            />
            {storeRepository.store.videoUrl && (
              <Box w={['100%', '100%', '100%', '100%', '100%']}>
                <EmbeddedVideo
                  src={storeRepository.store.videoUrl}
                  mx="auto"
                  title="embeddedVideo"
                  my={5}
                />
              </Box>
            )}
          </Box>
        </Flex>

        {/* Schaufenster */}
        {storeWindows.length > 0 && (
          <Stack direction="column" spacing={8}>
            <Box>
              <Text fontSize={['3xl', '3xl', '4xl', '5xl']} fontWeight="bold">
                {i18next.t('stores:storeInfo.storeWindowHeader')}
              </Text>
              <Text fontSize="lg">
                {i18next.t('stores:storeInfo.storeWindowSubheader')}
              </Text>
            </Box>

            <Box
              w={['250px', '300px', '648px', '810px', '1232px']}
              color="black">
              <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                {storeWindows?.map((item, index) => (
                  <Box key={`item_${index}`}>
                    <StoreWindowCard storeWindow={item} />
                  </Box>
                ))}
              </Grid>
            </Box>
          </Stack>
        )}

        {/* Maps */}
        <Stack direction="column" spacing={8}>
          <Text fontSize={['3xl', '3xl', '4xl', '5xl']} fontWeight="bold">
            {i18next.t('stores:storeInfo.googleMapsHeader')}
          </Text>
          <Box
            w={['250px', '300px', '648px', '810px', '1232px']}
            h={['200px', '250px', '400px', '400px', '572px']}
            bg="white"
            color="black">
            <Map
              center={center}
              zoom={zoom}
              onGoogleApiLoaded={(maps) => setGoogleMapsApi(maps)}>
              {center && (
                <DefaultMarker lat={center.lat} lng={center.lng} size="30px" />
              )}
            </Map>
          </Box>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <></>
  );
};

export const StoreInfo = compose<Props, Props>(observer)(StoreInfoBase);
