import { BoxProps, Box, Text, Image, Flex } from "@chakra-ui/react";
import { OutsideClickWrapper } from "app/shared/wrapper/OutsideClickWrapper";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { ProfileMenuActions } from "./ProfileMenuActions";
import { User } from "app/users/models";
import { useMediaQueryContext } from "../../../../styles/context";
import { ArrowDownIcon } from "styles/icons";
import environment from "configurations";

interface PropsExternal extends BoxProps {
  user: User;
  onLogout?: () => void;
  onClose?: () => void;
  color?: string;
}

interface Props extends PropsExternal, RouteComponentProps { }

const ProfileMenuBase: React.FC<Props> = ({
  history,
  color,
  user,
  onLogout,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const { isSmallMedium, isMedium } = useMediaQueryContext();

  useEffect(() => {
    history.listen(() => {
      open && setOpen(false);
    });
  });

  return (
    <OutsideClickWrapper isOpen={open} callback={() => setOpen(false)}>
      <Box _hover={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <Flex direction="row" alignItems="center">
          <Image
            src={environment.site + user.profilePictureUrl}
            objectFit="cover"
            borderRadius="50%"
            width="49px"
            height="49px"
            border="2px solid"
            borderColor="brandRed.300"
            mx={isSmallMedium ? 3 : undefined }
          />
          
          {!isSmallMedium ? (
            <Text
              mx={3}
              color={color ?? "brandRed.300"}
              fontWeight="bold"
              fontSize="xl">
              {user.firstName}
            </Text>
          ): (<></>)
          }
          {!isMedium && <ArrowDownIcon width="22px" />}
        </Flex>
      </Box>
      { open && <ProfileMenuActions onLogout={() => onLogout && onLogout()} onClose={onClose} user={user} />}
    </OutsideClickWrapper>
  );
};

export const ProfileMenu = compose<Props, PropsExternal>(withRouter)(
  ProfileMenuBase
);
