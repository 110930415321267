import { Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Field } from "formik";
import i18next from "i18next";
import React from "react";

export const DEFAULT_LABEL_WIDTH = "220px";

export interface GenericFieldProps {
  fieldName: string;
  fieldType: string;
  i18nextPrefix: string;
  Component: any;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidthPx?: string;
  flexDirection?: string;
}

export interface FieldInternalProps {
  field: any;
  form: any;
}

export const GenericInput: React.FC<GenericFieldProps> = (
  props: GenericFieldProps
) => {
  const { fieldName, fieldType, Component, hideLabel, i18nextPrefix, flexDirection } = props;
  const disabled = props.disabled || false;
  const labelWidthPx = props.labelWidthPx || DEFAULT_LABEL_WIDTH;

  

  return (
    <Field
      className="block"
      name={fieldName}
      type={fieldType}
      placeholder={fieldName}>
      {(renderProps: FieldInternalProps) => (
        <FormControl
          isInvalid={
            renderProps.form.errors[fieldName] &&
            renderProps.form.touched[fieldName]
          }>
          <Flex mt={3} flexDirection={flexDirection ? flexDirection === "row" ? "row" : "column" : "row"} mb={flexDirection ? flexDirection === "row" ? "0" : "6" : "0"}>
            {!hideLabel && (
              <Box width={labelWidthPx}>
                <FormLabel htmlFor={fieldName}>
                  {i18next.t(`${i18nextPrefix}:form.fields.${fieldName}.label`)}
                </FormLabel>
              </Box>
            )}
            <Box flex="1">
              <Component
                type={fieldType === "password" ? "password" : "text"}
                {...renderProps.field}
                id={fieldName}
                placeholder={i18next.t(
                  `${i18nextPrefix}:form.fields.${fieldName}.placeholder`
                )}
                disabled={disabled}
                fontSize="lg"
                px={3}
                py={2}
              />
              {(renderProps.form.errors[fieldName] &&
                renderProps.form.touched[fieldName]) ? <Box color="brandRed.300">{renderProps.form.errors[fieldName]}</Box>  : null}
            </Box>
          </Flex>
        </FormControl>
      )}
    </Field>
  );
};
