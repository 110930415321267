import { BoxProps, Text } from "@chakra-ui/react";
import React from "react";

export const Heading: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Text
    fontWeight="bold"
    fontSize={["3xl", "4xl", "7xl"]}
    color="darkGrey"
    lineHeight={["base", "base", "base", "base", "base"]}
    {...rest}>
    {children}
  </Text>
  //<Heading
  //  fontWeight="bold"
  //  fontSize={["3xl", "4xl", "7xl"]}
  //  color="darkGrey"
  //  lineHeight={["base", "base", "base", "tall", "taller"]}
  //  {...rest}>
  //  {children}
  //</Heading>
);
