import React from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Modal } from "../modal";
import "./galleryView.css";

interface Items {
  original: string;
  thumbnail: string;
}

interface Props {
  items: Items[];
  open: boolean;
  onClose: () => void;
  index?: number;
  thumbnailsTop?: boolean;
}

export const GalleryView: React.FC<Props> = ({
  items,
  open,
  onClose,
  index,
  thumbnailsTop,
}) => (
  <Modal open={open} onClose={onClose}>
    <ReactImageGallery
      showBullets={true}
      showFullscreenButton={false}
      items={items}
      startIndex={index}
      thumbnailPosition={thumbnailsTop ? "top" : "bottom"}
    />
  </Modal>
);
