import { Box, BoxProps, Flex, Text, Link } from "@chakra-ui/react";
import { Button, EmbeddedVideo, Heading } from "app/shared";
import { TextLink } from "app/shared/textLink";
import i18next from "i18next";
import React from "react";
import { useMediaQueryContext } from "styles/context";

interface Props extends BoxProps {
  header?: string;
  src: string;
  videoWidth: string[];
  desc?: string;
  textLink?: string;
  btnText?: string;
  btnLink?: string;
}

export const FormattedEmbeddedVideo: React.FC<Props> = ({
  header,
  src,
  videoWidth,
  desc,
  textLink,
  btnText,
  btnLink,
  ...rest
}) => {
  const { isSmallMedium } = useMediaQueryContext();

  return (
    <Box {...rest}>
      <>
        {header && (
          <Flex direction="column" alignItems="center">
            <Heading
              textAlign={["center", "center", "center", "center", "unset"]}>
              {i18next.t(header)}
            </Heading>
          </Flex>
        )}
        <EmbeddedVideo
          src={src}
          mx="auto"
          maxWidth={videoWidth}
          title="embeddedVideo"
          my={5}
        />
        {
          <Flex direction="column" alignItems="center">
            { (desc || textLink) && (
              <Box pb={5} textAlign="center" maxW="75%">
                {desc && (
                  <Text fontWeight="bold" fontSize={["md", "lg", "xl"]} color="darkGrey">
                    <span dangerouslySetInnerHTML={{ __html: desc }} />
                  </Text>
                )}
                {textLink && (
                  <TextLink
                    to={btnLink ?? ""}
                    fontWeight="bold"
                    fontSize="xl"
                    color="brandRed.300">
                    {i18next.t(textLink)}
                  </TextLink>
                )}
                <br />
                <br />
                <Link
                  href="lieblingslaeden"
                  target="_blank"
                  fontWeight="bold"
                  fontSize="xl"
                  color="brandRed.300">
                  Weitere Lieblingsläden im Video gibt es hier
                </Link>
              </Box>
            )}       
            
          </Flex>
        }
      </>
    </Box>
  );
};

/*{i18next.t(desc)}*/
