import React from 'react';
import { Box, AspectRatio, Image, Text } from '@chakra-ui/react';

import { ProductForTable } from 'app/products/models';
import i18next from 'i18next';
import { LOCAL_DELIVERY, NATIONAL_DELIVERY } from 'app/shared/models';
import { DEFAULT_RATIO } from 'app/shared/constants';
import { useStoreWindowRepositoryContext } from '../repositories';
import environment from 'configurations';
import { TextLink } from 'app/shared';

interface ProductCardProps {
  product: ProductForTable;
}

export const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { storeWindowRepository } = useStoreWindowRepositoryContext();

  let deliverTypeString = '';
  if (product.deliveryOptions.canDeliver) {
    if (product.deliveryOptions.deliveryType == LOCAL_DELIVERY)
      deliverTypeString += `${i18next.t(
        'storeWindows:storeWindowInfo.local'
      )} ${product.deliveryOptions.localDeliveryRadiusKm} km`;
    if (product.deliveryOptions.deliveryType == NATIONAL_DELIVERY)
      deliverTypeString += i18next.t('storeWindows:storeWindowInfo.national');
  }

  let deliveryOption;
  if (product.deliveryOptions.canPickup && product.deliveryOptions.canDeliver)
    deliveryOption = (
      <Text fontSize="lg">
        {i18next.t('storeWindows:storeWindowInfo.pickupAndDeliver')}{' '}
        {deliverTypeString}
      </Text>
    );
  else if (product.deliveryOptions.canPickup)
    deliveryOption = (
      <Text fontSize="lg">
        {i18next.t('storeWindows:storeWindowInfo.pickupOnly')}
      </Text>
    );
  else if (product.deliveryOptions.canDeliver)
    deliveryOption = (
      <Text fontSize="lg">
        {i18next.t('storeWindows:storeWindowInfo.deliverOnly')}{' '}
        {deliverTypeString}
      </Text>
    );
  else
    deliveryOption = (
      <Text fontSize="lg">
        {i18next.t('storeWindows:storeWindowInfo.noDeliveryAndPickup')}{' '}
      </Text>
    );

  return (
    <Box boxShadow="lg">
      <AspectRatio ratio={DEFAULT_RATIO}>
        <Box _hover={{ cursor: 'pointer' }}>
          <Image
            objectFit="contain"
            width="100%"
            height="100%"
            src={environment.site + product.photo?.url}
            onClick={() =>
              window.open(
                `/product/${storeWindowRepository.storeWindow.id}/${product.id}`,
                '_self'
              )
            }
          />
        </Box>
      </AspectRatio>
      <Box p={8} bg="white">
        <TextLink
          to={`/product/${storeWindowRepository.storeWindow.id}/${product.id}`}
          fontSize="lg"
          fontWeight="bold">
          {product.name}
        </TextLink>
        <Text fontSize="lg">{`${product.price} €`}</Text>

        {deliveryOption}
      </Box>
    </Box>
  );
};
