import { BoxProps, Flex } from "@chakra-ui/react";
import { CloseButton } from "app/shared";
import { SiteImageButton } from "app/shared/siteImageButton";
import React from "react";
import { useMediaQueryContext } from "styles/context";
import { DrawerMenuContent } from "./DrawerMenuContent";
import { MobileDrawerMenuContent } from "./MobileDrawerMenuContent";

interface Props extends BoxProps {
  onClose?: () => void;
}

export const DrawerMenu: React.FC<Props> = ({ onClose }) => {
  const { isSmallMedium } = useMediaQueryContext();
  return (
    <Flex
      direction={["column", "column", "column", "row"]}
      py={1}
      justifyContent="flex-start">
      {isSmallMedium ? (
        <Flex direction="row">
          <CloseButton onClick={onClose} iconSize={"26px"} pr={5} />
          <SiteImageButton onClick={onClose} width="200px" />
        </Flex>
      ) : (
        <CloseButton onClick={onClose} iconSize={"26px"} pr={"60px"} />
      )}
      {isSmallMedium ? <MobileDrawerMenuContent onClose={onClose} /> : <DrawerMenuContent onClose={onClose} />}
    </Flex>
  );
};
