import { Box, BoxProps, Image, Stack, Link } from "@chakra-ui/react";
import {} from "app/shared";
/*import { Link } from 'react-router-dom';*/
import { TextLink } from "app/shared/textLink";
import React, { ReactNode } from "react";
import { Headline, Heading, Button } from "app/shared";
import { useMediaQueryContext } from "styles/context";
import { DeliveryTypeAndRadiusField } from "../stores/forms/StoreFormFields";

interface Props extends BoxProps {
  imageView: () => ReactNode;
  text: string;
  buttonText: string;
  id: string;
  headline: string;
  imgPosition: "left" | "right";
  link?: string;
}

export const BannerCard: React.FC<Props> = ({
  imageView,
  imgPosition,
  text,
  buttonText,
  id,
  headline,
  link,
  ...rest
}) => {
  const { isSmall, isSmallMedium } = useMediaQueryContext();

  const flexDirection = isSmallMedium
    ? "column"
    : imgPosition === "left"
    ? "row"
    : "row-reverse";

  return (
    <Stack
      direction={flexDirection}
      position="relative"
      alignItems={["center"]}
      spacing={8}
      {...rest}
      key={id + "0"}>
      <Box
        position="absolute"
        width="100vw"
        height="100%"
        marginLeft="-50vw"
        left="50%"
        zIndex={[10]}
        bg="#F4F4F4"
        pt="40px"
        pb="40px"></Box>

      <Box
        position="relative"
        zIndex={[100]}
        minWidth={isSmall ? "100%" : "390px"}
        maxWidth={isSmall ? "100%" : "390px"}>
        <Box
          display="flex"
          justifyContent={isSmallMedium ? "flex-start" : "flex-end"}
          mb={"0"}>
          {imageView()}
        </Box>
      </Box>
      <Box
        position="relative"
        zIndex={[100]}
        fontSize="lg"
        color="content.text.color"
        mt={["0", "0", "40px"]}
        marginBottom={["40px", "40px", "40px", "40px", "0"]}
        display="flex"
        flexDirection="column"
        textAlign={["center", "center", "left"]}
        alignItems={["center", "center", "flex-start"]}>
        <Heading
          color="brandRed.300"
          width="100%"
          mb="30px"
          fontSize={["3xl", "4xl", "7xl"]}
          lineHeight={"1.1"}
          dangerouslySetInnerHTML={{ __html: headline }}></Heading>
        <span dangerouslySetInnerHTML={{ __html: text }} />
        {link && (
          <Button width="200px" display="block" mt="40px">
            <a href={link} target="_blank">
              {buttonText}
            </a>
          </Button>
        )}
      </Box>
    </Stack>
  );
};
