/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Flex, Center, Box, BoxProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { useProductRepositoryContext } from "../repositories";
import { Button, PhotoBox, TextLink } from "app/shared";
import { ProductTextInfo } from "./ProductTextInfo";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useStoreWindowRepositoryContext } from "app/storeWindows/repositories";

interface Props extends BoxProps {
  storeWindowId: string;
  productId: string;
}

const ProductInfoBase: React.FC<Props> = ({ storeWindowId, productId }) => {
  const [previousIndex, setPreviousIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const { storeWindowRepository } = useStoreWindowRepositoryContext();
  const { productRepository } = useProductRepositoryContext();

  const storeWindow = storeWindowRepository.storeWindow;

  useEffect(() => {
    storeWindowRepository.fetchStoreWindow(storeWindowId).then(() => {
      const productCount = storeWindowRepository.storeWindow.products.length;
      const productIndex = storeWindowRepository.storeWindow.products
        .map(function (x) {
          return x.id;
        })
        .indexOf(productId);
      setPreviousIndex(
        (((productIndex - 1) % productCount) + productCount) % productCount
      );
      setNextIndex(
        (productIndex + 1) % storeWindowRepository.storeWindow.products.length
      );
    });
    productRepository.fetchProduct(productId);
  }, [productId]);

  return productRepository.status === "successful" &&
    storeWindowRepository.status === "successful" ? (
    <Box>
      <Button width="215px" marginBottom="25px">
        <Link to={`/storeWindow/${storeWindow.id}`}>
          {i18next.t("products:productInfo.back")}
        </Link>
      </Button>

      <Stack
        direction="column"
        spacing={[5, 5, 20]}
        pb={9}
        w={["100%", "100%", "unset"]}>
        <Flex
          direction={["column", "column", "column", "column", "row"]}
          alignItems={["flex-start"]}>
          <ProductTextInfo
            w={["100%", "100%", "100%", "100%", "50%"]}
            mr={20}
            mb={[5, 5, 5, 5, 0]}
          />
          <PhotoBox
            photos={productRepository.product.photos}
            w={["100%", "100%", "100%", "100%", "50%"]}
          />
        </Flex>

        {storeWindow.products.length > 1 && (
          <Stack spacing={5}>
            <Box width="100%" height={1} bg="darkestGrey" />
            <Center>
              <Stack direction="row" spacing={10}>
                <TextLink
                  to={`/product/${storeWindow.id}/${storeWindow.products[previousIndex].id}`}
                  fontSize="lg">
                  <ArrowBackIcon /> {i18next.t("products:productInfo.previous")}
                </TextLink>
                <TextLink
                  to={`/product/${storeWindow.id}/${storeWindow.products[nextIndex].id}`}
                  fontSize="lg">
                  {i18next.t("products:productInfo.next")} <ArrowForwardIcon />
                </TextLink>
              </Stack>
            </Center>
          </Stack>
        )}
      </Stack>
    </Box>
  ) : (
    <></>
  );
};

export const ProductInfo = compose<Props, Props>(observer)(ProductInfoBase);
