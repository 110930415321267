import { Flex, Stack, Box, Link, Text } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { FacebookFooterIcon, InstagramFooterIcon, LinkinFooterIcon, TwitterFooterIcon, YoutubeFooterIcon } from "styles/icons";
import { Divider } from "../divider";
import { TextLink } from "../textLink";

export const Footer: React.FC = () => (
  <Flex flexDirection="column">
    <Flex
      width={"100%"}
      maxWidth={["maxWidth"]}
      mx="auto"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      pl={"10px"}
      pr={"10px"}>
      <Stack direction="row" flexWrap="wrap" px={[6, 6, 0]} py={6}>
        <TextLink
          to="/Datenschutzerklaerung"
          fontWeight="bold"
          color="white"
          fontSize="lg">
          {i18next.t("shared:footer.privacyPolicy")}
        </TextLink>
        <Divider color="white" />
        <TextLink to="/Impressum" fontWeight="bold" color="white" fontSize="lg">
          {i18next.t("shared:footer.impressum")}
        </TextLink>
        <Divider color="white" />
        <TextLink to="/presse" fontWeight="bold" color="white" fontSize="lg">
          {i18next.t("shared:footer.press")}
        </TextLink>
        <Divider color="white" />
        <TextLink to="/news" fontWeight="bold" color="white" fontSize="lg">
          {i18next.t("shared:footer.news")}
        </TextLink>
        <Divider color="white" />
        <TextLink to="/partner" fontWeight="bold" color="white" fontSize="lg">
          {i18next.t("shared:footer.partner")}
        </TextLink>

        {/* <Box pt={2}>
        <Text color="gray.300" fontSize={["xs", "sm", "md"]}>
          © Copyright
        </Text>
      </Box> */}
      </Stack>
      <Stack
        pt={[0, 0, 6]}
        pb={"6"}
        direction="row"
        flexWrap="wrap"
        px={[6, 6, 0]}>
        <Link
          href="https://www.facebook.com/wirliefernorg/"
          isExternal
          target="_blank">
          <FacebookFooterIcon boxSize="40px"/>
        </Link>
        <Link
          href="https://www.instagram.com/wir.liefern/"
          isExternal
          target="_blank">
          <InstagramFooterIcon boxSize="40px"/>
        </Link>
        <Link
          href="https://www.youtube.com/channel/UCm8rJaHGldIY28zWd2HoEsQ"
          isExternal
          target="_blank">
          <YoutubeFooterIcon boxSize="40px"/>
        </Link>
        <Link
          href="https://twitter.com/Wirliefernorg"
          isExternal
          target="_blank">
          <TwitterFooterIcon boxSize="40px"/>
        </Link>
        <Link
          href="https://www.linkedin.com/company/wir-liefern-org/"
          isExternal
          target="_blank">
          <LinkinFooterIcon boxSize="40px"/>
        </Link>
      </Stack>
    </Flex>

    <Flex
      flexDirection="column"
      width={"100%"}
      maxWidth={["maxWidth"]}
      mx="auto"
      pl={"10px"}
      pr={"10px"}>
      <Stack px={[6, 6, 0]} py={6}>
        <Text color="white" fontSize="30px">
          Bekannt aus...
        </Text>
        <Text color="white" fontSize="24px" lineHeight="38px" fontWeight="bold">
          NDR, Der Spiegel, Focus, Hamburger Morgenpost, Hamburger Abendblatt,
          Der Tagesspiegel, Internet World, TV-Spielfielm, Hinz&Kunzt, Sh:z,
          TextilWirtschaft, Handelsjournal, RSH
        </Text>
      </Stack>
    </Flex>
  </Flex>
);
