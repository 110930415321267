export const LOCAL_DELIVERY = "Local";
export const NATIONAL_DELIVERY = "National";

export type DeliveryType = "Local" | "National";

export interface DeliveryOptions {
  canDeliver?: boolean;
  deliveryDescription?: string;
  deliveryType?: DeliveryType;
  localDeliveryRadiusKm?: number;
  canPickup: boolean;
  pickupDescription?: string;
}