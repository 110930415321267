import { Box, BoxProps, Flex, Grid, Text } from "@chakra-ui/react";
import { Button } from "app/shared";
import { StoreId } from "app/stores/models";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { useCommentRepositoryContext } from "../repositories";
import { CommentView } from "./CommentView";

interface Props extends BoxProps {
  storeId: StoreId;
}

const StoreCommentsGridBase: React.FC<Props> = ({ storeId }) => {
  const { commentRepository } = useCommentRepositoryContext();
  const [maxDisplayedComments, setMaxDisplayedComments] = useState(0);

  useEffect(() => {
    commentRepository.fetchCommentsForPage(storeId).then(() => {
      setMaxDisplayedComments(commentRepository.paginatedComments.perPage);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return commentRepository.comments?.length !== 0 ? (
    <Box py={[4, 6, 9]}>
      <Text
        fontSize={["3xl", "3xl", "4xl", "5xl"]}
        fontWeight="bold"
        pb={[4, 6, 8]}>
        {i18next.t("comments:storeCommentsGrid.header")}
      </Text>
      <Grid
        templateColumns={[
          "repeat(1, auto)",
          "repeat(1, auto)",
          "repeat(1, auto)",
          "repeat(2, auto)",
          "repeat(2, 596px)",
        ]}
        templateRows={[
          `repeat(${commentRepository.comments.length}, 185px)`,
          `repeat(${commentRepository.comments.length}, 185px)`,
          `repeat(${commentRepository.comments.length}, 185px)`,
          `repeat(${Math.ceil(commentRepository.comments.length / 2)}, 185px)`,
        ]}
        gap={[4, 6, 8]}>
        {commentRepository.comments.map(
          (comment, index) =>
            index < maxDisplayedComments && (
              <CommentView key={`comment_index_${index}`} comment={comment} />
            )
        )}
      </Grid>
      {commentRepository.comments.length <
        commentRepository.paginatedComments.totalResults && (
        <Flex
          direction="column"
          alignItems="center"
          borderTop="4px solid"
          borderColor="brandRed.300"
          mt={[6, 8, 10]}>
          <Button
            w={["60%", "60%", "15%"]}
            disableHover={true}
            onClick={() =>
              commentRepository
                .fetchMoreComments(storeId)
                .then(() =>
                  setMaxDisplayedComments(
                    maxDisplayedComments +
                      commentRepository.paginatedComments.perPage
                  )
                )
            }>
            {i18next.t("comments:storeCommentsGrid.loadMore")}
          </Button>
        </Flex>
      )}
    </Box>
  ) : (
    <Text fontSize="lg" color="white" fontWeight="bold" pt={[10, 10, 20]}>
      {i18next.t("comments:storeCommentsGrid.emptyState")}
    </Text>
  );
};

export const StoreCommentsGrid = compose<Props, Props>(observer)(
  StoreCommentsGridBase
);
