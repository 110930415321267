import { Box, BoxProps, Flex, Stack, Text, Image } from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { ButtonLink } from "app/shared/buttonLink";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { compose } from "recompose";
import { useNewsRepositoryContext } from "../repositories";
import { Button, Headline } from "app/shared";
import { SitePager } from "app/shared/sitePager";
import { useMediaQueryContext } from "styles/context";

import { NewsDetails } from "../models/index";

interface Props extends BoxProps {
  newsId: string;
}

const NewsDetailsInfoBase: React.FC<Props> = ({ newsId }) => {

  const { isMediumLarge, isSmallMedium } = useMediaQueryContext();
  const { newsRepository } = useNewsRepositoryContext();
  const [loaded, setLoaded] = useState<boolean>(false);

  if (!loaded) {
    newsRepository.fetchNewsDetail(newsId).then(() => {
      setLoaded(true);
    });
  }

  let htmlText = "";
  let prevLink = "";
  let nextLink = "";
  let newsText = "";
  if (loaded) {

    prevLink = newsRepository.newsDetail?.prev ? `/newsDetail/${newsRepository.newsDetail?.prev}` : "";
    nextLink = newsRepository.newsDetail?.next ? `/newsDetail/${newsRepository.newsDetail?.next}` : "";

    newsText = newsRepository.newsDetail.text;
    newsText = newsText?.replace("<a", "<a style='color: #EB5757; font-weight: 700;' ").replace("<h2", "<h2 style='font-weight: bold;' ");


  }

  return (
    loaded ? (
      <Box>
        <Flex >
          <Box width={isMediumLarge ? "100%" : "910px"}>
            <Headline as="h1" lineHeight="52px" mb="20px" >{newsRepository.newsDetail?.headline}</Headline>
            <Text fontWeight="bold" fontSize="20px" mb="20px" color="darkGrey"><span dangerouslySetInnerHTML={{ __html: newsRepository.newsDetail?.teaser }} /></Text>
            {newsRepository.newsDetail.pictureFile && (
              <Image
                height="auto"
                width="100%"
                maxWidth="700px"
                objectFit="cover"
                src={newsRepository.newsDetail?.pictureFile}
                my="15px"
              />
            )
            }
            <Text fontWeight="400" fontSize="20px" color="darkGrey" lineHeight="27px">  <span dangerouslySetInnerHTML={{ __html: newsText }} /></Text>

          </Box>
          {!isMediumLarge && (
            <SitePager nextLink={nextLink} prevLink={prevLink} onClick={() => { setLoaded(false) }}></SitePager>
          )}
        </Flex>
        <Flex
          mt="25px"
        >
          <ButtonLink
            color="white"
            width="215px"
            height="40px"
            mr="14px"
            backgroundColor="brandRed.300"
            _hover={{ backgroundColor: "brandRed.400" }}
            to={"/news"}

          >

            {"<< Zurück zur Übersicht"}

          </ButtonLink>
          <SitePager nextLink={nextLink} prevLink={prevLink} onClick={() => { setLoaded(false) }}></SitePager>
        </Flex>
      </Box >
    ) : (<></>)

  )
};

export const NewsDetailsInfo = compose<Props, Props>(observer)(NewsDetailsInfoBase);
