import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { CategoryIcons } from "app/categories/constants";
import { BottomTriangle } from "app/shared";
import { observer } from "mobx-react";
import React, { ReactNode, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { useRootRepositoryContext } from "rootRepository";
import { createSearchUrl, getSubCategoriesForParam } from "../utils";
import { CategorySearchContext } from "./context";
import { CategoryIcon } from "app/categories/categoryMultiSelect/CategoryIcon"

type Category = string;

interface PropsExternal extends BoxProps {
  categoryId: string;
  categoryName: Category;
  children?: ReactNode;
}

interface Props extends PropsExternal, RouteComponentProps {}

const CategoryBase: React.FC<Props> = ({ categoryName, categoryId, history, children }) => {
  const { selectedMetaCategories } = useContext(CategorySearchContext);
  const { categoryRepository } = useRootRepositoryContext();

  const isCategorySelectable = () => {
    const selectedMetaCategoriesObj = categoryRepository.metaCategories.filter(
      (metaCategory) =>
        selectedMetaCategories?.includes(metaCategory.metaCategoryName)
    );

    const selectedCategories = selectedMetaCategoriesObj
      .map((selectedMetaCategory) => selectedMetaCategory.categories)
      .reduce((acc, val) => acc.concat(val), []);
    //.flat(1);

    return (
      selectedCategories.filter((category) => categoryName === category).length ===
      selectedMetaCategoriesObj.length
    );
  };

  const doSearch = () => {
    const subCategories = getSubCategoriesForParam(
      categoryId,
      categoryRepository.categories
    );

    const searchUrl = createSearchUrl(categoryId, subCategories);
    history.push(searchUrl);
  };

  const selectable = isCategorySelectable();

  const categoryIcon = CategoryIcons.find((c) => c.categoryName === categoryName);

  return (
    <Box
      position="relative"
      my={3}
      boxShadow={selectable ? "-5px 7px 20px rgba(0, 0, 0, 0.15)" : undefined}>
      <Box
        _hover={{ cursor: selectable ? "pointer" : undefined }}
        onClick={selectable ? doSearch : undefined}
        height="100%">
        <Flex
          bg={"brandRed.300"}
          opacity={selectable ? 1 : 0.15}
          direction="column"
          color="white"
          alignItems="center"
          py={8}
          px={3}
          h={"196px"}>
          <CategoryIcon name={categoryIcon?.iconName ?? "shirt"} boxSize="68px" />
          <Text
            textAlign="center"
            fontSize={["sm", "17px"]}
            fontWeight="bold"
            >
            {categoryName}
          </Text>
        </Flex>
      </Box>
      <BottomTriangle selectable={selectable} />
    </Box>
  );
};

export const Category = compose<Props, PropsExternal>(
  withRouter,
  observer
)(CategoryBase);
