import { CategoryRepository } from "app/categories/repositories";
import { UserRepository } from "app/users/repositories";
import { TestimonialRepository } from "app/testimonials/repositories/TestimonialRepository";

import React from "react";
import { RootRepositoryContext } from "./context";
import { MapsRepository } from "./MapsRepository";
import { CampaignRepository } from "app/campaign/repositories/CampaignRepository";

interface Props {
  children: React.ReactNode;
}

export const RootRepositoryProvider: React.FC<Props> = ({ children }) => {

  const categoryRepository = new CategoryRepository();
  const mapsRepository = new MapsRepository();
  const userRepository = new UserRepository();
  const testimonialRepository = new TestimonialRepository();
  const campaignRepository = new CampaignRepository();


  return (
    <RootRepositoryContext.Provider
      value={{ categoryRepository, mapsRepository, userRepository, testimonialRepository, campaignRepository}}>
      {children}
    </RootRepositoryContext.Provider>
  );
};
