import React from 'react';
import { Box, Flex, AspectRatio, Image, Link, Text } from '@chakra-ui/react';

import { StoreWindowForList } from 'app/storeWindows/models';
import { TextLink } from 'app/shared';
import { DEFAULT_RATIO } from 'app/shared/constants';
import i18next from 'i18next';
import { ArrowRightIcon } from '@chakra-ui/icons';
import environment from 'configurations';

interface StoreWindowCardProps {
  storeWindow: StoreWindowForList;
}

export const StoreWindowCard: React.FC<StoreWindowCardProps> = ({
  storeWindow,
}) => {
  return (
    <Box boxShadow="lg">
      <AspectRatio ratio={DEFAULT_RATIO}>
        <Box _hover={{ cursor: 'pointer' }}>
          <Image
            objectFit="contain"
            width="100%"
            height="100%"
            src={environment.site + storeWindow.photo?.url}
            onClick={() =>
              window.open(`/storeWindow/${storeWindow.id}`, '_self')
            }
          />
        </Box>
      </AspectRatio>
      <Box p={8} bg="lighterBrandGrey">
        <TextLink
          to={`/storeWindow/${storeWindow.id}`}
          color="white"
          fontSize="xl"
          fontWeight="bold">
          {storeWindow.name}
        </TextLink>
        <Text
          color="white"
          fontSize="lg"
          mb={5}>{`${storeWindow.countProducts} Produkte`}</Text>
        <TextLink
          to={`/storeWindow/${storeWindow.id}`}
          color="white"
          fontSize="lg"
          mt="auto">
          {i18next.t('stores:storeInfo.storeWindows.explore')}
          <ArrowRightIcon ml={2} boxSize={3} />
        </TextLink>
      </Box>
    </Box>
  );
};
