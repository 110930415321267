import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,
  FormattedEmbeddedVideo,
  InfoGrid,
  SocialInfo,
  Headline,
} from "app/shared";
import { PhotoAndTextBlock } from "app/staticPages/PhotoAndTextBlock";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { StaticPageTitle } from "app/staticPages/StaticPageTitle";
import React from "react";
import i18next from "i18next";
import useScript from "../utils/useScript";

const ForStoresVisibility = "images/ForStoresVisibility.svg";
const ForStoresCommunity = "images/ForStoresCommunity.svg";

export const ForStores: React.FC = () => {

  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'fur-geschafte', section: 'fur-geschafte', pubdate: '', author: '', url: '/fur-geschafte', object_type: 'fur-geschafte' }}); } ");

  return (
    <ContentArea>
      <Headline as="h1">{i18next.t("forStores:title")}</Headline>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forStores:introText1")}
        </Text>
      </StaticPageTextBlock>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("forStores:section1.line1")}
        </Text>
        {i18next.t("forStores:section1.line2")}
      </StaticPageTextBlock>

      <PhotoAndTextBlock
        imgSrc={ForStoresVisibility}
        imgWidth="5000px"
        imgPosition="left"
        my={12}>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("forStores:section2.line1")}
          </Text>
          {i18next.t("forStores:section2.line2")}
        </StaticPageTextBlock>
      </PhotoAndTextBlock>

      <PhotoAndTextBlock
        imgSrc={ForStoresCommunity}
        imgWidth="2000px"
        imgPosition="left"
        my={12}>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("forStores:section3.line1")}
          </Text>
          {i18next.t("forStores:section3.line2")}
          <br />
          {i18next.t("forStores:section3.line3")}
          <br />
          <Link href="/newUserAndStore" color="brandRed.300" fontWeight="bold">
            {i18next.t("forStores:section3.line4")}
          </Link>
        </StaticPageTextBlock>
      </PhotoAndTextBlock>

      <SocialInfo header={"shared:socialInfo.header"} py={20} />
    </ContentArea>
  )
};
