import { CategoryDTO } from "../models";
import environment from "configurations";
import axios from "../../../network";
/**
 * Categories
 */

export const getCategories = async (): Promise<CategoryDTO[]> => {
  return new Promise<CategoryDTO[]>((resolve, reject) => {

    let categoriesDTO: CategoryDTO[] = [];
    axios.
      get("/Categories")
      .then(function (response) {
        try {
          let data = response.data;
          //response.json().then(function (categories: CategoryDTO[]) {
          categoriesDTO = data;
          resolve(categoriesDTO);
          //});
        } catch (r) {
          reject(categoriesDTO);
        }
      }).catch(() => {
        console.log("Error loading/parsing category data");
        reject(categoriesDTO);
      });
  });
};
