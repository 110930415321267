import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/react";
import { SiteImageButton } from "app/shared/siteImageButton";
import i18next from "i18next";
import React from "react";
import { NavbarActions } from "../navbarActions";
import { DrawerMenuLink } from "./DrawerMenuLink";

interface Props {
  onClose?: () => void;
}

export const DrawerMenuContent: React.FC<Props> = ({ onClose }) => (
  <Stack py={2} direction="column" maxW="800px">
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pb={10}>
      <SiteImageButton onClick={onClose} width="200px" />
      <NavbarActions color="white" onClose={onClose} />
    </Flex>

    <Stack direction="row" justifyContent="space-between">
      <Stack direction="column" py={2}>
        <DrawerMenuLink
          href="/fur-nutzer"
          name={i18next.t("shared:navbar.burgerDrawer.forUsers")}
          onClick={onClose}
          iconVisible={false}
        />
        <DrawerMenuLink
          href="/fur-geschafte"
          name={i18next.t("shared:navbar.burgerDrawer.forStore")}
          onClick={onClose}
          iconVisible={false}
        />
        <DrawerMenuLink
          href="/about"
          name={i18next.t("shared:navbar.burgerDrawer.about")}
          onClick={onClose}
          iconVisible={false}
        />
        <DrawerMenuLink
          href="/NEWS"
          name={i18next.t("shared:navbar.burgerDrawer.news")}
          onClick={onClose}
          iconVisible={false}
        />

        <DrawerMenuLink
          href="/kontakt"
          name={i18next.t("shared:navbar.burgerDrawer.contact")}
          onClick={onClose}
          iconVisible={false}
        />
      </Stack>
      <Divider orientation="vertical" color="white" />
      <Box width="50%" py={4}>
        <Text fontSize={"lg"}>
          {i18next.t("shared:navbar.burgerDrawer.info")}
        </Text>
      </Box>
    </Stack>
  </Stack>
);
