import { MixedPhoto } from "app/shared/models";
import { isPhoto } from "./PhotoTypeChecker";

export const filesSizeChecker = (maxSizeInBytes: number) => {
  return (files: MixedPhoto[]): boolean => {
    // The array is not valid if there are some files with the size over the max
    return !files
      .filter((it) => !isPhoto(it))
      .some((file) => (file as File).size > maxSizeInBytes);
  };
};

export const fileSizeChecker = (maxSizeInBytes: number) => {
  return (file: MixedPhoto | null): boolean => {
    return file && !isPhoto(file) ? (file as File).size <= maxSizeInBytes : true;
  };
};

export const filesTypeChecker = (validTypes: string[]) => {
  return (files: MixedPhoto[]): boolean => {
    // The array is not valid if there are some files whose type is not included in the valid type list
    return !files
      .filter((it) => !isPhoto(it))
      .some((file) => !validTypes.includes((file as File).type));
  };
};

export const fileTypeChecker = (validTypes: string[]) => {
  return (file: MixedPhoto | null): boolean => {
    return file && !isPhoto(file) ? validTypes.includes((file as File).type) : true;
  };
};
