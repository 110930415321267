import { Product, ProductForTable, ProductSubCategory } from '../models/index';
import { Photo } from 'app/shared/models';
import { ProductFormViewModel } from '../forms/ProductInfoSchema';

import axios from '../../../network';

export const getOwnedProducts = async (
  userId: string
): Promise<ProductForTable[]> => {
  return new Promise<ProductForTable[]>((resolve, reject) => {
    axios
      .get('/Products/SearchMyProducts?userId=' + userId)
      .then(function (response) {
        try {
          const productData: ProductForTable[] = [];

          for (let i = 0; i < response.data.length; i++) {
            productData.push(getProductForListFromData(response.data[i]));
          }

          resolve(productData);
        } catch (r) {
          console.log('Error loading/parsing Product data');
        }
      })
      .catch(() => {
        console.log('Error loading/parsing Product data');
      });
  });
};

function getProductForListFromData(data: any): ProductForTable {
  const photos: Photo[] = getPicturesFromData(data);

  return {
    id: data.id ?? '',
    photo: photos[0],
    name: data.name ?? '',
    price:
      data.price.toFixed(2).toString().replace('.', ',') ?? 0,
    deliveryOptions: {
      canDeliver: data.canDeliver ?? false,
      canPickup: data.canPickup ?? false,
      deliveryDescription: data.deliveryDescription ?? '',
      deliveryType: data.deliveryType ?? undefined,
      localDeliveryRadiusKm: data.localDeliveryRadiusKm,
      pickupDescription: data.pickupDescription,
    },
    stock: data.stock ?? '',
  };
}

export const getProduct = async (productId: string): Promise<Product> => {
  return new Promise<Product>((resolve, reject) => {
    axios
      .get('/Products/Product/' + productId)
      .then(function (response) {
        try {
          const product: Product = getProductFromData(response.data);
          resolve(product);
        } catch (r) {
          console.log('Error loading/parsing Product data');
        }
      })
      .catch(() => {
        console.log('Error loading/parsing Product data');
      });
  });
};

function getProductFromData(data: any): Product {
  const subcategories: ProductSubCategory[] = getSubCategoriesFromData(data);
  const photos: Photo[] = getPicturesFromData(data);

  return {
    id: data.id ?? '',
    name: data.name ?? '',
    description: data.description ?? '',
    price: data.price.toFixed(2).toString().replace('.', ',') ?? 0,
    stock: data.stock ?? '',
    link: data.link ?? '',
    deliveryOptions: {
      canDeliver: data.canDeliver ?? false,
      canPickup: data.canPickup ?? false,
      deliveryDescription: data.deliveryDescription ?? '',
      deliveryType: data.deliveryType ?? undefined,
      localDeliveryRadiusKm: data.localDeliveryRadiusKm,
      pickupDescription: data.pickupDescription,
    },
    categories: subcategories,
    photos: photos,
  };
}

function getPicturesFromData(data: any): Photo[] {
  if (data === undefined || data === null) {
    return [];
  }

  if (data.pictures === undefined || data.pictures === null) {
    return [];
  }

  const pictures: Photo[] = [];
  for (let i = 0; i < data.pictures.length; i++) {
    const photo: Photo = {
      id: data.pictures[i].id ?? '',
      url: data.pictures[i].photoURL ?? '',
    };
    pictures.push(photo);
  }

  return pictures;
}

function getSubCategoriesFromData(data: any): ProductSubCategory[] {
  if (data === undefined || data === null) {
    return [];
  }

  if (data.categories === undefined || data.categories === null) {
    return [];
  }
  const subcategories: ProductSubCategory[] = [];
  for (let i = 0; i < data.categories.length; i++) {
    const subCat: ProductSubCategory = {
      name: data.categories[i].name ?? '',
      parentCategory: data.categories[i].parentCategory ?? '',
      id: data.categories[i].id ?? '',
    };
    subcategories.push(subCat);
  }

  return subcategories;
}

export const createProduct = async (
  values: ProductFormViewModel
): Promise<boolean> => {
  const { photos, ...jsonPayload } = values;

  return new Promise((resolve, reject) => {
    const data = new FormData();

    for (let i = 0; i < photos.length; i++) {
      data.append('pictures', photos[i] as File);
    }

    jsonPayload.price = jsonPayload.price.replace(',', '.');
    data.append('productInfo', JSON.stringify(jsonPayload));

    axios
      .post(`/Products/AddMyProduct`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        } else if (
          response?.status == 401 ||
          (response as any).response?.status == 401
        ) {
          reject(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log('Server error, please try again later');
        }
        reject(error);
      });
  });
};

export const updateProduct = async (
  values: ProductFormViewModel
): Promise<boolean> => {
  const { photos, ...jsonPayload } = values;

  return new Promise((resolve, reject) => {
    const data = new FormData();
    const pictures = [];

    for (let i = 0; i < photos.length; i++) {
      if (photos[i] instanceof File) {
        data.append('newPictures', photos[i] as File);
      } else {
        pictures.push(photos[i] as Photo);
      }
    }

    data.append('pictures', JSON.stringify(pictures));

    jsonPayload.price = jsonPayload.price.replace(',', '.');
    data.append('productInfo', JSON.stringify(jsonPayload));

    axios
      .post(`/Products/UpdateMyProduct`, data)
      .then((response) => {
        // 999 = individueller Code von dbh für diesen Aufruf
        if (response?.data?.Code == 999) {
          reject(response?.data?.Message);
        } else if (
          response?.status == 401 ||
          (response as any).response?.status == 401
        ) {
          reject(response);
        } else {
          resolve(response.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          const errorMessage =
            error.response && error.response.data
              ? error.response.data.message
              : error.message;
          console.log(errorMessage);
        } else {
          console.log('Server error, please try again later');
        }
        reject(error);
      });
  });
};

export const deleteProduct = async (productId: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .post('/Products/DeleteProduct?id=' + productId)
      .then(function (response) {
        try {
          resolve(true);
        } catch (r) {
          console.log('Error loading/parsing Product data');
          reject(r);
        }
      })
      .catch(() => {
        console.log('Error loading/parsing Product data');
      });
  });
};
