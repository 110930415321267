import { Box, BoxProps, Text } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {
  header: string;
  desc: string;
}

export const GridText: React.FC<Props> = ({ header, desc, ...rest }) => (
  <Box {...rest}>
    <Text fontSize="xl" fontWeight="bold" color="brandRed.300">
      {header}
    </Text>
    <Text fontSize="sm" color="black">
      {desc}
    </Text>
  </Box>
);
