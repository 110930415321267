import { Radio, RadioProps } from "@chakra-ui/react";
import React from "react";

export const StyledRadio: React.FC<RadioProps> = (props) => (
  <Radio
    borderColor="mdGrey"
    fontSize="lg"
    color="darkGrey"    
    {...props}    
    colorScheme={props.colorScheme ?? "red"}
  />
);
