import { Text, Link } from "@chakra-ui/react";
import {
  ContentArea,
  FormattedEmbeddedVideo,
  InfoGrid,
  SocialInfo,
} from "app/shared";
import { PhotoAndTextBlock } from "app/staticPages/PhotoAndTextBlock";
import { StaticPageTextBlock } from "app/staticPages/StaticPageTextBlock";
import { StaticPageTitle } from "app/staticPages/StaticPageTitle";
import React from "react";
import i18next from "i18next";
import useScript from 'utils/useScript';
//import { Helmet } from "react-helmet";

const AboutDesk = "images/AboutDesk.svg";
const AboutOnline = "images/AboutOnline.svg";
const AboutQuestionMark = "images/AboutQuestionMark.svg";


export const About: React.FC = () => {
  useScript.useScriptViaString("if (typeof upScore == 'function') { upScore({data: {object_id: 'about', section: 'about', pubdate: '', author: '', url: '/about', object_type: 'about' }}); } ");

  return (
    <ContentArea>
      {/* <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Wir-liefern About" />

        <title>Über uns</title>
        <link rel="canonical" href="https://wir-liefern.org/about" />

        <meta property="og:title" content="ABOUT ABOUT ABOUT ABOUT" />
        <meta property="og:description" content="ABOUT ABOUT ABOUT ABOUTABOUT ABOUT ABOUT ABOUTABOUT ABOUT ABOUT ABOUT" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://wir-liefern.org/about" />
        <meta property="og:image" content="https://wir-liefern.org/images/wir-liefern_rot.png" />
      </Helmet> */}

      <StaticPageTitle mb={"96px"}>{i18next.t("about:title")}</StaticPageTitle>
      <StaticPageTextBlock mb={12}>
        <Text fontWeight="bold">
          {i18next.t("about:section1.line1")}
        </Text>
        {i18next.t("about:section1.line2")}
      </StaticPageTextBlock>
      <PhotoAndTextBlock
        imgSrc={AboutQuestionMark}
        imgWidth="3200px"
        imgPosition="right"
        my={12}>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("about:section2.line1")}
          </Text>
          {i18next.t("about:section2.line2")}
        </StaticPageTextBlock>
      </PhotoAndTextBlock>

      <PhotoAndTextBlock
        imgSrc={AboutOnline}
        imgWidth="1200px"
        imgPosition="left"
        my={12}>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("about:section3.line1")}
          </Text>
          {i18next.t("about:section3.line2")}
          <br />
          {i18next.t("about:section3.line3")}
          <Text fontWeight="bold">
            <Link href="/newStore" color="content.button.color" background="content.button.background" fontWeight="bold">
              {i18next.t("about:section3.line4")}
            </Link>
          </Text>
        </StaticPageTextBlock>
      </PhotoAndTextBlock>

      <PhotoAndTextBlock
        imgSrc={AboutDesk}
        imgWidth="1200px"
        imgPosition="left"
        my={12}>
        <StaticPageTextBlock>
          <Text fontWeight="bold">
            {i18next.t("about:section4.line1")}
          </Text>
          {i18next.t("about:section4.line2")}
          <br />
          <Link href="/newUserAndStore" color="brandRed.300" fontWeight="bold">
            {i18next.t("about:section4.line3")}
          </Link>
          <br />
          <Link href="mailto:" color="brandRed.300" fontWeight="bold">
            {i18next.t("about:section4.line4")}
          </Link>
        </StaticPageTextBlock>
      </PhotoAndTextBlock>
      <SocialInfo header={"shared:socialInfo.header"} py={20} />
    </ContentArea >
  )
};
