import { News, NewsDetails } from "../models/index";
import axios from "../../../network";
/**
 * Categories
 */

let getMonthName = (month: number) => {
  let monthname = "";
  switch (month) {
    case 0: monthname = "Januar"; break;
    case 1: monthname = "Februar"; break;
    case 2: monthname = "März"; break;
    case 3: monthname = "April"; break;
    case 4: monthname = "Mai"; break;
    case 5: monthname = "Juni"; break;
    case 6: monthname = "Juli"; break;
    case 7: monthname = "August"; break;
    case 8: monthname = "September"; break;
    case 9: monthname = "Oktober"; break;
    case 10: monthname = "November"; break;
    case 11: monthname = "Dezember"; break;

  }

  return monthname;
};

export const getNews = async (typename:string): Promise<News[]> => {
  return new Promise<News[]>((resolve, reject) => {
    axios.
      get("/News/Search/?typename=news")
      .then(function (response) {
        try {
          let data = response.data;
          let newsData: News[] = data;

          for (let i = 0; i < newsData.length; i++) {
            let date = new Date(newsData[i].newsDate);
            if (date !== undefined && date !== null) {
              let parsedDate;
              let month = getMonthName(date.getMonth());
              let day = date.getDate();
              parsedDate = (day >= 10 ? day : "0" + day) + ". " + month + " " + date.getFullYear();
              newsData[i].parsedDate = parsedDate;
            }
          }


          resolve(newsData);
        } catch (r) {
          console.log("Error loading/parsing NewsList data");
        }
      }).catch(() => {
        console.log("Error loading/parsing NewsList data");
      });
  });
};

export const getNewsDetails = async (id: string): Promise<NewsDetails> => {
  return new Promise<NewsDetails>((resolve, reject) => {
    
    axios.
      get("/News/News/" + id)
      .then(function (response) {
        try {
          const news: NewsDetails = response.data;         
          resolve(news);
        } catch (r) {
          console.log("Error loading/parsing NewsDetails data");
        }
      }).catch(() => {
        console.log("Error loading/parsing NewsDetails data");
      });
  });
};
