import { Grid } from '@chakra-ui/react';
import { CategorySearch, SearchBox } from 'app/search';
import {
  BenefitsGrid,
  CampaignSlide,
  ContentArea,
  HeaderBackground,
  InfoGrid,
  SocialInfo,
  WhiteSpace,
} from 'app/shared';
import { FormattedEmbeddedVideo } from 'app/shared/formattedEmbeddedVideo';
import React, { useState, useEffect } from 'react';
import { useMediaQueryContext } from 'styles/context';
import useScript from '../utils/useScript';
import { useRootRepositoryContext } from 'rootRepository';
import { BannerCard } from 'app/banner/BannerCard';
import { Testimonial } from 'app/testimonials/models';
import i18next from 'i18next';
import { ReactComponent as BannerBenefitImage } from 'app/shared/benefitsGrid/static/bannerBenefit.svg';

const HomePage: React.FC = () => {
  useScript.useScriptViaString(
    "if (typeof upScore == 'function') { upScore({data: {object_id: 'HomePage', section: 'HomePage', pubdate: '', author: '', url: '/', object_type: 'HomePage' }}); } "
  );

  const { userRepository } = useRootRepositoryContext();
  const { testimonialRepository } = useRootRepositoryContext();
  const { isLarge, isVeryLarge } = useMediaQueryContext();

  const [testimonial, setTestimonial] = useState(
    testimonialRepository.latestTestimonial
  );

  useEffect(() => {
    testimonialRepository.fetchLatestTestimonial().then(() => {
      setTestimonial(testimonialRepository.latestTestimonial);
    });
  }, [testimonialRepository]);

  const RenderBenefitInfoCard = () => {
    const headlineText = i18next.t('shared:benefitsGrid.freeBenefitTitle');
    const text = i18next.t('shared:benefitsGrid.freeBenefitDesc');
    const buttonText = i18next.t('shared:benefitsGrid.freeBenefitBtn');
    const link = 'mailto:' + i18next.t('shared:benefitsGrid.freeBenefitMailTo');
    const imgSrc = '';

    const mailToLink = () => {
      // Mailto-Link wird bei Click zusammengebaut. Je nachdem ob der Nutzer angemeldet ist oder nicht, wird der Name des Nutzers im Placeholder eingesetzt
      const body = i18next
        .t('shared:benefitsGrid.freeBenefitMailTo')
        .replace(
          '{0}',
          userRepository.user
            ? `${userRepository.user.firstName} ${userRepository.user.lastName}%0D%0A`
            : ''
        );
      return 'mailto:?body=' + body;
    };

    const listArray: any = [];

    return (
      <BannerCard
        key="6"
        id={'id_benefitInfoCard'}
        headline={headlineText}
        imgPosition={'left'}
        text={text}
        buttonText={buttonText}
        link={mailToLink()}
        imageView={() => <BannerBenefitImage />}
      />
    );
  };

  /* Vorbereitung für späteres "sortieren" der Content-ELemente auf der Homepage.
     Für das Rendern der Elemente kann ein Array genutzt werden. Reihenfolge der Elemente im Array
     können somit beliebig angepasst werden. Es muss ein eindeutiger Key mit angegeben werden (da sonst eine
     Fehlermeldung in der Browser-Konsole mit ausgegeben wird*/

  const getHomepageContent = () => {
    const content = [];
    content.push(<SearchBox key="1" />);
    if (!isVeryLarge || !isLarge) {
      content.push(
        <WhiteSpace key="2" height={['0', '0', '0', '0', '0', '0', '60px']} />
      );
    }
    content.push(<CategorySearch key="3" />);
    content.push(<CampaignSlide key="4" />);
    content.push(<BenefitsGrid key="5" />);
    content.push(RenderBenefitInfoCard());
    if (testimonial) {
      content.push(
        <FormattedEmbeddedVideo
          key="7"
          header={testimonial.headline ?? ''}
          src={testimonial.linkVideo ?? ''}
          videoWidth={['250px', '500px', '500px', '700px', '900px']}
          desc={testimonial.text ?? ''}
          textLink={testimonial.linkText ?? ''}
          btnText={'shared:favoriteShopVideo.moreShops'}
          pb={[6, 6, 0]}
          btnLink={'store/' + testimonial.fk_stores}
        />
      );
    }

    content.push(<InfoGrid key="8" />);
    return content;
  };

  return (
    <>
      <HeaderBackground />
      <ContentArea>
        <Grid templateColumns="repeat(1, 1fr)" gap={[6, 10, 10, 16, 20]}>
          {getHomepageContent()}
        </Grid>
      </ContentArea>
    </>
  );
};

export default HomePage;
