import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface Props extends BoxProps {
  imageView: () => ReactNode;
  title: string;
  highlightedDesc: string;
  desc: string;
  btnView: () => ReactNode;
}

export const BenefitCard: React.FC<Props> = ({
  imageView,
  title,
  highlightedDesc,
  desc,
  btnView,
  ...rest
}) => (
  <Flex m={[0, 6]} direction="column" alignItems="center" {...rest}>
    <Flex
      direction="column"
      justifyContent="flex-end"
      minH={["130px", "220px"]}
      maxH={["unset", "220px"]}>
      {imageView()}
    </Flex>
    <Text
      color="darkRed"
      fontWeight="bold"
      fontSize="2xl"
      lineHeight="34px"
      width="100%"
      textAlign="center">
      {title}
    </Text>
    <Text
      pt={6}
      pb={8}
      textAlign="center"
      color="black"
      fontSize="sm"
      minH="230px">
      <Text as="span" fontWeight="bold">
        {highlightedDesc}
      </Text>
      &nbsp;
      <span dangerouslySetInnerHTML={{ __html: desc }} />
    </Text>

    <Box width={["90%", "90%", "90%", "90%", "60%"]}>{btnView()}</Box>
  </Flex>
);
